import "./App.css";
import { Toaster } from "react-hot-toast";
import Navigation from "./routing/Navigation";
import { AuthProvider } from "./store/context/AuthProvider";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./theme";
import { Provider } from "react-redux";
import store from "./redux/store";
import { useEffect } from "react";
const FrameBuster = () => {
  useEffect(() => {
    if (typeof window !== "undefined" && window !== window.top) {
      window.top.location.href = window.location.href;
    }
  }, []);

  return <></>;
};
function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <AuthProvider>
        <Provider store={store}>
          <Toaster
            toastOptions={{
              position: "top-center",
            }}
          />
          <Navigation />
          <FrameBuster />
        </Provider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;