import React, { useContext } from 'react'
import { basename } from '../../Config'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import './layout.css'
import { Routeconstant } from '../../routing/utils/RouteConstant'
import { AuthContext } from '../../store/context/AuthProvider'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { API_PATH } from '../../constant/apiPath'
import instance from '../../api/Interceptor'
import { genrateDownloadLink } from '../../utils/utility'
import { toast } from 'react-hot-toast'
import { useState } from 'react'
import moment from 'moment/moment'
import urlResolver from '../../utils/urlResolver'
import { CircularProgress, Tooltip } from '@mui/material'
import accessGiven from '../../utils/permission'
import CustomTooltip from '../../utils/CustomTooltip'
import DocUploadShipmentSidebar from "../sidebar/DocUploadShipmentSidebar";

function LayoutShipments(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const context = useContext(AuthContext)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [docUpload, setDocUpload] = useState(false)
  const permission = accessGiven()
  const handleClick = () => {
    setButtonLoading(true)
    const body = {
      ShipmentId: '',
      Search: props?.search,
      TransportMode: props?.filter?.TransportMode ?? '',
      ContainerType: props?.filter?.ContainerType ?? '',
      OriginCode: props?.filter?.Origin ?? '',
      DestinationCode: props?.filter?.Destination ?? '',
      Carrier: props?.filter?.Carrier ?? '',
      HouseBillNumber: '',
      Consignee: props?.filter?.Consignee ?? '',
      Consignor: props?.filter?.Consignor ?? '',
      OrderNumber: props?.filter?.OrderNumber ?? '',
      MasterBillNumber: props?.filter?.MasterBill ?? '',
      ETAFrom: props?.filter?.FromETA
        ? moment(new Date(props?.filter?.FromETA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETATo: props?.filter?.ToETA
        ? moment(new Date(props?.filter?.ToETA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETDFrom: props?.filter?.FromETD
        ? moment(new Date(props?.filter?.FromETD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETDTo: props?.filter?.ToETD
        ? moment(new Date(props?.filter?.ToETD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      // -----------
      ATDFrom: props?.filter?.FromATD
        ? moment(new Date(props?.filter?.FromATD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATDTo: props?.filter?.ToATD
        ? moment(new Date(props?.filter?.ToATD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATAFrom: props?.filter?.FromATA
        ? moment(new Date(props?.filter?.FromATA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATATo: props?.filter?.ToATA
        ? moment(new Date(props?.filter?.ToATA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : "",
      DestinationPortName: props?.filter?.DestinationPortName ?? "",
      OriginPortName: props?.filter?.OriginPortName ?? "",
      EmailID: "",
      ShowDeveloperResponse: false,
      DateFrom: '',
      DateTo: '',
      Status: props?.status ? props?.status : '',
      CurrentPage: 0,
      PageSize: (context?.listSize + 1) * 10,
      SortColumnName: props?.sortColumn ?? '',
      SortDirection: props?.sortDirection ?? ''
    }

    instance
      .post(API_PATH.EXPORT_TO_EXCEL_SHIPMENT, body)
      .then(res => {
        const downloadLink = document.createElement('a')
        const prefix = genrateDownloadLink(res?.data?.SC_FileName ?? '')
        downloadLink.href = `${prefix}${res?.data?.ImageDataVC ?? ''}`
        if (prefix) {
          downloadLink.download = res?.data?.SC_FileName ?? ''
          downloadLink.click()
        } else {
          toast.error('Invalid document found!')
        }
        setButtonLoading(false)
      })
      .catch(err => {
        console.log(err)
        setButtonLoading(false)
      })
  }
  return (
    <>
      {docUpload ? (
        <DocUploadShipmentSidebar
          setDocUpload={setDocUpload}
          docUpload={docUpload}
        />
      ) : null}
      <div
        className={
          location.pathname.includes(Routeconstant.DETAILS)
            ? 'container-fluid shipment-top-div shipment-card-div'
            : 'container-fluid shipment-top-div'
        }
      >
        {context.gridViewShipment === true ? (
          <div className='d-flex justify-content-between align-items-center'>
            <div
              className='d-flex justify-content-between col-xxl-4 col-xs-12 col-md-6 col-lg-5 col-xl-4 align-items-center layout-select-box'
              style={{ paddingLeft: '0px', paddingRight: '10px' }}
            >
              <p className='layout-head'>Shipments</p>
            </div>
            <div className='d-flex  justify-content-between align-items-center'>
              {permission?.exportToExcel && (
                <div className='me-3'>
                  <Stack spacing={1}>
                    <Chip
                      label={
                        !buttonLoading ? (
                          <span
                            className='font-shipment'
                            style={{ textAlign: 'center' }}
                          >
                            Export to Excel{' '}
                            <CustomTooltip
                              of='Shipment_Listing'
                              forKey='Export to excel'
                            />
                          </span>
                        ) : (
                          <>
                            <span className='font-shipment'>Exporting...</span>
                            <CircularProgress
                              size='1rem'
                              style={{
                                color: 'var(--color-miscellaneous-4)'
                              }}
                            />
                          </>
                        )
                      }
                      variant='outlined'
                      onClick={handleClick}
                    />
                  </Stack>
                </div>
              )}

              <div className='d-flex  justify-content-between align-items-center py-2 layout-head-section'>
                <div
                  className={
                    context.gridViewShipment
                      ? 'icons-shipment-page-inactive d-flex justify-content-center align-items-center mr-2'
                      : 'icons-shipment-page border-radius-left d-flex justify-content-center align-items-center mr-2'
                  }
                  onClick={() => {
                    context.setGridViewShipment(false)
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  {context.gridViewShipment ? (
                    <img src={urlResolver('assets/ListViewBlue.svg')} />
                  ) : (
                    <img src={urlResolver('assets/ListViewWhite.svg')} />
                  )}
                </div>
                <div
                  className={
                    context.gridViewShipment
                      ? 'icons-shipment-page border-radius-right d-flex justify-content-center align-items-center'
                      : 'icons-shipment-page-inactive d-flex justify-content-center align-items-center'
                  }
                  onClick={() => {
                    context.setGridViewShipment(true)
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  {context.gridViewShipment ? (
                    <img src={urlResolver('assets/GridViewWhite.svg')} />
                  ) : (
                    <img src={urlResolver('assets/GridViewBlue.svg')} />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : context.gridViewShipment === false &&
          location.pathname === Routeconstant.SHIPMENT_LISTING ? (
          <div className='d-flex justify-content-between align-items-center'>
            <div
              className='d-flex justify-content-between col-xxl-4 col-xs-12 col-md-6 col-lg-5 col-xl-4 align-items-center layout-select-box'
              style={{ paddingLeft: '0px', paddingRight: '30px' }}
            >
              <p className='layout-head'>Shipments</p>
            </div>
            <div className='d-flex  justify-content-between align-items-center'>
              {permission?.exportToExcel && (
                <div className='me-3'>
                  <Stack spacing={1}>
                    <Chip
                      label={
                        !buttonLoading ? (
                          <span
                            className='font-shipment'
                            style={{ textAlign: 'center' }}
                          >
                            Export to Excel
                          </span>
                        ) : (
                          <>
                            <span className='font-shipment'>Exporting...</span>
                            <CircularProgress
                              size='1rem'
                              style={{
                                color: 'var(--color-miscellaneous-4)'
                              }}
                            />
                          </>
                        )
                      }
                      variant='outlined'
                      onClick={handleClick}
                    />
                  </Stack>
                </div>
              )}
              <div className='d-flex justify-content-between align-items-center py-2 layout-head-section'>
                <div
                  className={
                    context.gridViewShipment
                      ? 'icons-shipment-page-inactive d-flex justify-content-center align-items-center mr-2'
                      : 'icons-shipment-page border-radius-left d-flex justify-content-center align-items-center mr-2'
                  }
                  onClick={() => {
                    context.setGridViewShipment(false)
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  {context.gridViewShipment ? (
                    <img src={urlResolver('assets/ListViewBlue.svg')} />
                  ) : (
                    <img src={urlResolver('assets/ListViewWhite.svg')} />
                  )}
                </div>
                <div
                  className={
                    context.gridViewShipment
                      ? 'icons-shipment-page border-radius-right d-flex justify-content-center align-items-center'
                      : 'icons-shipment-page-inactive d-flex justify-content-center align-items-center'
                  }
                  onClick={() => {
                    context.setGridViewShipment(true)
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  {context.gridViewShipment ? (
                    <img src={urlResolver('assets/GridViewWhite.svg')} />
                  ) : (
                    <img src={urlResolver('assets/GridViewBlue.svg')} />
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {context.gridViewShipment ? (
        <div className='container-fluid shipment-bottom-div'>
          <div className='row'>
            <div
              className={
                location.pathname.includes(Routeconstant.DETAILS) ||
                location.pathname.includes(Routeconstant.ROUTING) ||
                location.pathname.includes(Routeconstant.DOCUMENTATION) ||
                location.pathname.includes(Routeconstant.CARGO_DETAILS)
                  ? 'col-xs-12 col-md-6 col-lg-5 col-xl-4 shipment-card-div '
                  : 'col-xs-12 col-md-6 col-lg-5 col-xl-4 '
              }
            >
              {props.children}
            </div>
            {location.pathname === Routeconstant.SHIPMENT_LISTING ? (
              <>
                {/* {context.shipmentCollection.length ||
                context.preloaded.length ||
                context.loaded.length ||
                context.arrived.length ? (
                  <div className="col-xs-12 col-md-6 col-lg-7 col-xl-8 d-md-flex flex-column justify-content-center align-items-center d-none d-sm-none select-shipment-div">
                    <img
                      src={urlResolver("assets/SelectShipmentMain.svg")}
                      style={{ width: "inherit", height: "70vh" }}
                    />
                    <p className="select-shipment-details">
                      Select a shipment to see details
                    </p>
                  </div>
                ) : (
                  <div className="col-xs-12 col-md-6 col-lg-7 col-xl-8 d-md-flex flex-column justify-content-center align-items-center d-none d-sm-none select-shipment-div">
                    <img
                      src={urlResolver("assets/SelectShipmentMain.svg")}
                      style={{ width: "inherit", height: "70vh" }}
                    />
                    <p className="select-shipment-details">
                      Select a shipment to see details
                    </p>
                  </div>
                )} */}
                <div className='col-xs-12 col-md-6 col-lg-7 col-xl-8 d-md-flex flex-column justify-content-center align-items-center d-none d-sm-none select-shipment-div'>
                  <img
                    src={urlResolver('assets/SelectShipmentMain.svg')}
                    style={{ width: 'inherit', height: '70vh' }}
                  />
                  <p className='select-shipment-details font-change'>
                    Select a shipment to see details
                  </p>
                </div>
              </>
            ) : (
              <div className='col-xs-12 col-md-6 col-lg-7 col-xl-8'>
                <Outlet />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={
            location.pathname.includes(Routeconstant.DETAILS) ||
            location.pathname.includes(Routeconstant.ROUTING) ||
            location.pathname.includes(Routeconstant.DOCUMENTATION) ||
            location.pathname.includes(Routeconstant.CARGO_DETAILS)
              ? 'col-xs-12'
              : 'col-xs-12'
          }
        >
          {props.children}
        </div>
      )}
    </>
  )
}

export default LayoutShipments
