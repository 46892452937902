import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";
import { AuthContext } from "../../store/context/AuthProvider";
import { basename, customerName, SECRET_KEY } from "../../Config";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import "./layout.css";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import Tooltip from "@mui/material/Tooltip";
import ShareIcon from "@mui/icons-material/Share";
import Slide from "@mui/material/Slide";
import CryptoJS from "crypto-js";
import { Alert, Button, CircularProgress, Snackbar } from "@mui/material";
import urlResolver from "../../utils/urlResolver";
import CloseIcon from '@mui/icons-material/Close';
import DocUploadShipmentSidebar from "../sidebar/DocUploadShipmentSidebar";


function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function LayoutDetail(props) {
  const location = useLocation();
  const context = useContext(AuthContext);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [emailContact, setemailContact] = useState("");
  const [open, setOpen] = useState(false);
  const [emissions, setEmissions] = useState([{ CO2: "", loading: false }, { lune: "", loading: false }]);
  const [mass, setMass] = useState({ amount: "", unit: "" });
  const [showMass, setShowMass] = useState(false);
  const [noMassData, setNoMassData] = useState(false);
  const [docUpload, setDocUpload] = useState(false)
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer;
    if (showMass || noMassData) {
      setVisible(true);
      timer = setTimeout(() => {
        setVisible(false);
        setShowMass(false);
        setNoMassData(false);
        setIsActive(false)
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showMass, noMassData]);


  const [isActive, setIsActive] = useState(false);

  // Function to toggle the active state
  const toggleButton = () => {
    setIsActive(!isActive);
    getLuneEmission();
  };


  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getEmailContacts = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_EMAIL_CONTACTS, body)
      .then((res) => {
        setemailContact(res.data[0].EmailId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWhatsappNumber = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_WHATSAPP_NUMBER, body)
      .then((res) => {
        setWhatsappNumber(res.data[0].ContactNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (context.gridViewShipment) {
      getWhatsappNumber();
      getEmailContacts();
    }
  }, [location?.state?.ShipmentId]);

  const sendMail = () => {
    let body = `Hello, %0A%0ATeam ${customerName}, %0A%0ACould you please send more details about my shipment? %0A%0AShipment Number: ${location?.state?.ShipmentId ?? ""
      } %0AHouse BL Number: ${location?.state?.data?.HouseBillNumber ?? ""
      } %0ATransport Mode: ${location?.state?.TransportMode ?? ""
      } %0A%0AThank you! %0A${"SFL HUB"}%0A%0A`;
    window.location.href = `mailto:${emailContact}?subject=Shipment ID - ${location?.state?.ShipmentId ?? ""
      }&body=${body}`;
  };

  const getLuneEmission = () => {
    //let tempLoading = { ...emissions };
    //tempLoading[1].loading = true;
    //setEmissions(tempLoading);
    let shipmentID = location?.state?.ShipmentId;
    instance
      .get(`${API_PATH.GET_LUNE_EMISSION}/${shipmentID}`)
      .then((res) => {
        console.log('Response Data:', res.data.mass); // Log the entire response data
        //setMass(res.data.mass);
        // setShowMass(true);
        //context.setMass(res.data.mass)
        {/*if(res.data.mass.amount){
        
         setMass(res.data.mass);

         setShowMass(true);
         context.setMass(res.data.mass)
        }*/}

        if (res.data.mass && res.data.mass.amount) {

          if (res.data.mass.amount === '0') {
            setNoMassData(true);
            // setMass('No Mass Data')
          } else {
            setNoMassData(false);
            setMass(res.data.mass);
            context.setMass(res.data.mass)
            setShowMass(true)
          }
        }



        if (res?.data?.emissions?.length > 0) {
          let temp = { ...emissions };
          temp[1].lune = `${Number(res.data.emissions[0].amount).toFixed(2)} ${res.data.emissions[0].unit
            }`;
          setEmissions(temp);
          console.log('Mass22222', temp[1].lune); // Log the formatted emissions data
        } else {
          console.log('No emissions data found'); // Log if no emissions data is available
        }
      })
      .catch((err) => console.error('Error fetching emissions data:', err)) // Log the error
      .finally(() => {
        let tempLoading = { ...emissions };
        tempLoading[1].loading = false;
        setEmissions(tempLoading);
      });
  };

  return (
    <div className="detail-header overflow_detail">
       {docUpload ? (
            <DocUploadShipmentSidebar
              setDocUpload={setDocUpload}
              docUpload={docUpload}
            />
          ) : null}
      {context.gridViewShipment ? (
        <div className="d-flex justify-content-between align-items  p-2 details-head-div">
          <div className="d-flex">
            <p className="mb-0 mt-1 detail-text-head">
              {location?.state?.ShipmentId ?? "N/A"}
            </p>{" "}
            {/* <div className="d-flex align-items-center justify-content-center d-none">
              {context?.emissions[0]?.loading === true ? (
                <CircularProgress size={"1rem"} />
              ) : (
                <Button
                  variant="outlined"
                  style={{ marginRight: "5px", padding: "0px 5px", display: 'none' }}
                  onClick={() => {
                    context?.getCarbonEmission();
                  }}
                >
                  {context?.emissions[0]?.CO2 || (
                    <>
                      CO <sub>2</sub>
                    </>
                  )}
                </Button>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center co2">
              {" "}
              {emissions[1]?.loading === true ? (
                <CircularProgress size={"1rem"} />
              
              ) : showMass ? (
                <>
                  <h3 className="mb-0 mt-1 detail-text-head ml-3">{`${mass.amount} ${mass.unit}`}  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/leaf_16.png'} alt="Logo" className="image" />
                    <span className="co2-text">Co<sub>2</sub></span></h3>
                  <div
                    variant="outlined"
                    style={{ marginLeft: "5px", padding: "0px" }}
                    onClick={() => setShowMass(false)}
                  >
                    <CloseIcon />
                  </div>
                </>
              ) : noMassData ? (
                <>
                <h3 className="mb-0 mt-1 detail-text-head ml-3">No Data!  &nbsp;<img src={process.env.PUBLIC_URL + '/assets/leaf_16.png'} alt="Logo" className="image" />
                <span className="co2-text">Co<sub>2</sub></span></h3>
                <div variant="outlined" style={{marginLeft:'5px;', padding:'0px'}}
                 onClick={()=>setNoMassData(false)}
                >
                <CloseIcon/>
                </div>
                </>
              ) : (
              <></>
              )}

            </div>
            
            <Button
      variant="outlined"
      className="co2-btn"
      style={{
        marginRight: '5px',
        padding: '0px',
        
        backgroundColor: isActive ? '#0a2d44' : '#ffffff', // Change background color based on active state
      }}
      onClick={toggleButton}
    >
      {emissions[1]?.lune || (
        <>
          <img
            src={process.env.PUBLIC_URL + (isActive ? '/assets/co22.png' : '/assets/co21.png')}
            alt="Logo"
            className="image-co2"
          />
        </>
      )}
    </Button> */}
          </div>

          <div
            className="d-flex justify-content-between align-items-center"
            style={{ minWidth: "8%", gap: "0.2rem" }}
          >
              <Tooltip title='Upload document' className={
                location.pathname.includes(Routeconstant.DOCUMENTATION)
                  ? ''
                  : 'd-none'
              } >
                <img
                  onClick={() => {
                    setDocUpload(true)
                  }}
                  src={urlResolver('assets/DocumentUploadIcon.svg')}
                  style={{height: "2rem"}}
                />
              </Tooltip>
            
            <Tooltip title="Mail">
              <MailIcon className="layout-notification" onClick={sendMail} />
            </Tooltip>
            <Tooltip title="Whatsapp">
              <a
                href={`https://web.whatsapp.com/send?phone=${whatsappNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon className="layout-notification" />
              </a>
            </Tooltip>
            {location.pathname.includes("/details") && (
              <Tooltip title="Share shipment details (Public View)">
                <img
                  style={{
                    height: "1.5rem",
                  }}
                  src={urlResolver("assets/link.svg")}
                  onClick={() => {
                    var currentdate = new Date();

                    let encryptedate = CryptoJS.AES.encrypt(
                      currentdate.toString(),
                      SECRET_KEY
                    ).toString();
                    let encodedate = CryptoJS.enc.Base64.parse(
                      encryptedate
                    ).toString(CryptoJS.enc.Hex);
                    let encrypted = CryptoJS.AES.encrypt(
                      location?.state?.ShipmentId,
                      SECRET_KEY
                    ).toString();
                    let encodeditem = CryptoJS.enc.Base64.parse(
                      encrypted
                    ).toString(CryptoJS.enc.Hex);

                    let encryptedata = encodedate.concat("__", encodeditem);

                    if (basename === "") {
                      navigator.clipboard.writeText(
                        `${document.location.origin
                        }/${`shipment/details/`}${encryptedata}`
                      );
                    } else {
                      navigator.clipboard.writeText(
                        `${document.location.origin
                        }/${basename}/${`shipment/details/`}${encryptedata}`
                      );
                    }

                    handleClick();
                  }}
                />
              </Tooltip>
            )}
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              TransitionComponent={SlideTransition}
            >
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                Copied to clipboard!
              </Alert>
            </Snackbar>
          </div>
        </div>
      ) : null}
      <div
        className={
          context.gridViewShipment
            ? "layout-details-div mx-3"
            : "layout-details-div mx-3 pt-5"
        }
      >
        {props.children}
      </div>
    </div>

  );
}

export default LayoutDetail;