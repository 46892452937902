import React, { useState } from 'react'
import ListTable from './ListTable'
import { Grid, Skeleton } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { basename } from '../../../Config'
import { useNavigate } from 'react-router-dom'
import { Routeconstant } from '../../../routing/utils/RouteConstant'
import FilterModalBooking from '../../../components/model/FilterModalBooking'
import { API_PATH } from '../../../constant/apiPath'
import instance from '../../../api/Interceptor'
import { useDispatch } from 'react-redux'
import {
  deststorepage,
  originstorepage
} from '../../../redux/bookingRedux/storeDataSlice'
import {
  destselectedpage,
  originselectedpage
} from '../../../redux/bookingRedux/selectedDataSlice'
import { userTypeSelect } from '../../../redux/bookingRedux/createBookingSlice'
import EditNoteIcon from '@mui/icons-material/EditNote'
import TableBookingColumn from '../../../components/model/TableBookingColumn'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { useEffect } from 'react'
const Dashboard = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState('')
  const [open, setOpen] = React.useState(false)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [count, setCount] = React.useState(0)
  const [sorting, setSorting] = useState('')
  const [sortingVal, setSortingVal] = useState('')
  const [tableModal, setTableModal] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [tableCopy, setTableCopy] = useState([])
  const [isDataAvailable, setIsDataAvailable] = useState(false)
  const [filterOptions, setFilterOptions] = React.useState({
    transportMode: null,
    consignee: null,
    consignor: null,
    origin: null,
    destination: null,
    createdBy: null,
    etaFrom: null,
    etaTo: null,
    etdFrom: null,
    etdTo: null,
    status: null,
    createdFrom: null,
    createdTo: null
  })
  const [pagination, setPagination] = React.useState({
    CurrentPage: 0,
    PageSize: 10,
    intialLoad: true
  })
  const [reason, setReason] = useState(null)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const dispatch = useDispatch()

  const handleClear = () => {
    setPagination({ CurrentPage: 0, PageSize: 10, intialLoad: true })
    setFilterOptions({
      transportMode: null,
      consignee: null,
      consignor: null,
      origin: null,
      destination: null,
      createdBy: null,
      etaFrom: null,
      etaTo: null,
      etdFrom: null,
      etdTo: null,
      status: null,
      createdFrom: null,
      createdTo: null
    })
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleEditOpen = () => {
    setTableModal(true)
  }
  const handleFilter = () => {
    setPagination({ CurrentPage: 0, PageSize: 10, intialLoad: true })
    getCollection({}, '', filterOptions, true)
    setOpen(false)
  }

  const handleBack = () => {
    setPagination({ CurrentPage: 0, PageSize: 10, intialLoad: true })
    getCollection({}, '', {}, true)
    setFilterOptions({
      transportMode: null,
      consignee: null,
      consignor: null,
      origin: null,
      destination: null,
      createdBy: null,
      etaFrom: null,
      etaTo: null,
      etdFrom: null,
      etdTo: null,
      status: null,
      createdFrom: null,
      createdTo: null
    })
    setOpen(false)
  }
  const getCollection = (pagination, search, filterOptions, dataSetCheck) => {
    setLoading(true)

    const body = {
      BookingNo: search,
      CurrentPage: pagination.CurrentPage ?? 0,
      PageSize: pagination.PageSize ?? 10,
      SortColumnName: sortingVal,
      SortDirection: sorting,
      TransportMode: filterOptions?.transportMode ?? '',
      Consignor: filterOptions?.consignor ?? '',
      Consignee: filterOptions?.consignee ?? '',
      OriginCode: filterOptions?.origin ?? '',
      DestinationCode: filterOptions?.destination ?? '',
      Createdby: filterOptions?.createdBy ?? '',
      ETAFrom: filterOptions?.etaFrom ?? '',
      ETATo: filterOptions?.etaTo ?? '',
      ETDFrom: filterOptions?.etdFrom ?? '',
      ETDTo: filterOptions?.etdTo ?? '',
      Status: filterOptions?.status ?? '',
      CreatedFrom: filterOptions?.createdFrom ?? '',
      CreatedTo: filterOptions?.createdTo ?? ''
    }
    instance
      .post(`${API_PATH.GET_BOOKING_COLLECTION}`, body)
      .then(res => {
        setCount(res?.totalCount)
        if (dataSetCheck) {
          setData(res?.data)
        } else {
          setData([...data, ...res.data])
        }
        // if (reason) {
        //   setData(res?.data);
        // } else if (search) {
        //   setData(res?.data);
        // } else if (filterActive) {
        //   setData(res?.data);
        // } else if (sortingVal) {
        //   setData([...data, ...res.data]);
        // } else {
        //   setData([...data, ...res.data]);
        // }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setReason(null)
        setLoading(false)
      })
  }

  const getTableCols = () => {
    setLoadingTable(true)
   
    instance
      .get(API_PATH.GET_BOOKING_TABLE_COLS)
      .then(res => {
        if (res.data.length > 0) {
          let sortedCols = res.data
          sortedCols = res.data.sort((a, b) => a.Order - b.Order)
          setTableCopy(sortedCols)

          const isDataAvailable = sortedCols.some(col => col.IsVisible)
          if (isDataAvailable) {
            setIsDataAvailable(true)
          }
        }
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => {
        setLoadingTable(false)
      })
  }
  const updateTableCols = updatedCols => {
    setLoadingTable(true)
    let cols = updatedCols
    updatedCols.map((item, index) => {
      cols[index].Order = index + 1
    })

    instance
      .post(API_PATH.POST_BOOKING_TABLE_COLS, cols)
      .then(res => {
        getTableCols()
      })
      .catch(err => {
        setLoadingTable(false)
      })
  }
  useEffect(() => {
    getTableCols()
  }, [])

  return (
    <>
      <div className='booking-dashboard'>
        <div className='booking-actions flex-wrap'>
          <p className='booking-heading '>Booking</p>
          <div className='d-flex gap-2 flex-wrap'>
            <div className='booking-search'>
              <input
                placeholder='Search your booking'
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                }}
                onKeyDown={e => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true
                  })
                  if (e.key === 'Enter') {
                    getCollection(pagination, search, filterOptions, true)
                  }
                }}
              />
              <img
                src={`/${basename}/assets/search_icon.svg`}
                style={{ color: 'rgba(var(--text-heading-dark))' }}
                className='search-icon'
                alt=''
                onClick={() => {
                  setPagination({
                    CurrentPage: 0,
                    PageSize: 10,
                    intialLoad: true
                  })
                  getCollection(pagination, search, filterOptions, true)
                }}
              />
              {search && (
                <CloseOutlinedIcon
                  style={{
                    position: 'absolute',
                    right: '1rem',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setSearch('')
                    getCollection(pagination, '', {}, true)
                  }}
                />
              )}
            </div>
            <div>
              <TableBookingColumn
                updateTableCols={updateTableCols}
                tableModal={tableModal}
                setTableModal={setTableModal}
                tableCopy={tableCopy}
                setTableCopy={setTableCopy}
                getCollection={getCollection}
              />
              <Fab
                title='Use this feature to select and save your
                       preferred column display preferences 
                       on the user interface.'
                size='small'
                aria-label='edit'
                style={{ backgroundColor: 'white', color: '#000' }}
              >
                <EditNoteIcon onClick={handleEditOpen} />
              </Fab>
            </div>
            <Fab
              title='Filter'
              size='small'
              aria-label='edit'
              style={{
                backgroundColor: 'white',
                color: '#000'
              }}
            >
              <FilterAltOutlinedIcon onClick={handleClickOpen} />
            </Fab>

            <Fab
              color='primary'
              size='small'
              aria-label='add'
              title='Create Booking'
            >
              <AddIcon
                onClick={() => {
                  dispatch(userTypeSelect(''))
                  dispatch(originstorepage([]))
                  dispatch(originselectedpage([]))
                  dispatch(destselectedpage([]))
                  dispatch(deststorepage([]))
                  navigate(`${Routeconstant.BOOKING_CREATE}`)
                }}
              />
            </Fab>
          </div>
        </div>
      
        <ListTable
          tableCopy={tableCopy}
          setTableCopy={setTableCopy}
          search={search}
          getCollection={getCollection}
          setPagination={setPagination}
          pagination={pagination}
          loading={loading}
          setLoading={setLoading}
          data={data}
          setData={setData}
          sorting={sorting}
          setSorting={setSorting}
          sortingVal={sortingVal}
          setSortingVal={setSortingVal}
          reason={reason}
          setReason={setReason}
          filterOptions={filterOptions}
          count={count}
          isDataAvailable = {isDataAvailable}
        />
      </div>
      <FilterModalBooking
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        open={open}
        handleClose={handleClose}
        handleClickOpen={handleClickOpen}
        handleClear={handleClear}
        handleBack={handleBack}
        handleFilter={handleFilter}
      />
    </>
  )
}
export default Dashboard
