const ENV = "";
// const ENV = "";
let basename = "";
let isInDocumentDev = false;
let SECRET_KEY =
  "MIICWwIBAAKBgQCFRInogbYBaG3h5zpMKoGl1aNB6S+ajMUPom1s1vsv4V+4G6o6BeR9eooPqO2OG0t5Mxkw2Ls0ypvd9sRQpGuxNNTbSwCXmoYnHxCWDOVF0Lv7Ylr+eOC/v6brQmx8ZocJc5db3QUWZz/+/qFMRqM3bRS9FAcSVla0Reg52R6NnwIDAQABAoGAR09Ra/Rca4xxn7uW2kK0dQRIA3C/ZDeHCsKjQuYWKpyyF1XWVI3qoan8TjzaQHwieov5IsYWBj2UAcWVboFMjhOdqN7+ILrbtJiKks3Lp7HG5Z+OSMeMAedA7JMQU+1r6t75QF+m6uIEcn775DXRV16oPnt47hGQhPdNYyjYKEECQQDIdMNVmTha5HQsKYn1wWEsD0ZYL2HkmtV0AfMQeKbHuJzhgC3jUQZKAPAd+C5mlTVad/80i6UjJwfpb25KacWpAkEAqjHOsKTmPB/ZSYRayGXgDGAl6R29LsRItK/VVHX1dxhMi8DPPiAHDieTxdZTJ0raY6zpD/0BuWbQe8HsmJS2BwJAYxkORC/2I0ETiY2TjXHnZw+6F/h8rbY51hrsnfT3hn7m17g1d05kDIvAKSC7V61l7e6qtMBgiCtQhv+2h4UY2QJAZrtP3vJ6gxpaZ3T783EDD5VN7OvlkQAsPtzQMX5OX8gMFGOa9Q4SFCoxmb6oTNRzBK2PtPXsf4QfHkTNUsCZKwJAGAbvPvvLPbl0ZF5TCwQuDvsBIbgC/Umto2RlDWfiDxKz8Xt2YpvjtQr9689Ax0gmB4cGgRivxAkQAU87DYtgJA==";

let baseUrl = "";
let baseOrderUrl = "";
let LOCAL_STORAGE_KEY = "";
let customerName = "AxisShipping";
// let customerName = "SFLInsight";
let marginRms = false;
if (ENV === "DEMO") {
  basename = "SFLInsightsUINewDemo";
  baseUrl = "https://dev.sflhub.com/SFLInsightAPIDemo/";
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
} else {
  basename = "insights";
  baseUrl = "https://tracking.axisshipping.com.br/connectAPI/";
  baseOrderUrl =
    "https://tracking.axisshipping.com.br/connectAPI/PurchaseOrder/";
  LOCAL_STORAGE_KEY = "sflinsights_mvp2";
}

export {
  basename,
  baseUrl,
  baseOrderUrl,
  LOCAL_STORAGE_KEY,
  customerName,
  marginRms,
  SECRET_KEY,
  isInDocumentDev
}

// "start": "set PORT=4001 &&  react-scripts start", set port 4001 for order API's


