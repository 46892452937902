import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputAdornment,
  Switch,
  TextField,
  Tooltip
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateAdditionalServices,
  updateDetails
} from '../../../../redux/bookingRedux/bookingBasicDetailSlice'
import instance from '../../../../api/Interceptor'
import { API_PATH } from '../../../../constant/apiPath'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { updateUserData } from '../../../../redux/bookingRedux/createBookingSlice'
import {
  deststorepage,
  originstorepage
} from '../../../../redux/bookingRedux/storeDataSlice'
import {
  destselectedpage,
  originselectedpage
} from '../../../../redux/bookingRedux/selectedDataSlice'
import {
  DatePicker,
  LocalizationProvider,
  StaticDatePicker
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { debounce } from 'lodash'

const transportMode = [{ label: 'FCL' }, { label: 'LCL' }, { label: 'AIR' }]

const BuyerSupplier = ({ edit, setEdit, collection, role, buyer }) => {
  const {
    errorBooking,
    bookingBasicDetails: { BookingHeader },
    createBooking: { userData, userType },
    storeData: {
      store: { originunloco, destunloco }
    },
    selectedData: {
      selected: { destselectedunloco, originselectedunloco }
    }
  } = useSelector(state => state)
  const { createBooking } = useSelector(state => state)
  const dispatch = useDispatch()
  const location = useLocation()
  /* -----------------------------States----------------------------- */
  const [address, setAddress] = useState({
    buyerAddress: [],
    supplierAddress: []
  })
  const [selectedAddress, setSelectedAddress] = useState({
    buyerAddress: '',
    supplierAddress: ''
  })

  const [transPortModes, setTransPortModes] = useState([])
  const [carrierCodes, setCarrierCodes] = useState([])
  const [movementType, setMovementType] = useState([])
  const [dropDownloader, setDropDownLoader] = useState({
    origin: false,
    destination: false
  })
  const [transport, setTransport] = useState({})
  const [cargoType, setCargoType] = useState({})
  const [carrier, setCarrier] = useState({})

  const [isFocused, setIsFocused] = useState({
    enterConsignerFrom: false,
    addConsignerFrom: false,
    enterConsignerTo: false,
    addConsignerTo: false
  })

  const [isAddingFrom, setIsAddingFrom] = useState(false)
  const [isAddingTo, setIsAddingTo] = useState(false)

  const leftSubsectionFromRef = useRef(null)
  const leftSubsectionToRef = useRef(null)

  useEffect(() => {
    const leftSubsection = leftSubsectionFromRef.current
    if (leftSubsection) {
      leftSubsection.style.height = `${leftSubsection.scrollHeight}px`
    }
  }, [edit[1].editSupplier, userData?.selectedAddress?.supplierAddress])

  useEffect(() => {
    const leftSubsection = leftSubsectionToRef.current
    if (leftSubsection) {
      leftSubsection.style.height = `${leftSubsection.scrollHeight}px`
    }
  }, [edit[0].editBuyer, userData?.selectedAddress?.buyerAddress])

  /* -----------------------------APIs----------------------------- */

  const getBuyerSupplier = (org, role) => {
    let body = {
      OrganisationCode: org,
      Type: role
    }
    instance.post(`${API_PATH.GET_BUYER_SUPPLIER}`, body).then(res => {
      if (
        role === 'Buyer' &&
        BookingHeader?.ConsigneeCode &&
        userData?.address?.buyerAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: 'viewSupplier',
            value: res?.data?.SupplierBuyers
          })
        )
        let temp = { ...userData.address }
        temp.buyerAddress = res?.data?.Organisation?.Addresses

        dispatch(updateUserData({ keyName: 'address', value: temp }))
      } else if (
        role === 'Supplier' &&
        BookingHeader.ConsignorCode &&
        userData?.address?.supplierAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: 'viewBuyers',
            value: res.data.SupplierBuyers
          })
        )
        let temp = { ...userData.address }
        temp.supplierAddress = res?.data?.Organisation?.Addresses
        dispatch(updateUserData({ keyName: 'address', value: temp }))
      }
    })
  }

  const getOrg = search => {
    let temp = { ...dropDownloader }
    temp.origin = true
    setDropDownLoader(temp)
    let body = {
      CountryCode: '',
      Search: search ?? ''
    }

    instance
      .post('api/v1/master/GetUnlocoInfo', body)
      .then(res => {
        dispatch(originstorepage(res?.data))
      })
      .catch(err => console.log(err))
      .finally(() => {
        let temp = { ...dropDownloader }
        temp.origin = false
        setDropDownLoader(temp)

        if (location.pathname.includes('edit')) {
          // getDest();
        }
      })
  }

  const debouncedGetOrg = useCallback(debounce(getOrg, 300), [])

  const getDest = search => {
    let temp = { ...dropDownloader }
    temp.destination = true
    setDropDownLoader(temp)
    let body = {
      CountryCode: '',
      Search: search ?? ''
    }

    instance
      .post('api/v1/master/GetUnlocoInfo', body)
      .then(res => {
        dispatch(deststorepage(res?.data))
      })
      .catch(err => console.log(err))
      .finally(() => {
        let temp = { ...dropDownloader }
        temp.destination = false
        setDropDownLoader(temp)
      })
  }

  const debouncedGetDest = useCallback(debounce(getDest, 300), [])

  const getTransportMode = () => {
    instance
      .get(`${API_PATH.GET_TRANSPORT_MODE}`)
      .then(res => {
        let result = res?.data?.filter(item => item?.Code !== 'FAS')
        setTransPortModes(result)
        setTransport(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.Transportmode
          })
        )
      })
      .catch(err => console.log(err))
  }

  const getCarrierCodes = val => {
    instance
      .get(`${API_PATH.GET_BOOKING_CARRIER_CODES}${val}`)
      .then(res => {
        setCarrierCodes(res?.data)
        if (BookingHeader?.CarrierCode?.length > 0) {
          setCarrier(() => {
            return res?.data?.find((item, index) => {
              return item?.Code === BookingHeader?.CarrierCode
            })
          })
        } else if (BookingHeader?.CarrierName?.length > 0) {
          setCarrier({
            Code: BookingHeader?.CarrierName,
            FullName: BookingHeader?.CarrierName
          })
        }
      })
      .catch(err => console.log(err))
  }

  const getMovementType = val => {
    let body = { TransportMode: val }
    instance
      .post(`${API_PATH.GET_MOVEMENT_TYPE}`, body)
      .then(res => {
        setMovementType(res?.data)
        setCargoType(() =>
          res?.data?.find((item, index) => {
            return item.Code === BookingHeader?.Carotype
          })
        )
      })
      .catch(err => console.log(err))
  }

  /* -----------------------------Functions----------------------------- */

  function handleUpdateDetails(name, value) {
    dispatch(
      updateDetails({ objName: 'BookingHeader', name: name, value: value })
    )
  }

  const handleOriginDetails = val => {
    dispatch(originselectedpage(val))
  }

  const handleCarrierDetails = (name, value) => {
    dispatch(
      updateDetails({ objName: 'BookingHeader', name: name, value: value })
    )
  }

  const handleFocus = field => {
    setIsFocused(prevState => ({
      ...prevState,
      [field]: true
    }))
  }

  const handleBlur = field => {
    setIsFocused(prevState => ({
      ...prevState,
      [field]: false
    }))
  }

  const handleAddClick = () => {
    let temp = [...edit]
    temp[0].editBuyer = true
    setEdit(temp)
    setSelectedAddress(prev => ({
      ...prev,
      buyerAddress: ''
    }))
  }

  const handleRemoveClick = () => {
    let temp = [...edit]
    temp[0].editBuyer = false
    setEdit(temp)
    setSelectedAddress(prev => ({
      ...prev,
      buyerAddress: ''
    }))
  }
  useEffect(() => {
    if (role === 'Buyer') {
      let buyers = collection[0]?.OrgnisationCollection.filter(
        type => type?.IsBuyer === true
      )
      dispatch(updateUserData({ keyName: 'viewBuyers', value: buyers }))
    } else if (role === 'Supplier') {
      let suppliers = collection[0]?.OrgnisationCollection.filter(
        type => type?.IsSupplier === true
      )
      dispatch(updateUserData({ keyName: 'viewSupplier', value: suppliers }))
    }
  }, [role, collection])

  // useEffect(() => {
  //   if (role === 'Buyer' && BookingHeader.ConsigneeCode) {
  //     getBuyerSupplier(BookingHeader.ConsigneeCode, 'Buyer')
  //   } else if (role === 'Supplier' && BookingHeader.ConsignorCode) {
  //     getBuyerSupplier(BookingHeader.ConsignorCode, 'Supplier')
  //   }
  // }, [BookingHeader.ConsigneeCode, BookingHeader.ConsignorCode])

  // GetAddress
  useEffect(() => {
    if (
      BookingHeader?.ConsigneeCode?.length > 0 &&
      BookingHeader?.ConsignorCode?.length > 0
    ) {
      if (role === 'Buyer') {
        let tempAdd = { ...userData.address }
        tempAdd.supplierAddress = userData?.viewSupplier[0]?.Addresses
        dispatch(updateUserData({ keyName: 'address', value: tempAdd }))
      } else if (role === 'Supplier') {
        let tempAdd = { ...userData.address }
        tempAdd.buyerAddress = userData.viewBuyers[0]?.Addresses
        dispatch(updateUserData({ keyName: 'address', value: tempAdd }))
      }
    }
  }, [
    BookingHeader.ConsigneeCode,
    BookingHeader.ConsignorCode,
    selectedAddress
  ])

  useEffect(() => {
    if (BookingHeader?.TransportMode !== '' && transPortModes.length > 0) {
      setTransport(() =>
        transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.TransportMode
        })
      )
    }
    if (
      BookingHeader?.Carotype !== '' &&
      movementType.length > 0 &&
      !cargoType?.Code
    ) {
      setCargoType(() =>
        transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.Carotype
        })
      )
    }
    if (
      BookingHeader?.CarrierCode !== '' &&
      carrierCodes.length > 0 &&
      !carrier?.Code
    ) {
      setCarrier(() => {
        return transPortModes?.find((item, index) => {
          return item.Code === BookingHeader?.CarrierCode
        })
      })
    }
  }, [BookingHeader, transPortModes])

  useEffect(() => {
    getTransportMode()
  }, [])

  useEffect(() => {
    getCarrierCodes(BookingHeader?.TransportMode)
    getMovementType(BookingHeader?.TransportMode)
  }, [BookingHeader?.TransportMode, transportMode])

  return (
    <div className='buyer-supplier input-gap'>
      {/* From */}
      <div className='section-header'>
        <h6>From</h6>
      </div>

      <div className='section'>
        <div className='left-subsection' ref={leftSubsectionFromRef}>
          {edit[1].editSupplier ? (
            <div
              className={isAddingFrom ? 'new-component' : 'remove-component'}
            >
              <TextField
                size='small'
                label='Consignor name'
                variant='outlined'
                value={BookingHeader?.ConsignorName || ''} 
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  handleUpdateDetails('ConsignorName', e.target.value);
                  let temp = [...edit]
                  temp[0].newName = e.target.value
                  setEdit(temp)
                }}
                onFocus={() => {
                  handleFocus('addConsignerFrom')
                }}
                onBlur={() => handleBlur('addConsignerFrom')}
                InputProps={{
                  endAdornment: (
                    <div title='remove consignor'>
                      {!(isFocused.addConsignerFrom || edit[0].newName) && (
                        <InputAdornment position='end'>
                          <RemoveIcon
                            className='add-icon'
                            color='secondary'
                            fontSize='small'
                            onClick={() => {
                              setIsAddingFrom(false)
                              setTimeout(() => {
                                let temp = [...edit]
                                temp[1].editSupplier = !temp[1].editSupplier
                                setEdit(temp)
                                setSelectedAddress(prev => ({
                                  ...prev,
                                  supplierAddress: ''
                                }))
                                handleBlur('enterConsignerFrom')
                              }, 500)
                            }}
                          />
                        </InputAdornment>
                      )}
                    </div>
                  )
                }}
              />
              <TextField
                size='small'
                label='Address 1'
                variant='outlined'
                sx={{ width: '100%', marginBottom: '17px' }}
                value={BookingHeader?.ConsignorAddress1 || ''} 
                onChange={e => {
                  handleUpdateDetails('ConsignorAddress1', e.target.value);
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                size='small'
                label='Address 2'
                variant='outlined'
                value={BookingHeader?.ConsignorAddress2 || ''} 
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  handleUpdateDetails('ConsignorAddress2', e.target.value);
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                size='small'
                label='State'
                variant='outlined'
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                type='number'
                size='small'
                value={BookingHeader?.OriginPinCode || ''} 
                // value={edit[1]?.newPin}
                label='Pincode'
                variant='outlined'
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  if (e.target.value >= 0) {
                    handleUpdateDetails('OriginPinCode', e.target.value);
                    let temp = [...edit]
                    temp[1].newPin = e.target.value
                    setEdit(temp)
                  }
                }}
              />
            </div>
          ) : (
            <div
              className={!isAddingFrom ? 'new-component' : 'remove-component'}
            >
              <Autocomplete
                sx={{ width: '100%' }}
                freeSolo
                className='new-component-auto'
                autoHighlight
                size='small'
                disabled={
                  role === 'Buyer' && !userData?.selectedAddress?.buyerAddress
                    ? true
                    : false
                }
                disablePortal
                id='combo-box-demo'
                onChange={(e, val) => {
                  dispatch(
                    updateUserData({ keyName: 'selectedSupplier', value: val })
                  )
                  handleUpdateDetails('ConsignorName', val?.OrgFullName)
                  handleUpdateDetails('ConsignorCode', val?.OrgCode)
                }}
                onInputChange={(e, val, reason) => {
                  if (reason === 'clear') {
                    if (createBooking?.userType === 'Buyer') {
                      let temp = { ...selectedAddress }
                      temp.supplierAddress = ''
                      setSelectedAddress(temp)
                    } else {
                      dispatch(
                        updateUserData({
                          keyName: 'selectedAddress',
                          value: {
                            buyerAddress: '',
                            supplierAddress: ''
                          }
                        })
                      )
                      dispatch(
                        updateUserData({
                          keyName: 'address',
                          value: {
                            buyerAddress: [],
                            supplierAddress: []
                          }
                        })
                      )
                    }
                  }
                }}
                options={userData?.viewSupplier}
                value={userData?.selectedSupplier}
                defaultValue={userData?.viewSupplier}
                getOptionLabel={option => `${option?.OrgFullName}`}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Please enter consignor name'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      onFocus: () => handleFocus('enterConsignerFrom'),
                      onBlur: () => handleBlur('enterConsignerFrom'),
                      endAdornment: (
                        <div title='Add new consignor'>
                          {params.InputProps.endAdornment}
                          {!(
                            isFocused.enterConsignerFrom ||
                            userData?.selectedSupplier
                          ) && (
                            <InputAdornment position='end'>
                              <AddIcon
                                className='add-icon'
                                color='secondary'
                                fontSize='small'
                                onClick={() => {
                                  setIsAddingFrom(true)
                                  setTimeout(() => {
                                    let temp = [...edit]
                                    temp[1].editSupplier = !temp[1].editSupplier
                                    setEdit(temp)
                                    setSelectedAddress(prev => ({
                                      ...prev,
                                      supplierAddress: ''
                                    }))
                                  }, 500)
                                }}
                              />
                            </InputAdornment>
                          )}
                        </div>
                      )
                    }}
                  />
                )}
              />

              <Autocomplete
                className='new-component-auto'
                sx={{ width: '100%' }}
                freeSolo
                autoHighlight
                size='small'
                disabled={
                  role === 'Buyer' && !userData?.selectedAddress?.buyerAddress
                    ? true
                    : false
                }
                disablePortal
                id='combo-box-demo'
                onChange={(e, val) => {
                  handleUpdateDetails('ConsignorAddress1', val?.Address1)
                  handleUpdateDetails('ConsignorAddress2', val?.Address2)
                  let temp = { ...userData?.selectedAddress }
                  temp.supplierAddress = val
                  dispatch(
                    updateUserData({ keyName: 'selectedAddress', value: temp })
                  )
                }}
                options={userData?.address?.supplierAddress}
                value={userData?.selectedAddress?.supplierAddress}
                getOptionLabel={option => `${option?.ShortCode}`}
                renderInput={params => (
                  <TextField
                    {...params}
                    label='Please enter consignor address'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />

              {userData?.selectedAddress?.supplierAddress && (
                <div className='new-component'>
                  <TextField
                    size='small'
                    label='Address 1'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={userData?.selectedAddress?.supplierAddress?.Address1}
                  />
                  <TextField
                    size='small'
                    label='Address 2'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={userData?.selectedAddress?.supplierAddress?.Address2}
                  />
                  <TextField
                    size='small'
                    label='City'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={userData?.selectedAddress?.supplierAddress?.City}
                  />
                  <TextField
                    type='number'
                    size='small'
                    label='Pincode'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={userData?.selectedAddress?.supplierAddress?.PinCode}
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className={'right-subsection'}>
          <div className='d-flex disabledTextField'>
            <Autocomplete
              className='new-component-auto'
              sx={{ width: '100%' }}
              size='small'
              freeSolo
              autoHighlight
              disablePortal
              onChange={(e, val) => {
                handleUpdateDetails('OriginPort', val?.Code)
                handleUpdateDetails('OriginPortName', val?.Name)
                handleOriginDetails(val)
              }}
              id='combo-box-demo'
              options={originunloco}
              value={originselectedunloco}
              getOptionLabel={option =>
                option?.Code
                  ? `${option?.Code} - ${option?.Name} - ${option?.CountryName}`
                  : ''
              }
              renderInput={params => (
                <TextField
                  required
                  error={
                    errorBooking.Booking.origin
                      ? errorBooking.Booking.origin.error
                      : false
                  }
                  helperText={
                    errorBooking.Booking.origin
                      ? errorBooking.Booking.origin.label
                      : ''
                  }
                  {...params}
                  placeholder='Start typing to search'
                  label={
                    dropDownloader.origin
                      ? 'Origin Port loading'
                      : 'Origin Port'
                  }
                  onChange={e => {
                    debouncedGetOrg(e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              maxDate={BookingHeader?.ETA}
              label='Pickup Required by'
              value={BookingHeader?.ETD ?? null}
              inputFormat='DD/MM/YYYY'
              onChange={e => {
                handleUpdateDetails('ETD', e)
              }}
              renderInput={params => (
                <TextField
                  required
                  size='small'
                  style={{ width: '100%' }}
                  {...params}
                  color='primary'
                  error={
                    errorBooking.Booking.etd
                      ? errorBooking.Booking.etd.error
                      : null
                  }
                  helperText={
                    errorBooking.Booking.etd
                      ? errorBooking.Booking.etd.label
                      : ''
                  }
                  defaultValue={''}
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className='divider' />

      {/* To */}
      <div className='section-header'>
        <h6>To</h6>
      </div>

      <div className='section'>
        <div className='left-subsection' ref={leftSubsectionToRef}>
          {edit[0].editBuyer ? (
            <div className={isAddingTo ? 'new-component' : 'remove-component'}>
              <TextField
                size='small'
                label='Consignee name'
                variant='outlined'
                value={BookingHeader?.ConsigneeName || ''} 
                sx={{ width: '100%', marginBottom: '15px' }}
                onChange={e => {
                  handleUpdateDetails('ConsigneeName', e.target.value);
                  let temp = [...edit]
                  temp[0].newName = e.target.value
                  console.log("temp",e.target.value,temp);
                  
                  setEdit(temp)
                }}
                onFocus={() => {
                  handleFocus('addConsignerTo')
                }}
                onBlur={() => handleBlur('addConsignerTo')}
                InputProps={{
                  endAdornment: (
                    <div title='remove consignor'>
                      {!(isFocused.addConsignerTo || edit[0].newName) && (
                        <InputAdornment position='end'>
                          <RemoveIcon
                            className='add-icon'
                            color='secondary'
                            fontSize='small'
                            onClick={() => {
                              setIsAddingTo(false)
                              setTimeout(() => {
                                let temp = [...edit]
                                temp[0].editBuyer = !temp[0]?.editBuyer
                                setEdit(temp)
                                setSelectedAddress(prev => {
                                  return {
                                    ...prev,
                                    buyerAddress: ''
                                  }
                                })
                                handleBlur('enterConsignerTo')
                              }, 300)
                            }}
                          />
                        </InputAdornment>
                      )}
                    </div>
                  )
                }}
              />
              
              <TextField
                size='small'
                label='Address 1'
                variant='outlined'
                value={BookingHeader?.ConsigneeAddress1 || ''} 
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  handleUpdateDetails('ConsigneeAddress1', e.target.value);
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                size='small'
                label='Address 2'
                variant='outlined'
                value={BookingHeader?.ConsigneeAddress2 || ''} 
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  handleUpdateDetails('ConsigneeAddress2', e.target.value);
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                size='small'
                label='City'
                variant='outlined'
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  let temp = [...edit]
                  temp[0].newAddress = e.target.value
                  setEdit(temp)
                }}
              />
              <TextField
                type='number'
                size='small'
                // value={edit[0]?.newPin}
                value={BookingHeader?.DestinationPinCode || ''} 
                label='Pincode'
                variant='outlined'
                sx={{ width: '100%', marginBottom: '17px' }}
                onChange={e => {
                  handleUpdateDetails('DestinationPinCode', e.target.value);
                  if (e.target.value >= 0) {
                    let temp = [...edit]
                    temp[0].newPin = e.target.value
                    setEdit(temp)
                  }
                }}
              />
            </div>
          ) : (
            <div className={!isAddingTo ? 'new-component' : 'remove-component'}>
              <Autocomplete
                sx={{ width: '100%' }}
                className='new-component-auto'
                freeSolo
                autoHighlight
                size='small'
                disabled={
                  role === 'Supplier' &&
                  !userData?.selectedAddress?.supplierAddress
                    ? true
                    : false
                }
                disablePortal
                id='combo-box-demo'
                onChange={(e, val) => {
                  dispatch(
                    updateUserData({ keyName: 'selectedBuyer', value: val })
                  )
                  handleUpdateDetails('ConsigneeName', val?.OrgFullName)
                  handleUpdateDetails('ConsigneeCode', val?.OrgCode)
                }}
                onInputChange={(e, value, reason) => {
                  if (reason === 'clear') {
                    if (createBooking?.userType === 'Supplier') {
                      let temp = { ...selectedAddress }
                      temp.buyerAddress = ''
                      setSelectedAddress(temp)
                    } else {
                      setSelectedAddress({
                        buyerAddress: '',
                        supplierAddress: ''
                      })
                      setAddress({
                        buyerAddress: [],
                        supplierAddress: []
                      })
                    }
                  }
                }}
                options={userData?.viewBuyers}
                value={userData?.selectedBuyer}
                getOptionLabel={option => `${option?.OrgFullName}`}
                renderInput={params => (
                  <TextField
                    required
                    error={
                      errorBooking?.Booking?.consignee
                        ? errorBooking?.Booking?.consignee?.error
                        : false
                    }
                    helperText={
                      errorBooking?.Booking?.consignee
                        ? errorBooking?.Booking?.consignee?.label
                        : ''
                    }
                    {...params}
                    label='Please select consignee name'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      onFocus: () => handleFocus('enterConsignerTo'),
                      onBlur: () => handleBlur('enterConsignerTo'),
                      endAdornment: (
                        <div title='Add new consignor'>
                          {params.InputProps.endAdornment}
                          {!(
                            isFocused.enterConsignerTo ||
                            userData?.selectedBuyer
                          ) && (
                            <InputAdornment position='end'>
                              <AddIcon
                                className='add-icon'
                                color='secondary'
                                fontSize='small'
                                onClick={() => {
                                  setIsAddingTo(true)
                                  setTimeout(() => {
                                    let temp = [...edit]
                                    temp[0].editBuyer = !temp[0]?.editBuyer
                                    setEdit(temp)
                                    setSelectedAddress(prev => {
                                      return {
                                        ...prev,
                                        buyerAddress: ''
                                      }
                                    })
                                  }, 300)
                                }}
                              />
                            </InputAdornment>
                          )}
                        </div>
                      )
                    }}
                  />
                )}
              />

              <Autocomplete
                className='new-component-auto'
                sx={{ width: '100%' }}
                freeSolo
                autoHighlight
                size='small'
                disabled={
                  role === 'Supplier' &&
                  !userData?.selectedAddress?.supplierAddress
                    ? true
                    : false
                }
                disablePortal
                id='combo-box-demo'
                onChange={(e, val) => {
                  handleUpdateDetails('ConsigneeAddress1', val?.Address1)
                  handleUpdateDetails('ConsigneeAddress2', val?.Address2)
                  let temp = { ...userData.selectedAddress }
                  temp.buyerAddress = val
                  dispatch(
                    updateUserData({ keyName: 'selectedAddress', value: temp })
                  )
                }}
                options={userData?.address?.buyerAddress}
                value={userData?.selectedAddress?.buyerAddress}
                getOptionLabel={option => `${option?.ShortCode}`}
                renderInput={params => (
                  <TextField
                    required
                    error={
                      errorBooking?.Booking?.consigneeAddress
                        ? errorBooking?.Booking?.consigneeAddress?.error
                        : false
                    }
                    helperText={
                      errorBooking?.Booking?.consigneeAddress
                        ? errorBooking?.Booking?.consigneeAddress?.label
                        : ''
                    }
                    {...params}
                    label='Please enter consignee address'
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />

              {userData?.selectedAddress?.buyerAddress && (
                <div className='new-component'>
                  <TextField
                    size='small'
                    label='Address 1'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={
                      userData?.selectedAddress?.buyerAddress?.Address1 ?? ''
                    }
                  />
                  <TextField
                    size='small'
                    label='Address 2'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={
                      userData?.selectedAddress?.buyerAddress?.Address2 ?? ''
                    }
                  />
                  <TextField
                    size='small'
                    label='City'
                    disabled
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={userData?.selectedAddress?.buyerAddress?.City ?? ''}
                  />
                  <TextField
                    type='number'
                    size='small'
                    disabled
                    label='Pincode'
                    variant='outlined'
                    sx={{ width: '100%', marginBottom: '17px' }}
                    value={
                      userData?.selectedAddress?.buyerAddress?.PinCode ?? ''
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className={'right-subsection'}>
          <div className='d-flex disabledTextField'>
            <Autocomplete
              className='new-component-auto'
              sx={{ width: '100%' }}
              freeSolo
              autoHighlight
              size='small'
              disablePortal
              id='combo-box-demo'
              onChange={(e, val) => {
                handleUpdateDetails('DestinationPort', val?.Code)
                handleUpdateDetails('DestinationPortName', val?.Name)
                dispatch(destselectedpage(val))
              }}
              options={destunloco}
              value={destselectedunloco}
              getOptionLabel={option =>
                option?.Code
                  ? `${option?.Code} - ${option?.Name} - ${option?.CountryName}`
                  : ''
              }
              renderInput={params => (
                <TextField
                  required
                  error={
                    errorBooking.Booking.destination
                      ? errorBooking.Booking.destination.error
                      : false
                  }
                  helperText={
                    errorBooking.Booking.destination
                      ? errorBooking.Booking.destination.label
                      : ''
                  }
                  {...params}
                  label={
                    dropDownloader.destination
                      ? 'Destination Port loading'
                      : 'Destination Port'
                  }
                  placeholder='Start typing to search'
                  onChange={e => {
                    debouncedGetDest(e.target.value)
                  }}
                  InputProps={{
                    ...params.InputProps,
                    type: 'search'
                  }}
                />
              )}
            />
          </div>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label='Delivery Required by'
              minDate={BookingHeader?.ETD}
              // valueDefault={null}
              value={BookingHeader?.ETA ?? null}
              inputFormat='DD/MM/YYYY'
              onChange={e => {
                handleUpdateDetails('ETA', e)
              }}
              renderInput={params => (
                <TextField
                  required
                  size='small'
                  style={{ width: '100%' }}
                  {...params}
                  color='primary'
                  error={
                    errorBooking.Booking.eta
                      ? errorBooking.Booking.eta.error
                      : null
                  }
                  helperText={
                    errorBooking.Booking.eta
                      ? errorBooking.Booking.eta.label
                      : ''
                  }
                />
              )}
            />
          </LocalizationProvider>
        </div>
      </div>

      <div className='divider' />

      {/* Transport */}
      <div className='section-header'>
        <h6>Transport</h6>
      </div>

      <div className='section'>
        <div className={'left-subsection'}>
          <Autocomplete
            className='new-component-auto'
            sx={{ width: '100%' }}
            freeSolo
            autoHighlight
            size='small'
            disabled={location.pathname.includes('edit') ? true : false}
            disablePortal
            id='combo-box-demo'
            onChange={(e, val) => {
              handleUpdateDetails('TransportMode', val?.Code)
              setCargoType({})
              setCarrier({})
            }}
            options={transPortModes}
            value={transport}
            getOptionLabel={option =>
              option?.Description ? `${option?.Description}` : ''
            }
            renderInput={params => (
              <TextField
                required
                error={
                  errorBooking.Booking.transportmode
                    ? errorBooking.Booking.transportmode.error
                    : null
                }
                helperText={
                  errorBooking.Booking.transportmode
                    ? errorBooking.Booking.transportmode.label
                    : ''
                }
                {...params}
                label='Transport Mode'
                InputProps={{
                  ...params.InputProps,
                  type: 'search'
                }}
              />
            )}
          />
          <Autocomplete
            sx={{ width: '100%' }}
            freeSolo
            autoHighlight
            size='small'
            disablePortal
            id='combo-box-demo'
            disabled={location.pathname.includes('edit') ? true : false}
            onChange={(e, val) => {
              handleUpdateDetails('Carotype', val?.Code)
            }}
            options={movementType}
            value={cargoType}
            getOptionLabel={option =>
              option?.Description
                ? `${option?.Description} (${option?.Code})`
                : ''
            }
            renderInput={params => (
              <TextField
                required
                {...params}
                error={
                  errorBooking.Booking.cargotype
                    ? errorBooking.Booking.cargotype.error
                    : null
                }
                helperText={
                  errorBooking.Booking.cargotype
                    ? errorBooking.Booking.cargotype.label
                    : ''
                }
                label='Cargo Type'
                InputProps={{
                  ...params.InputProps,
                  type: 'search'
                }}
              />
            )}
          />
        </div>

        <div className={'right-subsection'}>
          <Autocomplete
            className='new-component-auto'
            sx={{ width: '100%' }}
            freeSolo
            autoHighlight
            size='small'
            // disablePortal
            // disabled={location.pathname.includes("edit") ? true : false}
            onChange={(e, val) => {
              handleUpdateDetails('CarrierName', val?.FullName)
              handleUpdateDetails('CarrierCode', val?.Code)
              setCarrier(val)
            }}
            options={carrierCodes}
            value={carrier}
            getOptionLabel={option => option?.FullName || ''}
            renderInput={params => (
              <TextField
                {...params}
                label='Preferred Carrier'
                InputProps={{
                  ...params.InputProps,
                  type: 'search'
                }}
                onChange={e => {
                  handleCarrierDetails('CarrierName', e.target.value)
                }}
              />
            )}
          />
          <TextField
            size='small'
            label='Description'
            value={BookingHeader?.GoodsDescription ?? ''}
            onChange={e => {
              handleUpdateDetails('GoodsDescription', e.target.value)
            }}
            variant='outlined'
            sx={{ width: '100%', marginBottom: '15px' }}
          />
        </div>
      </div>

      <FormControl component='fieldset' variant='standard'>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1-content'
            id='panel1-header'
            className='addCheck'
          >
            <p className='m-0'>Additional Services</p>
            <p className='line flex-fill my-0 mx-2' />
          </AccordionSummary>
          <AccordionDetails>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      BookingHeader?.AdditionalServices?.length &&
                      BookingHeader?.AdditionalServices[0]?.IsTrue
                    }
                    onChange={e => {
                      dispatch(
                        updateAdditionalServices({
                          index: 0,
                          value: e.target.checked
                        })
                      )
                    }}
                    name='insurance'
                  />
                }
                label='Insurance'
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={
                      BookingHeader?.AdditionalServices?.length &&
                      BookingHeader?.AdditionalServices[1]?.IsTrue
                    }
                    onChange={e => {
                      dispatch(
                        updateAdditionalServices({
                          index: 1,
                          value: e.target.checked
                        })
                      )
                    }}
                    name='custom-clearance'
                  />
                }
                label='Custom Clearance'
              />
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      </FormControl>
    </div>
  )
}

export default BuyerSupplier
