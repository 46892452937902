export default function TableHeader() {
  return (
    <thead style={{ fontFamily: 'var(--family-label)' }}>
      <tr>
        <th>Job No</th>
        <th>Document Type</th>
        <th>Document Name</th>
        <th>Date Uploaded</th>
        <th>Status</th>

        <th>Latest Update</th>
        <th
          style={{
            textAlign: 'center'
          }}
        >
          Preview
        </th>
        <th
          style={{
            textAlign: 'center'
          }}
        >
          Review
        </th>
        <th>Actions</th>
      </tr>
    </thead>
  )
}
