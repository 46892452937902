import ListFilters from './_components/Dashboard/Filter'
import Header from './_components/Dashboard/Header'
import List from './_components/Dashboard/List'
import ReviewDialogue from './_components/Dashboard/ReviewDialogue'

export default function DocumentApproval() {
  return (
    <div>
      {/* header */}
      <Header />
      {/* List */}
      <List />
      {/* dialog */}
      <ListFilters />
      <ReviewDialogue />
    </div>
  )
}
