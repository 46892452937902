import React, { useState } from 'react';
import {
  Autocomplete,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBookingPackages,
  deleteBookingPackages,
  updateBookingPackages,
} from '../../../../../redux/bookingRedux/bookingBasicDetailSlice';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const Air = () => {
  const {
    bookingBasicDetails: { BookingPackages },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [expandedRow, setExpandedRow] = useState(null);

  const handleAdd = () => {
    dispatch(addBookingPackages());
  };

  const handleDelete = (index) => {
    dispatch(deleteBookingPackages({ index: index }));
  };

  const handleExpandClick = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  return (
    <>
      <div className="package-list">
        <div className="package-header">
          <div className="package-summary-item">Packaging Type</div>
          <div className="package-summary-item">Quantity</div>
          <div className="package-summary-item">Weight per Piece</div>
          <div className="package-summary-item">Weight Unit</div>
          <div className="package-summary-item">Unit</div>
          <div className="package-summary-item">Height</div>
          <div className="package-summary-item">Length</div>
          <div className="package-summary-item">Width</div>
          <div className="package-summary-item">Volume</div>
          <div className="package-summary-item">Stackable</div>
          <div className="package-summary-item">Actions</div>
        </div>
        {BookingPackages.map((item, index) => (
          <div
            key={index}
            className={`package-item ${expandedRow === index ? 'expanded' : ''}`}
          >
            <div className="package-summary" onClick={() => handleExpandClick(index)}>
              <div className="package-summary-item">{item.PakageTypeName}</div>
              <div className="package-summary-item">{item.PackageQuantity}</div>
              <div className="package-summary-item">{item.WeightPerPiece}</div>
              <div className="package-summary-item">{item.WeightUnit}</div>
              <div className="package-summary-item">{item.Unit}</div>
              <div className="package-summary-item">{item.Height}</div>
              <div className="package-summary-item">{item.Length}</div>
              <div className="package-summary-item">{item.Width}</div>
              <div className="package-summary-item">
                {item.Unit === 'cm'
                  ? (
                      (((item.Height / 100) * item.Width) / 100) * item.Length
                    ).toFixed(4)
                  : (
                      (((item.Height / 39.37) * item.Width) / 39.37) * item.Length
                    ).toFixed(3)}
              </div>
              <div className="package-summary-item">
                <Switch
                  size="small"
                  checked={item.IsStackable}
                  onChange={(e) => {
                    dispatch(
                      updateBookingPackages({
                        index: index,
                        name: 'IsStackable',
                        value: e.target.checked,
                      })
                    );
                  }}
                />
              </div>
              <div className="package-summary-item">
                <IconButton onClick={() => handleDelete(index)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleExpandClick(index)}>
                  {expandedRow === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </div>
            </div>

            {expandedRow === index && (
              <div className="package-details">
                <div className="package-detail-item">
                  <FormControl size="small" fullWidth>
                    <InputLabel>Packaging Type</InputLabel>
                    <Select
                      size="small"
                      label="PackagingType"
                      value={item.PakageTypeName}
                      onChange={(e) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'PakageTypeName',
                            value: e.target.value,
                          })
                        );
                      }}
                    >
                      <MenuItem value="Pallets">Pallets</MenuItem>
                      <MenuItem value="Skids">Skids</MenuItem>
                      <MenuItem value="Crates">Crates</MenuItem>
                      <MenuItem value="Boxes">Boxes</MenuItem>
                      <MenuItem value="Cartons">Cartons</MenuItem>
                      <MenuItem value="Cases">Cases</MenuItem>
                      <MenuItem value="Packages">Packages</MenuItem>
                      <MenuItem value="Drums">Drums</MenuItem>
                      <MenuItem value="Bags">Bags</MenuItem>
                      <MenuItem value="Rolls">Rolls</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Quantity"
                    size="small"
                    value={item.PackageQuantity}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'PackageQuantity',
                            value: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Weight per Piece"
                    size="small"
                    value={item.WeightPerPiece}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'WeightPerPiece',
                            value: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="package-detail-item">
                  <FormControl size="small" fullWidth>
                    <InputLabel>Weight Unit</InputLabel>
                    <Select
                      label="WeightUnit"
                      size="small"
                      value={item.WeightUnit}
                      onChange={(e) => {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'WeightUnit',
                            value: e.target.value,
                          })
                        );
                      }}
                    >
                      <MenuItem value="KG">KG</MenuItem>
                      <MenuItem value="Ton">Ton</MenuItem>
                      <MenuItem value="Lb">Lb</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="package-detail-item">
                  <Autocomplete
                    freeSolo
                    size="small"
                    disablePortal
                    value={{ label: item.Unit ?? '' }}
                    options={[
                      { label: 'cm' },
                      { label: 'in' },
                      // Add more dimension units as needed
                    ]}
                    onChange={(e, val) => {
                      dispatch(
                        updateBookingPackages({
                          index: index,
                          name: 'Unit',
                          value: val?.label,
                        })
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Dimension Unit" />
                    )}
                  />
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Height"
                    size="small"
                    value={item.Height}
                    fullWidth
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'Height',
                            value: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Length"
                    fullWidth
                    size="small"
                    value={item.Length}
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'Length',
                            value: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Width"
                    fullWidth
                    size="small"
                    value={item.Width}
                    type="number"
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        dispatch(
                          updateBookingPackages({
                            index: index,
                            name: 'Width',
                            value: e.target.value,
                          })
                        );
                      }
                    }}
                  />
                </div>
                <div className="package-detail-item">
                  <TextField
                    label="Volume"
                    fullWidth
                    disabled
                    size="small"
                    value={
                      item.Unit === 'cm'
                        ? (
                            (((item.Height / 100) * item.Width) / 100) * item.Length
                          ).toFixed(4)
                        : (
                            (((item.Height / 39.37) * item.Width) / 39.37) * item.Length
                          ).toFixed(3)
                    }
                  />
                </div>
                <div className="package-detail-item">
                  <Switch
                    size="small"
                    checked={item.IsStackable}
                    onChange={(e) => {
                      dispatch(
                        updateBookingPackages({
                          index: index,
                          name: 'IsStackable',
                          value: e.target.checked,
                        })
                      );
                    }}
                  />
                  <label>Stackable</label>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="button-div">
          <button className="button-style" onClick={handleAdd}>
            Add Package
          </button>
        </div>
      </div>
    </>
  );
};

export default Air;
