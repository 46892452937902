import * as React from 'react'
import Slide from '@mui/material/Slide'
import MenuItem from '@mui/material/MenuItem'
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Box,
  Slider,
  TextField,
  Divider,
  Menu
} from '@mui/material'

import './filterbooking.css'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'
import moment from 'moment'
import { KeyboardBackspace } from '@mui/icons-material'
import { useState } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { DtCalendar } from 'react-calendar-datetime-picker'
import 'react-calendar-datetime-picker/dist/style.css'
import AirplaneIcon from '@mui/icons-material/AirplanemodeActive'
import ShipIcon from '@mui/icons-material/LocalShipping'
import TrainIcon from '@mui/icons-material/Train'
import InventoryIcon from '@mui/icons-material/Inventory'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import { useEffect } from 'react'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const HeadingText = ({ children }) => {
  return (
    <p
      style={{
        margin: '0',
        fontWeight: 400,
        lineHeight: 1.5,
        fontSize: 'var(--fs-lg)',
        fontFamily: 'var(--family-label)',
        width: '49%'
      }}
    >
      {children}
    </p>
  )
}
const FieldContainer = ({ children, customcss }) => {
  return (
    <div className='child-container' style={customcss}>
      {children}
    </div>
  )
}
export default function FilterModalBooking({
  open,
  handleClose,
  handleClickOpen,
  filterOptions,
  setFilterOptions,
  handleClear,
  handleBack,
  handleFilter
}) {
  // useEffect(() => {
  //   console.log('filterOptions', filterOptions)
  // }, [filterOptions])

  const [orgDestPair, setOrgDestPair] = React.useState({
    originPort: [],
    destinationPort: []
  })
  const [selected, setSelected] = React.useState({
    origin: [],
    destination: []
  })
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [selectedTransportMode, setSelectedTransportMode] = useState(
    filterOptions?.transportMode
  )
  const [sliderValues, setSliderValues] = useState({
    created: 0,
    etd: 0,
    eta: 0
  })
  const [currentField, setCurrentField] = useState(null)
  const [calendarKey, setCalendarKey] = useState(0)

  const resetCalendar = () => {
    setCalendarKey(prevKey => prevKey + 1) // This will force remount the DtCalendar
  }

  const handleOpenUserMenu = (event, field) => {
    setAnchorElUser(event.currentTarget)
    setCurrentField(field)
    setSliderValues(prevValues => ({
      ...prevValues,
      [field]: 0
    }))
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
    setCurrentField(null)
  }
  const {
    data: transportmodes,
    isError,
    getData: getTransportModes
  } = GetData('get', API_PATH.GET_TRANSPORT_MODE, {})

  const getOrg = search => {
    let body = {
      CountryCode: '',
      Search: search ?? ''
    }

    instance
      .post('api/v1/master/GetUnlocoInfo', body)
      .then(res => {
        let data = { ...orgDestPair }
        data.originPort = res?.data
        setOrgDestPair(data)
      })
      .catch(err => console.log(err))
      .finally(() => {})
  }
  const getDest = search => {
    let body = {
      CountryCode: '',
      Search: search ?? ''
    }

    instance
      .post('api/v1/master/GetUnlocoInfo', body)
      .then(res => {
        let data = { ...orgDestPair }
        data.destinationPort = res?.data
        setOrgDestPair(data)
      })
      .catch(err => console.log(err))
      .finally(() => {})
  }

  useEffect(() => {
    getTransportModes()
  }, [])

  const handleFilterChange = (filterName, value) => {
    setFilterOptions({ ...filterOptions, [filterName]: value })
  }

  const formatDate = date => {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
  }

  const handleSliderChange = (event, newValue, field) => {
    let newFilterOptions = { ...filterOptions }
    const now = moment()
    newFilterOptions[`${field}From`] = formatDate(
      now.subtract(newValue, 'days').toDate()
    )
    newFilterOptions[`${field}To`] = formatDate(new Date())
    setFilterOptions(newFilterOptions)

    setSliderValues(prevValues => ({
      ...prevValues,
      [field]: newValue
    }))
  }
  const handleCalenderChange = (val, field) => {
    if (val?.from && val?.to) {
      let newFilterOptions = { ...filterOptions }
      newFilterOptions[`${field}From`] = formatDate(
        new Date(val.from.year, val.from.month - 1, val.from.day)
      )
      newFilterOptions[`${field}To`] = formatDate(
        new Date(val.to.year, val.to.month - 1, val.to.day)
      )
      setFilterOptions(newFilterOptions)
    }
  }
  const marks = [
    { value: 0, label: '' },
    { value: 7, label: '7D' },
    { value: 14, label: '14D' },
    { value: 30, label: '30D' }
  ]
  const transportIcons = {
    air: <AirplaneIcon />,
    sea: <DirectionsBoatFilledIcon />,
    COU: <InventoryIcon />,
    RAI: <TrainIcon />,
    ROA: <ShipIcon />
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth='sm'
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogActions
          className='justify-content-between'
          style={{ padding: '20px 24px 0px 24px' }}
        >
          <Button
            onClick={() => {
              handleBack()
              setSliderValues({
                created: 0,
                etd: 0,
                eta: 0
              })
              setSelected({
                origin: [],
                destination: []
              })
              setSelectedTransportMode('')
              setFilterOptions({
                status: '',
                createdFrom: null,
                createdTo: null,
                etdFrom: null,
                etdTo: null,
                etaFrom: null,
                etaTo: null
              })
            }}
          >
            <KeyboardBackspace color='primary' />
            Back
          </Button>
          <Button
            onClick={() => {
              handleClear()
              setSelectedTransportMode('')
              setSelected({
                origin: [],
                destination: []
              })
              setOrgDestPair({
                origin: [],
                destination: []
              })
              setSliderValues({
                created: 0,
                etd: 0,
                eta: 0
              })
              setFilterOptions({
                transportMode: null,
                consignee: null,
                consignor: null,
                origin: null,
                destination: null,
                createdBy: null,
                etaFrom: null,
                etaTo: null,
                etdFrom: null,
                etdTo: null,
                status: null,
                createdFrom: null,
                createdTo: null
              })
              resetCalendar()
            }}
          >
            Reset All
          </Button>
        </DialogActions>

        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem'
          }}
        >
          <Divider
            sx={{
              backgroundColor: 'var(--color-miscellaneous-1)',
              justifyContent: 'center'
            }}
          />
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Status</HeadingText>

              <div style={{ display: 'flex', gap: '32px' }}>
                {status.map(mode => (
                  <Button
                    key={mode.value}
                    variant={
                      filterOptions?.status === mode.value
                        ? 'contained'
                        : 'outlined'
                    }
                    onClick={() => handleFilterChange('status', mode.value)}
                  >
                    {mode.label}
                  </Button>
                ))}
              </div>
            </FieldContainer>
          </div>

          {['created', 'etd', 'eta'].map(field => (
            <div className='parent-container' key={field}>
              <FieldContainer>
                <HeadingText>
                  {field === 'created'
                    ? field.charAt(0).toUpperCase() + field.slice(1)
                    : field.toUpperCase()}
                </HeadingText>
                <div className='grand-container'>
                  <Box sx={{ width: 300 }}>
                    <Slider
                      aria-label='Restricted values'
                      // defaultValue={30}
                      value={sliderValues[field]}
                      getAriaValueText={value => `${value} days`}
                      step={null}
                      valueLabelDisplay='auto'
                      marks={marks}
                      onChangeCommitted={(e, newValue) =>
                        handleSliderChange(e, newValue, field)
                      }
                      min={0}
                      max={30}
                    />
                  </Box>
                  <div className='calender-section'>
                    <IconButton onClick={e => handleOpenUserMenu(e, field)}>
                      <CalendarMonthIcon color='primary' />
                    </IconButton>
                    <Menu
                      className='check-date-div'
                      sx={{ mt: '45px' }}
                      id='menu-appbar'
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      open={Boolean(anchorElUser) && currentField === field}
                      onClose={handleCloseUserMenu}
                    >
                      <MenuItem
                        sx={{
                          padding: '0 ',
                          margin: '0'
                        }}
                      >
                        <div
                          className='date-picker-section '
                          style={{
                            minWidth: '100%',
                            height: '100%'
                          }}
                        >
                          <DtCalendar
                            key={calendarKey}
                            type='range'
                            onChange={val => {
                              handleCalenderChange(val, field)
                            }}
                          />
                        </div>
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
              </FieldContainer>
            </div>
          ))}
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Transport mode</HeadingText>
              <div style={{ display: 'flex', gap: '38px' }}>
                {Object.keys(transportIcons).map(key => (
                  <IconButton
                    title={key.charAt(0).toUpperCase() + key.slice(1)}
                    key={key}
                    onClick={() => {
                      if (selectedTransportMode === key) {
                        setSelectedTransportMode(null)
                        handleFilterChange('transportMode', null)
                      } else {
                        setSelectedTransportMode(key)
                        handleFilterChange('transportMode', key)
                      }
                    }}
                    style={{
                      borderRadius: '50%',
                      padding: '10px',
                      color: selectedTransportMode === key ? '#fff' : '#236c9c',
                      backgroundColor:
                        selectedTransportMode === key ? '#236c9c' : ''
                    }}
                  >
                    {transportIcons[key]}
                  </IconButton>
                ))}
              </div>
            </FieldContainer>
          </div>
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Created by</HeadingText>

              <TextField
                style={{ marginBottom: '0px !important' }}
                fullWidth
                size='small'
                id='outlined-basic'
                value={filterOptions?.createdBy ?? ''}
                label=''
                variant='outlined'
                onChange={e => {
                  handleFilterChange('createdBy', e.target.value)
                }}
              />
            </FieldContainer>
          </div>
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Consignee</HeadingText>

              <TextField
                fullWidth
                size='small'
                id='outlined-basic'
                value={filterOptions?.consignee ?? ''}
                label=''
                variant='outlined'
                onChange={e => {
                  handleFilterChange('consignee', e.target.value)
                }}
              />
            </FieldContainer>
          </div>
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Consignor</HeadingText>

              <TextField
                fullWidth
                size='small'
                id='outlined-basic'
                value={filterOptions?.consignor ?? ''}
                label=''
                variant='outlined'
                onChange={e => {
                  handleFilterChange('consignor', e.target.value)
                }}
              />
            </FieldContainer>
          </div>
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Origin</HeadingText>

              <Autocomplete
                fullWidth
                freeSolo
                disablePortal
                options={orgDestPair?.originPort ?? []}
                onChange={(e, val) => {
                  handleFilterChange('origin', val.Code)
                  let temp = { ...selected }
                  temp.origin = val
                  setSelected(temp)
                }}
                value={selected?.origin}
                getOptionLabel={option =>
                  option.Name ? `${option.Name}( ${option.Code})` : ''
                }
                size='small'
                renderInput={params => (
                  <TextField
                    {...params}
                    label=''
                    onChange={e => {
                      if (e.target.value.length >= 3) {
                        getOrg(e.target.value)
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
            </FieldContainer>
          </div>
          <div className='parent-container'>
            <FieldContainer>
              <HeadingText>Destination</HeadingText>
              <Autocomplete
                fullWidth
                freeSolo
                disablePortal
                options={orgDestPair?.destinationPort ?? []}
                onChange={(e, val) => {
                  handleFilterChange('destination', val.Code)
                  let temp = { ...selected }
                  temp.destination = val
                  setSelected(temp)
                }}
                value={selected?.destination}
                getOptionLabel={option =>
                  option.Name ? `${option.Name}( ${option.Code})` : ''
                }
                size='small'
                renderInput={params => (
                  <TextField
                    {...params}
                    label=''
                    onChange={e => {
                      if (e.target.value.length >= 3) {
                        getDest(e.target.value)
                      }
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: 'search'
                    }}
                  />
                )}
              />
            </FieldContainer>      
          </div>
          {/* <div className="parent-container">
            <FieldContainer>
              <HeadingText>Created</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.createdTo}
                  valueDefault={null}
                  value={filterOptions?.createdFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("createdFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.createdFrom}
                  valueDefault={null}
                  value={filterOptions?.createdTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("createdTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div>
          <div className="parent-container">
            <FieldContainer>
              <HeadingText>ETD</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.etdTo}
                  valueDefault={null}
                  value={filterOptions?.etdFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etdFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.etdFrom}
                  valueDefault={null}
                  value={filterOptions?.etdTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etdTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div>

          <div className="parent-container">
            <FieldContainer>
              <HeadingText>ETA</HeadingText>
            </FieldContainer>
            <FieldContainer>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="From"
                  maxDate={filterOptions?.etaTo}
                  valueDefault={null}
                  value={filterOptions?.etaFrom ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etaFrom", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
            <FieldContainer customcss={{ marginLeft: "10px" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="To"
                  minDate={filterOptions?.etaFrom}
                  valueDefault={null}
                  value={filterOptions?.etaTo ?? null}
                  inputFormat="DD/MM/YYYY"
                  onChange={(e) => {
                    handleFilterChange("etaTo", formatDate(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      {...params}
                      color="primary"
                      error={null}
                    />
                  )}
                />
              </LocalizationProvider>{" "}
            </FieldContainer>
          </div> */}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleFilter}>Search</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
export const GetData = (type, apiUrl, body) => {
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const getData = () => {
    setLoading(true)
    instance[type](apiUrl, body)
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        setIsError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return { data, loading, isError, getData }
}
const status = [
  { label: 'Pending', value: 0 },
  { label: 'Accepted', value: 1 },
  { label: 'Rejected', value: 2 }
]
// -------
