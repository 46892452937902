import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchCurrentDoc } from '../../../../redux/documentRedux/documentListSlice'
import FileViewer from 'react-file-viewer'
import { genrateDownloadLink } from '../../../../utils/utility'
import { PDFDocument, StandardFonts, degrees, rgb } from 'pdf-lib'
import download from 'downloadjs'
export default function ViewLayout() {
  const {
    currentDoc: { isLoading }
  } = useSelector(state => state.documentApproval)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [type, setType] = useState('pdf')
  const [file, setFile] = useState('')
  const [docName, setDocName] = useState('')

  const { Email } = JSON.parse(localStorage.getItem('userData'))

  useEffect(() => {
    let tempPathArr = location.pathname.split('/document/view/')[1].split('__')
    let docpromise = dispatch(
      fetchCurrentDoc({
        ShipmentID: tempPathArr[1],
        DocumentPk: tempPathArr[0]
      })
    )
    docpromise.then(res => {
      if (res?.payload?.IsSuccess) {
        setDocName(res?.payload?.data[0]?.SC_FileName)
        const downloadLink = document.createElement('a')
        const prefix = genrateDownloadLink(
          res?.payload?.data[0]?.SC_FileName ?? ''
        )
        downloadLink.href = `${prefix}${
          res?.payload?.data[0]?.ImageDataVC ?? ''
        }`
        let link = `${prefix}${res?.payload?.data[0]?.ImageDataVC}`
        setFile(link)
      }
    })
  }, [])
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={true}
      onClose={() => {
        navigate('/document')
      }}
    >
      <DialogTitle>{docName.length > 0 ? docName : 'Preview'}</DialogTitle>
      <DialogContent
        style={{
          height: '90vh'
        }}
      >
        {isLoading || file.length <= 0 ? (
          <p>Please wait fetching data from server..</p>
        ) : (
          <DialogContentText>
            <div
              style={{
                zIndex: 1,
                position: 'absolute',
                height: '2rem',
                width: '2rem',
                opacity: '0.5',
                left: '35%',
                top: '50%',
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                transform: ' rotate(-45deg)'
              }}
            >
              <span
                style={{
                  color: 'red',
                  fontSize: 'var(--fs-md)',
                  fontWeight: '700'
                }}
              >
                Draft!
              </span>
              <span
                style={{
                  color: 'red',
                  fontSize: 'var(--fs-head)',
                  fontWeight: '700'
                }}
              >
                {Email}
              </span>
            </div>
            <FileViewer fileType={type} filePath={file} />
          </DialogContentText>
        )}
      </DialogContent>
    </Dialog>
  )
}
