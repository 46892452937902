import React, { useEffect, useState, useRef } from 'react'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import { useLocation } from 'react-router-dom'
import { Skeleton } from '@mui/material'
import { map_darkTheme } from '../../../constant/map/styles/dark'
import { map_darkBlueTheme } from '../../../constant/map/styles/darkBlue'
import { map_lightTheme } from '../../../constant/map/styles/light'
import Map from '../../../components/map/Map'

function Mapcomp(props) {
  const location = useLocation()
  const [locationData, setLocationData] = useState({})
  const [currentLocation, setCurrentLocation] = useState({
    latitude: null,
    longitude: null
  })
  const [loading, setLoading] = useState(false)
  const [isMarkerBlinking, setIsMarkerBlinking] = useState(true)
  const [curvy, setCurvy] = useState(false)
  const [currentMapTheme, setCurrentMapTheme] = useState(getDefaultTheme())
  const [themes, setThemes] = useState([
    { id: 0, label: 'Dark Blue', theme: map_darkBlueTheme },
    { id: 1, label: 'Dark', theme: map_darkTheme },
    { id: 2, label: 'Light', theme: map_lightTheme }
  ])
  const [mapOptions, setMapOptions] = useState({
    center: { lat: 41.0082, lng: 29.9784 },
    zoom: 1.6,
    disableDefaultUI: true,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: window?.google?.maps?.ControlPosition?.TOP_RIGHT
    },
    styles: map_darkBlueTheme
  })
  function getDefaultTheme() {
    return map_darkBlueTheme
  }
  const mapRef = useRef(null) // Ref to store map instance
  const currentLocationMarkerRef = useRef(null) // Ref to store current location marker

  const handleMapThemeChange = id => {
    setCurrentMapTheme(themes[id].theme)
    setMapOptions(prevOptions => ({
      ...prevOptions,
      styles: themes[id].theme
    }))
  }

  const handleMarkerClick = () => {
    setIsMarkerBlinking(!isMarkerBlinking)
  }

  const controller = new AbortController()
  const signal = controller.signal

  useEffect(() => {
    // // if (props?.shipmentDetails?.TransportMode === 'AIR') setCurvy(true);
    // if (location?.state?.ShipmentId) {
    //   console.log("Fetching location data...");
    //   getLocation();
    // }
    // return () => {
    //   controller.abort();
    // };
    // console.log("Current ShipmentId:", location?.state?.ShipmentId);
    if (location?.state?.ShipmentId) {
      console.log('Fetching location data...')
      getLocation()
    } else {
      console.error('No ShipmentId found!')
    }
    // return () => {
    //      controller.abort();
    //   };
  }, [location?.state?.ShipmentId])

  const getLocation = async () => {
    setLoading(true)
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? '',
      TransportMode: props?.shipmentDetails?.TransportMode ?? '',
      SCAC: props?.shipmentDetails?.SACCode ?? '',
      type: 'BL',
      OriginUNLOCO: props?.shipmentDetails?.OriginPortCode,
      OriginPortName: props?.shipmentDetails?.OriginPortName ?? '',
      BlNo: props?.shipmentDetails?.MasterBillNumber?.length
        ? props?.shipmentDetails?.MasterBillNumber[0]
        : '',
      DestinationUNLOCO: props?.shipmentDetails?.DestinationPortCode ?? '',
      DestinationPortName: props?.shipmentDetails?.DestinationPortName ?? '',
      ShipmentStatus: props?.shipmentDetails?.ShipmentStatus ?? '',
      ETD: props?.shipmentDetails?.ETDDate ?? '',
      ETA: props?.shipmentDetails?.ETADate ?? ''
    }

    try {
      const res = await instance.post(API_PATH.GET_ROUTE_LOCATION, body)
      setLocationData(res.data[0] ?? {})
      const originMarker = res.data[0]?.Marker?.find(
        marker => marker.Locationtype === 'ORIGIN'
      )
      const destinationMarker = res.data[0]?.Marker?.find(
        marker => marker.Locationtype === 'DESTINATION'
      )

      if (originMarker && destinationMarker) {
        const originLocation = { lat: originMarker.Lat, lng: originMarker.Lng }
        const destinationLocation = {
          lat: destinationMarker.Lat,
          lng: destinationMarker.Lng
        }

        // Calculate the midpoint
        const midLat = (originLocation.lat + destinationLocation.lat) / 2
        const midLng = (originLocation.lng + destinationLocation.lng) / 2

        // Update the map center to the midpoint
        setMapOptions(prevOptions => ({
          ...prevOptions,
          center: { lat: midLat, lng: midLng }
        }))

        const pin = res.data[0]?.Pin
        if (pin) setCurrentLocation({ latitude: pin.lat, longitude: pin.lng })
      }
    } catch (err) {
      console.error('Error fetching location data:', err)
    } finally {
      setLoading(false)
    }
  }
  const areCoordinatesEqual = (coord1, coord2, tolerance = 0.0001) => {
    return (
      Math.abs(coord1.lat - coord2.lat) < tolerance &&
      Math.abs(coord1.lng - coord2.lng) < tolerance
    )
  }

  // const getStraightAndDottedPath = (origin, current, destination) => {
  //   const isCurrentAtOrigin = areCoordinatesEqual(current, origin)
  //   const isCurrentAtDestination = areCoordinatesEqual(current, destination)

  //   if (isCurrentAtOrigin) {
  //     return { straightLinePath: null, dottedCurvePath: [origin, destination] }
  //   } else if (isCurrentAtDestination) {
  //     return { straightLinePath: [origin, destination], dottedCurvePath: null }
  //   } else {
  //     return {
  //       straightLinePath: [origin, current],
  //       dottedCurvePath: [current, destination]
  //     }
  //   }
  // }
  const getStraightAndDottedPath = (routes, current) => {
    if (routes.length === 0)
      return { straightLinePath: null, dottedCurvePath: null }

    const origin = { lat: routes[0].lat, lng: routes[0].lng } // First point in the routes
    const destination = {
      lat: routes[routes.length - 1].lat,
      lng: routes[routes.length - 1].lng
    } // Last point in the routes

    const isCurrentAtOrigin = areCoordinatesEqual(current, origin)
    const isCurrentAtDestination = areCoordinatesEqual(current, destination)

    if (isCurrentAtOrigin) {
      return { straightLinePath: null, dottedCurvePath: [origin, destination] }
    } else if (isCurrentAtDestination) {
      return { straightLinePath: [origin, destination], dottedCurvePath: null }
    } else {
      return {
        straightLinePath: [origin, current],
        dottedCurvePath: [current, destination]
      }
    }
  }

  // useEffect(() => {
  //   if (
  //     isMarkerBlinking &&
  //     mapRef.current &&
  //     currentLocationMarkerRef.current
  //   ) {
  //     const interval = setInterval(() => {
  //       // Toggle visibility or change color
  //       const markerIcon = currentLocationMarkerRef.current.getIcon()
  //       currentLocationMarkerRef.current.setOptions({
  //         icon: {
  //           url: process.env.PUBLIC_URL + '/assets/flight11.png',
  //           scaledSize: new window.google.maps.Size(30, 30), // Adjust the size if needed
  //           anchor: new window.google.maps.Point(18, 15)
  //         }
  //       })
  //     }, 500) // Adjust the interval time for blinking effect

  //     return () => clearInterval(interval)
  //   }
  // }, [isMarkerBlinking])

  return (
    <>
      {loading ? (
        <div className='skeleton-div'>
          <Skeleton style={{ height: '29rem', marginTop: '1-rem' }} />
        </div>
      ) : (
        <>
          <div
            style={{ display: 'grid', marginTop: '-3rem', gridTemplateColumns: '1fr 1fr' }}
            onClick={e => handleMapThemeChange(e.target.id)}
          >
            {themes.map((ntheme, index) => (
              <div key={ntheme?.name}>
                {/* <input
                  type='radio'
                  id={ntheme?.id}
                  name='theme'
                  value={currentMapTheme}
                  style={{ marginRight: '1rem' }}
                /> */}
                <label htmlFor={ntheme?.id}></label>
              </div>
            ))}
          </div>
          <Map
            id='myMap'
            options={mapOptions}
            onMapLoad={map => {
              mapRef.current = map // Store the map instance in the ref
              const originIcon = {
                // path: window.google.maps.SymbolPath.CIRCLE,
                path: 'M 0,0 m -8, 0 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0',
                fillColor: '#fff', // Outer circle color
                fillOpacity: 1,
                strokeColor: '#ff9900', // Outer circle stroke color
                strokeWeight: 10,
                scale: 1, // Adjust this scale to fit your needs
                anchor: new window.google.maps.Point(0, 0) // Center the icon
              }

              const destinationIcon = {
                // path: "M 0,0 m -8, 0 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0 M 0,0 m -4, 0 a 4,4 0 1,0 8,0 a 4,4 0 1,0 -8,0",
                path: 'M 0,0 m -8, 0 a 8,8 0 1,0 16,0 a 8,8 0 1,0 -16,0',
                fillColor: '#fff', // Outer circle color
                fillOpacity: 1,
                strokeColor: '#ff9900',
                strokeWeight: 10,
                scale: 1, // Adjust this scale to fit your needs
                anchor: new window.google.maps.Point(0, 0) // Center the icon
              }

              const greenFlagIcon = {
                url: process.env.PUBLIC_URL + '/assets/flag.png', // Path to your green flag image
                scaledSize: new window.google.maps.Size(44, 45) // Adjust the size if needed
              }
              const transportModeIcons = {
                AIR: process.env.PUBLIC_URL + '/assets/flight.png',
                RAI: process.env.PUBLIC_URL + '/assets/rail.png',
                ROA: process.env.PUBLIC_URL + '/assets/road.png',
                COU: process.env.PUBLIC_URL + '/assets/courier.png',
                SEA: process.env.PUBLIC_URL + '/assets/boat.png'
              }

              // Select the appropriate icon based on transport mode
              // const currentLocationIcon = {
              //   url: transportModeIcons[props?.shipmentDetails?.TransportMode] || process.env.PUBLIC_URL + '/assets/default.svg',
              //   scaledSize: new window.google.maps.Size(60, 60), // Adjust the size if needed
              // };
              // Assuming you have the origin location stored in a variable

              const originMarker = locationData.Marker?.find(
                marker => marker.Locationtype === 'ORIGIN'
              )
              const originLocation = {
                lat: originMarker?.Lat,
                lng: originMarker?.Lng
              }
              const destinationMarker = locationData.Marker?.find(
                marker => marker.Locationtype === 'DESTINATION'
              )
              const destinationLocation = {
                lat: destinationMarker?.Lat,
                lng: destinationMarker?.Lng
              }
              // Inside your onMapLoad function
              const currentLocationIcon =
                (currentLocation.latitude === originLocation.lat &&
                  currentLocation.longitude === originLocation.lng) ||
                (currentLocation.latitude === destinationLocation.lat &&
                  currentLocation.longitude === destinationLocation.lng)
                  ? null // Do not show icon if currentLocation is at origin or destination
                  : {
                      url:
                        transportModeIcons[
                          props?.shipmentDetails?.TransportMode
                        ] || process.env.PUBLIC_URL + '/assets/default.svg',
                      scaledSize: new window.google.maps.Size(40, 40) // Adjust the size if needed
                    }

              locationData.Marker &&
                locationData.Marker.map((item, index) => {
                  const isDestination = index === locationData.Marker.length - 1
                  const marker = new window.google.maps.Marker({
                    position: { lat: item.Lat, lng: item.Lng },
                    map: map,
                    // title: `${item?.Name}`,
                    icon: isDestination ? destinationIcon : originIcon
                  })
                  // Create a custom OverlayView for the rectangular label
                  const labelOverlay = new window.google.maps.OverlayView()
                  labelOverlay.onAdd = function () {
                    const div = document.createElement('div')
                    div.style.position = 'absolute'
                    div.style.backgroundColor = '#000' // Black background
                    div.style.color = '#fff' // White text
                    div.style.border = '1px solid #ccc'
                    div.style.padding = '4px 8px'
                    div.style.borderRadius = '4px'
                    div.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.3)'
                    div.innerText = item?.Name

                    this.div = div

                    const panes = this.getPanes()
                    panes.overlayImage.appendChild(div)
                  }

                  labelOverlay.draw = function () {
                    const projection = this.getProjection()
                    const position = projection.fromLatLngToDivPixel(
                      marker.getPosition()
                    )

                    if (position) {
                      this.div.style.left = position.x + 'px'
                      this.div.style.top = position.y + 20 + 'px' // Position it below the marker (adjust as needed)
                    }
                  }

                  labelOverlay.onRemove = function () {
                    if (this.div) {
                      this.div.parentNode.removeChild(this.div)
                      this.div = null
                    }
                  }

                  labelOverlay.setMap(map)

                  // Open info window on mouseover
                  // marker.addListener('mouseover', () => {
                  //   infoWindow.setContent(`
                  //     <div class="info-window-content">
                  //       <h5>${item?.Name}</h5>
                  //     </div>
                  //   `);
                  //   infoWindow.open(map, marker);
                  // });

                  // Close info window on mouseout
                  // marker.addListener('mouseout', () => {
                  //   infoWindow.close();
                  // });
                })

              // Add marker for the current location
              if (
                currentLocation.latitude !== null &&
                currentLocation.longitude !== null &&
                !(
                  (currentLocation.latitude === originLocation.lat &&
                    currentLocation.longitude === originLocation.lng) ||
                  (currentLocation.latitude === destinationLocation.lat &&
                    currentLocation.longitude === destinationLocation.lng)
                )
              ) {
                const currentLocationMarker = new window.google.maps.Marker({
                  position: {
                    lat: currentLocation.latitude,
                    lng: currentLocation.longitude
                  },
                  map: map,
                  title: 'Current Location',
                  icon: currentLocationIcon, // This will be null if currentLocation is at origin or destination
               
                })

                currentLocationMarkerRef.current = currentLocationMarker // Store the marker in the ref
              }

              // Do not create a marker for the destination if it matches the current location
              if (
                currentLocation.latitude !== destinationLocation.lat ||
                currentLocation.longitude !== destinationLocation.lng
              ) {
                const destinationMarkerData = locationData.Marker?.find(
                  marker => marker.Locationtype === 'DESTINATION'
                )
                const destinationMarker = new window.google.maps.Marker({
                  position: destinationLocation,
                  map: map,
                  title: destinationMarkerData?.Name || 'Destination',
                  icon: destinationIcon // Use your destination icon here
                })
              }

              // Create the green flag marker at the same destination location
              if (
                currentLocation.latitude === destinationLocation.lat ||
                currentLocation.longitude === destinationLocation.lng
              ) {
                const greenFlagMarker = new window.google.maps.Marker({
                  position: destinationLocation,
                  map: map,
                  title: 'Arrived at Destination',
                  icon: greenFlagIcon, // Use your green flag icon here
                  zIndex: 1
                })
              }

              //  Draw the Paths
              const routes = locationData.routes || [] // Get the routes from the location data
              // Use the current location to determine the paths
              const current = {
                lat: currentLocation.latitude,
                lng: currentLocation.longitude
              }
              // Get paths based on current location
              const { straightLinePath, dottedCurvePath } =
                getStraightAndDottedPath(routes, current)

              // Draw the straight line if it exists
              if (straightLinePath) {
                const line = new window.google.maps.Polyline({
                  path: straightLinePath,
                  geodesic: true,
                  strokeColor: 'white',
                  strokeOpacity: 1.0,
                  strokeWeight: 2
                })
                line.setMap(map)
              }

              // Draw the dotted curve if it exists
              if (dottedCurvePath) {
                const dottedLine = new window.google.maps.Polyline({
                  path: dottedCurvePath,
                  geodesic: true,
                  strokeColor: 'blue',
                  strokeOpacity: 0,
                  strokeWeight: 0,
                  icons: [
                    {
                      icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        scale: 2.2, // Adjust the size of the dots
                        strokeColor: 'gray',
                        fillColor: ' gray',
                        fillOpacity: 2
                      },
                      offset: '0%',
                      repeat: '10px' // Adjust the repeat value to set the spacing of the dots
                    }
                  ]
                })
                dottedLine.setMap(map)
              }
            }}
          />
        </>
      )}
    </>
  )
}

export default Mapcomp
