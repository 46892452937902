import {
  Alert,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDoclist,
  fetchRemarks,
  postRemarks,
  updateDocListBody,
  updateStatus
} from '../../../../redux/documentRedux/documentListSlice'
import MarkdownRenderer from '../../../../common/MarkdownRenderer '
import { PrimeReactProvider } from 'primereact/api'
import { Editor } from 'primereact/editor'
import TurndownService from 'turndown'
import { DateFormatWithtime } from '../../../../utils/moment'
export default function ReviewDialogue() {
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [text, setText] = useState('')
  const {
    remarks: {
      isRemarksOpen,
      currentParentId,
      currentRemarks,
      isReject,
      currentPk,
      updateRemarks: { isLoading }
    }
  } = useSelector(state => state.documentApproval)
  const renderHeader = () => {
    // to add more options https://quilljs.com/docs/modules/toolbar/ 👍
    return (
      <span className='ql-formats'>
        <button className='ql-bold' aria-label='Bold'></button>
        <button className='ql-italic' aria-label='Italic'></button>
        <button className='ql-underline' aria-label='Underline'></button>
        <button className='ql-strike' aria-label='Strike'></button>
      </span>
    )
  }
  const header = renderHeader()
  const onTextChange = e => {
    const htmlValue = e.htmlValue
    // Check if htmlValue is empty
    if (htmlValue === null) {
      setText('') // Clear the text state
    } else {
      // Convert HTML to Markdown
      const turndownService = new TurndownService()
      const markdownValue = turndownService.turndown(htmlValue)
      setText(markdownValue)
    }
  }
  const handleClose = () => {
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'isRemarksOpen',
        value: false
      })
    )
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'isReject',
        value: false
      })
    )
  }
  const handlePostRemarks = () => {
    if (isReject) {
      let promiseReject = dispatch(
        updateStatus({
          pk: currentPk,
          status: 2,
          shipmentid: currentParentId
        })
      )
      let promIsRejectRemarks = promiseReject
        .then(res => {
          dispatch(
            postRemarks({
              HouseBillNumber: '',
              PK: currentPk,
              ParentId: currentParentId,
              DocType: '',
              Remarks: text
            })
          )
        })
        .then(() => {
          dispatch(
            fetchDoclist({
              HouseBillNumber: '',
              ParentId: '',
              Search: '',
              Status: '',
              StartDate: '',
              EndDate: '',
              CurrentPage: 0,
              PageSize: 10,
              SortColumnName: '',
              SortDirection: '',
              ConsigneeCode: '',
              ConsignorCode: '',
              Approver: ''
            })
          )
        })
        .then(() => {
          dispatch(
            updateDocListBody({
              obj: 'remarks',
              objName: null,
              name: 'isRemarksOpen',
              value: false
            })
          )
        })
    } else if (!isReject) {
      handleFetchReviews()
    }
  }
  const handleReject = () => {
    if (text?.length <= 0) {
      setError(true)
    } else {
      handlePostRemarks()
    }
  }
  const handleFetchReviews = () => {
    dispatch(fetchRemarks(currentParentId))
  }
  useEffect(() => {
    if (currentParentId) handleFetchReviews()
  }, [currentParentId])
  return (
    <Dialog
      open={isRemarksOpen}
      fullWidth
      onClose={() => {
        handleClose()
      }}
    >
      <DialogTitle>
        {currentParentId} <Divider />
      </DialogTitle>
      <DialogContent
        style={{
          height: '90vh'
        }}
      >
        {error && <Alert severity='error'>Please add remarks</Alert>}
        {currentRemarks?.RemarksCollection &&
          currentRemarks?.RemarksCollection.map((remark, index) => {
            return (
              <Card
                key={index}
                sx={{
                  marginTop: '1rem'
                }}
              >
                <CardContent>
                  <div>
                    <p>
                      Updated on :{' '}
                      {DateFormatWithtime(remark?.RemarksUpdatedDate)}
                    </p>
                  </div>
                  <MarkdownRenderer data={{ MarkdownText: remark?.Remarks }} />
                </CardContent>
              </Card>
            )
          })}
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: 'var(--color-primary-light)'
        }}
      >
        <div
          style={{
            width: '100%'
          }}
        >
          {' '}
          <PrimeReactProvider>
            <Editor
              headerTemplate={header}
              value={text}
              onTextChange={onTextChange}
              style={{ height: '10rem' }}
            />
          </PrimeReactProvider>
          {isReject ? (
            <Button
              fullWidth
              variant='contained'
              style={{
                marginTop: '1rem'
              }}
              onClick={() => {
                handleReject()
              }}
            >
              Done
            </Button>
          ) : (
            <Button
              fullWidth
              variant='contained'
              style={{
                marginTop: '1rem'
              }}
              onClick={handleReject}
            >
              Add Remarks
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}
