import { Description } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";

const createBookingSlice = createSlice({
  name: "createBooking",
  initialState: {
    sidebar: {
      selected: 0,
    },
    userType: "",
    otherStates: {
      CS_Description: [
        {
          CS_Code: null,
          CS_Description: null,
        },
      ],
    },
    userData: {
      initialBuyerOrSupplier: [],
      viewBuyers: [],
      viewSupplier: [],
      selectedBuyer: null,
      selectedSupplier: null,
      address: {
        buyerAddress: [],
        supplierAddress: [],
      },
      selectedAddress: {
        buyerAddress: null,
        supplierAddress: null,
      },
    },
  },
  reducers: {
    updateConsignorName: (state, action) => {
      state.userData = {...state.userData, selectedSupplier: action.payload};
    },
    updateConsignorAddress: (state, action) => {
      state.userData.selectedAddress.supplierAddress = action.payload;
    },
    updateConsigneeName: (state, action) => {
      state.userData = {...state.userData, selectedBuyer: action.payload};
    },
    updateConsigneeAddress: (state, action) => {
      state.userData.selectedAddress.buyerAddress = action.payload;
    },
    editFill: (state, action) => {
      state.userData = action.payload;
    },
    clearState: (state, action) => {
      state.userData = {
        initialBuyerOrSupplier: [],
        viewBuyers: [],
        viewSupplier: [],
        selectedBuyer: null,
        selectedSupplier: null,
        address: {
          buyerAddress: [],
          supplierAddress: [],
        },
        selectedAddress: {
          buyerAddress: null,
          supplierAddress: null,
        },
      };
    },
    updateUserData: (state, action) => {
      state.userData[action.payload.keyName] = action.payload.value;
    },
    switchPage: (state, action) => {
      state.sidebar.selected = action.payload;
    },
    userTypeSelect: (state, action) => {
      state.userType = action.payload;
    },
    clearCreateBooking: (state, action) => {
      state.sidebar = { selected: 0 };
      state.userType = "";
    },
    addCSDetails: (state, action) => {
      state.otherStates.CS_Description.push({
        CS_Code: null,
        CS_Description: null,
      });
    },
    updateCSDetails: (state, action) => {
      state.otherStates.CS_Description[action.payload.index].CS_Description =
        action.payload.value;
    },
  },
});
export const {
  updateConsignorName,
  updateConsignorAddress,
  updateConsigneeName,
  updateConsigneeAddress,
  editFill,
  clearState,
  updateUserData,
  switchPage,
  userTypeSelect,
  clearCreateBooking,
  addCSDetails,
  updateCSDetails,
} = createBookingSlice.actions;
export default createBookingSlice.reducer;
