import {
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  Skeleton,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import { useNavigate } from 'react-router-dom'
import DoneIcon from '@mui/icons-material/Done'
import CloseIcon from '@mui/icons-material/Close'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PostAddIcon from '@mui/icons-material/PostAdd'
import NodatafoundComp from '../../../charts/NodatafoundComp'
const ListTable = ({
  getCollection,
  pagination,
  setPagination,
  loading,
  setLoading,
  data,
  setData,
  setSorting,
  setSortingVal,
  sorting,
  sortingVal,
  reason,
  setReason,
  count,
  filterOptions,
  tableCopy,
  isDataAvailable
}) => {
  const navigate = useNavigate()
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('sm')
  const [open, setOpen] = useState(false)
  const [bookingNo, setBookingNo] = useState('')
  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    getCollection(pagination, '', filterOptions, false)
  }, [sorting, sortingVal])

  const convertBooking = (status, id, reason) => {
    setLoading(true)
    const body = {
      Status: status,
      RejectedReason: reason,
      ShowDeveloperResponse: '',
      BookingNumber: id
    }
    instance
      .post(`${API_PATH.CONVERT_BOOKING}`, body)
      .then(res => {
        setData([])
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setData([])
        getCollection({}, '', filterOptions, true)
        // window.location.reload();
      })
  }
  const userData = JSON.parse(localStorage.getItem('userData')).Role.split(',')
  const ActionButtons = props => {
    const { item, index } = props
    const isAdmin = userData.includes('Admin')

    if (item?.StatusCode === '0') {
      return (
        <>
          {isAdmin && (
            <>
              <Fab
                title='Accept Booking'
                size='small'
                onClick={() => {
                  setReason('hshhjh')
                  convertBooking(1, item?.BookingNo, '')
                }}
                aria-label='add'
                style={{ backgroundColor: 'white', marginRight: '5px' }}
              >
                <DoneIcon color='primary' />
              </Fab>

              <Fab
                title='Reject Booking'
                onClick={() => {
                  setBookingNo(item?.BookingNo)
                  setOpen(true)
                }}
                size='small'
                aria-label='add'
                style={{
                  backgroundColor: 'white',
                  color: 'var(--color-secondary-font)',
                  marginRight: '5px'
                }}
              >
                <CloseIcon />
              </Fab>
            </>
          )}

          <Fab
            title='Duplicate Booking'
            size='small'
            aria-label='add'
            style={{
              backgroundColor: 'white',
              color: 'var(--color-secondary-font)'
            }}
            onClick={() => {
              navigate(`/booking/create/${item?.BookingNo}`)
            }}
          >
            <PostAddIcon />
          </Fab>
        </>
      )
    } else if (item?.StatusCode === '1' || item?.StatusCode === '2') {
      return (
        <Fab
          title='Duplicate Booking'
          size='small'
          aria-label='add'
          onClick={() => {
            navigate(`/booking/create/${item?.BookingNo}`)
          }}
          style={{
            backgroundColor: 'white',
            color: 'var(--color-secondary-font)'
          }}
        >
          <PostAddIcon />
        </Fab>
      )
    } else {
      return <p>N/A</p>
    }
  }
  function getkey(key) {
    const SortNameObj = {
      CarrierName: 'BH_CarrierName',
      ConsigneeName: 'JS_ConsigneeName',
      ConsignorName: 'BH_ConsigneeCode',
      OriginPortName: 'BH_OriginPortName',
      DestinationPortName: 'BH_destinationportName',
      ETA: 'BH_ETA',
      ETD: 'BH_ETD',
      CreatedUserName: 'BH_CreatedUserName',
      Createdtime: 'BH_Createdtime'
    }
    return SortNameObj[key]
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <DialogContent>
          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h2'
            style={{ paddingBottom: '1rem' }}
          >
            Provide the reason of rejection of Quotation Number({bookingNo})
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around'
            }}
          >
            <TextField
              size='small'
              label='Reason'
              variant='outlined'
              sx={{ width: '100%', marginBottom: '15px' }}
              onChange={e => {
                setReason(e.target.value)
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='buttons-container'>
            <button
              style={{ border: 'none', padding: '0 0.8rem 0 ' }}
              onClick={() => {
                setOpen(false)
              }}
            >
              Cancel
            </button>
            <button
              style={{ border: 'none', padding: '0 0.8rem 0 ' }}
              onClick={() => {
                if (reason !== '') {
                  convertBooking(2, bookingNo, reason)
                  setOpen(false)
                } else {
                  alert('Please give a valid reason')
                }
              }}
            >
              Proceed
            </button>
          </div>
        </DialogActions>
      </Dialog>
      {isDataAvailable ? (
        <div className='tableWrap'>
          <table className='table'>
            <thead>
              <tr>
                {tableCopy.map(item => {
                  if (item?.IsVisible) {
                    if (
                      item?.DataKey === 'CarrierName' ||
                      item?.DataKey === 'ConsigneeName' ||
                      item?.DataKey === 'ConsignorName' ||
                      item?.DataKey === 'OriginPortName' ||
                      item?.DataKey === 'DestinationPortName' ||
                      item?.DataKey === 'ETA' ||
                      item?.DataKey === 'ETD' ||
                      item?.DataKey === 'CreatedUserName' ||
                      item?.DataKey === 'Createdtime'
                    ) {
                      const key = getkey(item?.DataKey)
                      return (
                        <th scope='col' className='font-table font-shipment'>
                          {item?.ColumnName}
                          {sortingVal !== key ? (
                            <SwapVertIcon
                              onClick={() => {
                                setPagination({
                                  CurrentPage: 0,
                                  PageSize: data?.length,
                                  intialLoad: true
                                })
                                setData([])
                                setSorting('Asc')
                                setSortingVal(key)
                              }}
                            />
                          ) : (
                            <>
                              {sorting === 'Asc' ? (
                                <ArrowDownwardIcon
                                  onClick={() => {
                                    setData([])
                                    setSorting('Desc')
                                  }}
                                />
                              ) : (
                                <ArrowUpwardIcon
                                  onClick={() => {
                                    setData([])
                                    setSorting('Asc')
                                  }}
                                />
                              )}
                            </>
                          )}
                        </th>
                      )
                    }

                    return (
                      <th scope='col' className='font-table font-shipment'>
                        {item.ColumnName}
                      </th>
                    )
                  }
                })}
                <th
                  scope='col'
                  className='font-table font-shipment sticky-col-header'
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <>
                  {new Array(12).fill(1).map(() => {
                    return (
                      <tr>
                        {new Array(17).fill(1).map(() => {
                          return (
                            <td>
                              <Skeleton
                                animation='wave'
                                width={146}
                                height={25}
                              />
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </>
              ) : (
                <>
                  {data.map((item, index) => {
                    return (
                      <tr key={index}>
                        {tableCopy.map(row => {
                          if (row?.IsVisible) {
                            if (row?.DataKey === 'BookingNo') {
                              return (
                                <td
                                  className='font-change-list ellipsis-text '
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    navigate(`/booking/${item?.BookingNo}`)
                                  }}
                                >
                                  <span style={{ left: '-1px ', top: '15px' }}>
                                    {item?.StatusCode === '0' ? (
                                      <FiberManualRecordIcon
                                        color='primary'
                                        style={{ fontSize: '0.8rem' }}
                                      />
                                    ) : item?.StatusCode === '1' ? (
                                      <FiberManualRecordIcon
                                        color='success'
                                        style={{ fontSize: '0.8rem' }}
                                      />
                                    ) : item?.StatusCode === '2' ? (
                                      <FiberManualRecordIcon
                                        color='error'
                                        style={{ fontSize: '0.8rem' }}
                                      />
                                    ) : (
                                      'N/A'
                                    )}
                                  </span>
                                  <span className='booking_para'>
                                    {item[row.DataKey] ?? null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'CWBookingNumber') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span className='booking_para-size'>
                                    {item[row.DataKey] ?? null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'Transportmode') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item['TransportMode'] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'CarrierName') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'ConsigneeName') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'ConsignorName') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'Incoterm') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'OriginPortName') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'DestinationportName') {
                              return (
                                <td className='font-change-list ellipsis-text '>
                                  <span>
                                    {item['DestinationPortName'] ?? null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'ETD') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>
                                    {item[row.DataKey]
                                      ? new Date(item[row.DataKey])
                                          .toISOString()
                                          .split('T')[0]
                                      : null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'ETA') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>
                                    {item[row.DataKey]
                                      ? new Date(item[row.DataKey])
                                          .toISOString()
                                          .split('T')[0]
                                      : null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'Containertype') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>{item['ContainerType'] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'CreatedUserName') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            } else if (row?.DataKey === 'CreateDateTimeUTC') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>
                                    {item[row.DataKey]
                                      ? new Date(item[row.DataKey])
                                          .toISOString()
                                          .split('T')[0]
                                      : null}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'StatusCode') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>
                                    {item[row.DataKey] === '0' ? (
                                      <p>Pending</p>
                                    ) : item[row.DataKey] === '1' ? (
                                      <p>Accepted</p>
                                    ) : item[row.DataKey] === '2' ? (
                                      <p>Rejected</p>
                                    ) : (
                                      'N/A'
                                    )}
                                  </span>
                                </td>
                              )
                            } else if (row?.DataKey === 'ApproveRemark') {
                              return (
                                <td className='ellipsis font-shipment ellipsis-width'>
                                  <span>{item[row.DataKey] ?? null}</span>
                                </td>
                              )
                            }
                          }
                        })}

                        <td className='font-change-list sticky-col'>
                          <ActionButtons item={item} index={index} />
                        </td>
                      </tr>
                    )
                  })}
                </>
              )}
            </tbody>
          </table>
          {!loading && data.length <= 0 && (
            <div className='d-flex justify-content-center align-items-center font-change-list'>
              No data found!
            </div>
          )}
        </div>
      ) : (
        <NodatafoundComp />
      )}
      {data.length < count ? (
        <div
          className='buttons-container'
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <button
            disabled={loading}
            style={{
              border: 'none'
            }}
            onClick={() => {
              const temp = {
                ...pagination
              }
              temp.CurrentPage = pagination.CurrentPage + 1
              temp.PageSize = pagination.PageSize
              temp.intialLoad = false
              setPagination(temp)
              getCollection(temp, '', filterOptions, false)
            }}
          >
            Show more
          </button>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
export default ListTable
