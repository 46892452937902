export const TableBookColumns = [
  {
    ColumnId: 1,
    ColumnName: 'Booking Number',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'BookingNo'
  },
  {
    ColumnId: 2,
    ColumnName: 'CW Booking No',
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'CWBookingNumber'
  },
  {
    ColumnId: 3,
    ColumnName: 'Transport Mode',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'TransportMode'
  },
  {
    ColumnId: 4,
    ColumnName: 'Container Type',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ContainerType'
  },
  {
    ColumnId: 5,
    ColumnName: 'Inco Term',
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'Incoterm'
  },
  {
    ColumnId: 6,
    ColumnName: 'Consignor Name',
    IsVisible: false,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ConsignorName'
  },
  {
    ColumnId: 7,
    ColumnName: 'Consignee Name',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ConsigneeName'
  },
  {
    ColumnId: 8,
    ColumnName: 'Carrier Name',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'CarrierName'
  },
  {
    ColumnId: 9,
    ColumnName: 'ETA',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ETD'
  },
  {
    ColumnId: 10,
    ColumnName: 'ETD',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ETA'
  },
  {
    ColumnId: 11,
    ColumnName: 'Origin Port',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'OriginPort'
  },
  {
    ColumnId: 12,
    ColumnName: 'Destination Name',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'DestinationPort',
    AltKey: 'DestinationPort'
  },
  {
    ColumnId: 13,
    ColumnName: 'Created User',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'CreatedUserName'
  },
  {
    ColumnId: 14,
    ColumnName: 'Create Date',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'CreateDateTimeUTC'
  },
  {
    ColumnId: 15,
    ColumnName: 'Status',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'StatusCode'
  },
  {
    ColumnId: 16,
    ColumnName: 'Approve Remark',
    IsVisible: true,
    UserRank: 1,
    DefaultRank: 2,
    IsSelected: false,
    DataKey: 'ApproveRemark'
  }
]
