import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { Button, IconButton, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDoclist,
  updateDocListBody
} from '../../../../redux/documentRedux/documentListSlice'
import { SearchOutlined } from '@mui/icons-material'
import { useEffect, useState } from 'react'
import useDebounce from '../../../../customHook/useDebounce'
import instance from '../../../../api/Interceptor'
export default function Header() {
  const dispatch = useDispatch()
  const {
    docListState: { isFilterOn }
  } = useSelector(state => state.documentApproval)
  const [search, setSearch] = useState(null)
  console.log(isFilterOn, 'isFilterOn')
  const debouncedTitle = useDebounce(search, 1000)
  const setCook = () => {
    instance
      .get('UserAPI/api/login/set-cookie')
      .then(res => {
        console.log(res)
      })
      .catch(err => {
        console.log(err)
      })
  }
  useEffect(() => {
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        objName: 'body',
        name: 'Search',
        value: search
      })
    )
    // prevents api call on initial load
    if (search !== null)
      dispatch(
        fetchDoclist({
          HouseBillNumber: '',
          ParentId: '',
          Search: search,
          Status: '',
          StartDate: '',
          EndDate: '',
          CurrentPage: 0,
          PageSize: 10,
          SortColumnName: '',
          SortDirection: '',
          ConsigneeCode: '',
          ConsignorCode: '',
          Approver: ''
        })
      )
  }, [debouncedTitle])
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '0.5rem',
        flexWrap: 'wrap'
      }}
    >
      <p className='layout-head'>Documents</p>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem'
        }}
      >
        <div
          style={{
            position: 'relative',
            width: '15rem'
          }}
        >
          <input
            type='text'
            onChange={e => {
              setSearch(e.target.value)
            }}
            placeholder='Type to search'
            style={{
              paddingLeft: '0.5rem',
              paddingBottom: '0.2rem',
              paddingTop: '0.2rem',
              border: 'none',
              minWidth: '100%',
              borderRadius: '0.5rem',
              backgroundColor: 'var(--color-miscellaneous-1)'
            }}
          />
          <SearchOutlined
            onClick={() => {
              // setCook()
              dispatch(
                updateDocListBody({
                  obj: 'docListState',
                  objName: 'body',
                  name: 'Search',

                  value: search
                })
              )
            }}
            style={{
              paddingTop: '0.3rem',
              position: 'absolute',
              right: '5%'
            }}
          />
        </div>
        <div>
          <IconButton
            size='small'
            style={
              isFilterOn
                ? {
                    backgroundColor: 'var(--color-primary)'
                  }
                : {
                    backgroundColor: 'var(--color-white)'
                  }
            }
            onClick={() => {
              dispatch(
                updateDocListBody({
                  obj: 'docListState',
                  objeName: null,
                  name: 'isFilterOpen',
                  value: true
                })
              )
            }}
          >
            {' '}
            <FilterAltIcon
              style={
                isFilterOn
                  ? { color: 'var(--color-white)' }
                  : { color: 'var(--color-primary)' }
              }
            />{' '}
          </IconButton>
          {isFilterOn && (
            <Button
              onClick={() => {
                console.log('shiveta')
                dispatch(
                  fetchDoclist({
                    HouseBillNumber: '',
                    ParentId: '',
                    Search: '',
                    Status: '',
                    StartDate: '',
                    EndDate: '',
                    CurrentPage: 0,
                    PageSize: 10,
                    SortColumnName: '',
                    SortDirection: '',
                    ConsigneeCode: '',
                    ConsignorCode: '',
                    DocType: '',
                    ClientName: '',
                    Approver: ''
                  })
                )

                dispatch(
                  updateDocListBody({
                    obj: 'docListState',
                    objName: null,
                    name: 'filters',
                    value: {}
                  })
                )
                dispatch(
                  updateDocListBody({
                    obj: 'docListState',
                    name: 'isFilterOn',
                    value: false
                  })
                )
              }}
            >
              Clear
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
