import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDoclist,
  resetFilters,
  updateDocListBody
} from '../../../../redux/documentRedux/documentListSlice'
import { useEffect, useState } from 'react'
import moment from 'moment'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
const options = [
  { label: 'Pending', value: 0 },
  { label: 'Rejected', value: 2 },
  { label: 'Accepted', value: 1 }
]
export default function ListFilters() {
  const {
    userInfo: { userRole },
    docListState: {
      data,
      isLoading,
      isError,
      body,
      isFilterOpen,
      filters,
      isFilterOn
    }
  } = useSelector(state => state.documentApproval)
  const dispatch = useDispatch()

  const handleFeildChange = e => {
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        name: 'filters',
        value: { ...filters, [e.target.name]: e.target.value }
      })
    )
  }

  const handleSave = () => {
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        objName: null,
        name: 'filters',
        value: filters
      })
    )
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        objName: null,
        name: 'isFilterOn',
        value: true
      })
    )
    dispatch(
      fetchDoclist({
        HouseBillNumber: '',
        ParentId: filters?.jobNo,
        Search: '',
        Status: filters?.status,
        StartDate: filters?.StartDate,
        EndDate: filters?.EndDate,
        CurrentPage: 0,
        PageSize: 10,
        SortColumnName: '',
        SortDirection: '',
        ConsigneeCode: '',
        ConsignorCode: '',
        DocType: filters?.docType,
        ClientName: filters?.clientName,
        Approver: filters?.Approver
      })
    )
    console.log(fetchDoclist, 'fetchDoclist')
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        name: 'isFilterOpen',
        value: false
      })
    )
  }
  // dispatch(
  //   updateDocListBody({
  //     obj: 'docListState',
  //     name: 'filters',
  //     value: false
  //   })
  const formatDate = date => {
    return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
  }
  console.log(filters, 'filtersgukhjklhuihnkljhkbjhb.jkmn')
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={isFilterOpen}
      onClose={() => {
        dispatch(
          updateDocListBody({
            obj: 'docListState',
            name: 'isFilterOpen',
            value: false
          })
        )
      }}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >
        <Button
          startIcon={<KeyboardBackspaceIcon />}
          onClick={() => {
            dispatch(
              updateDocListBody({
                obj: 'docListState',
                name: 'isFilterOpen',
                value: false
              })
            )
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => {
            dispatch(resetFilters())
          }}
        >
          Reset All
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          columnSpacing={2}
          sx={{
            alignItems: 'baseline',
            rowGap: '1rem'
          }}
        >
          {/* JOB NUMBER */}
          <Grid item xs={3.5}>
            <Typography>Job No</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextField
              name='jobNo'
              size='small'
              value={filters?.jobNo}
              onChange={handleFeildChange}
              fullWidth
            />{' '}
          </Grid>
          {/* Document Type */}
          <Grid item xs={3.5}>
            <Typography>Document Type</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <TextField
              name='docType'
              value={filters?.docType}
              onChange={handleFeildChange}
              size='small'
              fullWidth
            />
          </Grid>
          {/* Approver */}
          {userRole === 'FreightForwarder' && (
            <>
              <Grid item xs={3.5}>
                <Typography>Approver</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  value={filters?.Approver}
                  name='Approver'
                  onChange={handleFeildChange}
                  size='small'
                  fullWidth
                />{' '}
              </Grid>
            </>
          )}
          {/* Date Range */}
          <Grid item xs={3.5}>
            <Typography> Date uploaded</Typography>
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                name='StartDate'
                label='Start Date'
                maxDate={filters?.EndDate}
                valueDefault={null}
                value={filters?.StartDate ?? null}
                inputFormat='DD/MM/YYYY'
                onChange={e => {
                  dispatch(
                    updateDocListBody({
                      obj: 'docListState',
                      name: 'filters',
                      value: { ...filters, StartDate: formatDate(e) }
                    })
                  )
                  // setTempFilters({ ...filters, StartDate: formatDate(e) })
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    size='small'
                    {...params}
                    color='primary'
                    error={null}
                  />
                )}
              />
            </LocalizationProvider>{' '}
          </Grid>
          <Grid
            item
            xs={0.5}
            sx={{
              display: 'flex'
            }}
          >
            To
          </Grid>
          <Grid item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                fullWidth
                name='EndDate'
                label='End Date'
                minDate={filters?.StartDate}
                valueDefault={null}
                value={filters?.EndDate ?? null}
                inputFormat='DD/MM/YYYY'
                onChange={e => {
                  dispatch(
                    updateDocListBody({
                      obj: 'docListState',
                      name: 'filters',
                      value: { ...filters, EndDate: formatDate(e) }
                    })
                  )
                  // setTempFilters({ ...filters, EndDate: formatDate(e) })
                }}
                renderInput={params => (
                  <TextField
                    fullWidth
                    size='small'
                    {...params}
                    color='primary'
                    error={null}
                  />
                )}
              />
            </LocalizationProvider>{' '}
          </Grid>
          {/* Client Name */}
          {userRole === 'FreightForwarder' && (
            <>
              <Grid item xs={3.5}>
                <Typography>Client Name</Typography>
              </Grid>
              <Grid item xs={8.5}>
                <TextField
                  name='clientName'
                  size='small'
                  value={filters?.clientName}
                  onChange={handleFeildChange}
                  fullWidth
                />{' '}
              </Grid>
            </>
          )}
          {/* Status */}
          <Grid item xs={3.5}>
            <Typography>Status</Typography>
          </Grid>
          <Grid item xs={8.5}>
            <FormControl
              size='small'
              fullWidth
              sx={{
                margin: '1rem 0rem'
              }}
            >
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                name='status'
                value={filters?.status ?? null}
                onChange={handleFeildChange}
              >
                {options.map(option => {
                  return (
                    <MenuItem value={option.value}>{option?.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
