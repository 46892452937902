import { Autocomplete, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateOrderSpecific } from "../../../../../src/redux/orderRedux/createOrder/orderspecificslice";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getAgents } from "../apiCalls/get/getSelectList";
import { updateInputState } from "../../../../redux/orderRedux/createOrder/inputSlice";
const OrderNumber = ({ EditPermission }) => {
  const {
    orderSpecificError: { errors },
    order: { orderNumber },
    order,
    input: { agentList },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const updateOrderSpecificFun = (name, value) => {
    const objName = "orderNumber";
    dispatch(updateOrderSpecific({ name, value, objName }));
  };
  const getData = async () => {
    const promiseAgentList = getAgents();
    const dataOfAgentList = await promiseAgentList;
    dispatch(
      updateInputState({
        name: "agentList",
        data: dataOfAgentList,
      })
    );
  };

  // console.log(order, "Facebook");

  useEffect(() => {
    getData();
  }, []);
  return (
    <div
      style={{
        height: "100%",
      }}
      className="overCard"
    >
      <Typography variant="overline" color={"primary"} className="font-change">
        Order Number
      </Typography>
      <Stack
        sx={{
          maxHeight: "60vh",
          overflow: "auto",
          paddingTop: "1rem",
        }}
      >
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <TextField
            value={orderNumber?.orderNumber}
            disabled={EditPermission ? false : true} //measn if edit permisison true that mean disable shoude be false
            size="small"
            fullWidth
            type="text"
            name="innerPacks"
            label={
              <div className="font-change">
                Order Number
                {/* <span
                  style={{
                    color: "#d32f2f",
                  }}
                >
                  *
                </span> */}
              </div>
            }
            error={errors?.hasOwnProperty("orderNumber.orderNumber")}
            helperText={
              errors?.hasOwnProperty("orderNumber.orderNumber")
                ? errors["orderNumber.orderNumber"]
                : ""
            }
            onChange={(e) =>
              updateOrderSpecificFun("orderNumber", e.target.value)
            }
          />
        </div>

        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <TextField
            value={orderNumber?.cw1OrderNumber}
            disabled={EditPermission ? false : true}
            size="small"
            fullWidth
            type="text"
            name="innerPacks"
            label="CW1 Order Number"
            error={errors?.hasOwnProperty("orderNumber.cw1OrderNumber")}
            helperText={
              errors?.hasOwnProperty("orderNumber.cw1OrderNumber")
                ? errors["orderNumber.cw1OrderNumber"]
                : ""
            }
            onChange={(e) =>
              updateOrderSpecificFun("cw1OrderNumber", e.target.value)
            }
          />
        </div>

        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              name="reqInStoreDate"
              className="w-100 datepicker removeRed"
              inputFormat="DD/MM/YYYY"
              value={orderNumber?.orderDate}
              disabled={EditPermission ? false : true}
              // value={linedetails?.index?.reqInStoreDate}
              onChange={(e) => updateOrderSpecificFun("orderDate", e)}
              renderInput={(params) => (
                <TextField
                  size="small"
                  {...params}
                  label={
                    <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                      Order Date
                    </span>
                  }
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            size="small"
            disabled={EditPermission ? false : true}
            // freeSolo
            id="free-solo-2-demo"
            options={agentList}
            getOptionLabel={(option) => option.Code}
            value={orderNumber?.sendingAgent ? orderNumber?.sendingAgent : null}
            onChange={(e, val) => {
              updateOrderSpecificFun("sendingAgent", val);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sending Agent"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>

        <div
          className="font-change"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Autocomplete
            size="small"
            disabled={EditPermission ? false : true}
            // freeSolo
            id="free-solo-2-demo"
            options={agentList}
            value={
              orderNumber?.receivingAgent ? orderNumber?.receivingAgent : null
            }
            getOptionLabel={(option) => option.Code}
            onChange={(e, val) => {
              updateOrderSpecificFun("receivingAgent", val);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Recieving Agent"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div>

        {/* <Typography variant="overline" color={"primary"}>
          Milestones
        </Typography>
        <div
          style={{
            padding: "2% 10% 1% 10%",
          }}
        >
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            options={top100Films.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Order Number"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
          <Autocomplete
            freeSolo
            size="small"
            id="free-solo-2-demo"
            options={top100Films.map((option) => option.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="CW1 Order Number"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                }}
              />
            )}
          />
        </div> */}
      </Stack>
    </div>
  );
};
export default OrderNumber;
