import React, { useEffect, useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../User.css";
import { styled } from "@mui/material/styles";
import { DateFormat, DateFormatWithtime } from "../../../utils/moment";
import { API_PATH } from "../../../constant/apiPath";
import axios from "axios";
import Switch from "@mui/material/Switch";
import { baseUrl } from "../../../Config";
import { toast } from "react-hot-toast";
import { changeStatusApiCall } from "../userApi/userApiDefination";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { Button } from "@mui/material";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import urlResolver from "../../../utils/urlResolver";
import EditIcon from "@mui/icons-material/Edit";
const Usercard = ({
  Admin_Portal_Action_Modules,
  setOpenAddEditDelete,
  setPropertiesAddEditDelete,
  userData,
  setOpen,
  data,
  setModuleCaller,
  setActiveUserAndAdminNumber,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingReset, setLoadingReset] = useState(false);
  const [switchStatus, setSwitchStatus] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    setSwitchStatus(userData?.IsLoginEnabled);
  }, [userData]);
  const [PauseTimmer, setPauseTimmer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#65C466" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#a19f9c" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  console.log(userData, "userData");

  const sendResetLink = (email) => {
    setLoadingReset(true);
    axios
      .get(`${baseUrl}${API_PATH.FORGET_PASSWORD}${email}`)
      .then((res) => {
        setLoadingReset(false);
        if (res.data.IsSuccess) {
          toast.success(res.data?.Message?.Message ?? "");
        } else {
          toast.error(res.data?.Message?.Message ?? "");
        }
      })
      .catch((err) => {
        setLoadingReset(false);
        console.log(err);
      });
  };
  return (
    <>
      <div
        className="container-fluid"
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <div className="card sip-card user-card mb-2">
          <div className="card-body" style={{ padding: "10px 0px" }}>
            <div className="row align-items-center">
              <div className="col-1">
                <ul className="list-style m-0">
                  <li></li>
                </ul>
              </div>
              <div className="col-5">{userData?.ContactName ?? "N/A"}</div>
              <div className="col-2">
                <CustomSwitch
                  checked={switchStatus}
                  disabled={PauseTimmer || userData?.Roles.includes("Admin")}
                  onChange={(event) => {
                    setPauseTimmer(true);
                    changeStatusApiCall(
                      event?.target?.checked,
                      userData?.Email,
                      setSwitchStatus,
                      setActiveUserAndAdminNumber
                    );
                    setTimeout(() => {
                      setPauseTimmer(false);
                    }, 10000);
                  }}
                />
              </div>
              <div className="col-2">
                <div
                  onClick={() => {
                    sendResetLink(userData?.Email ?? "");
                  }}
                >
                  <LockOutlinedIcon />
                </div>
              </div>
              <div className="col-1">
                <div onClick={openModal}>
                  <MoreVertIcon />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div
            className="modal fade show"
            style={{ display: "block" }}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {userData?.ContactName ?? "N/A"}
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={closeModal}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-6">
                      <p>Contact No.</p>
                      <p>{userData?.ContactNumber ?? "N/A"}</p>
                    </div>
                    <div className="col-6">
                      <p>Email ID</p>
                      <p className='text-content'>{userData?.Email ?? 'N/A'}</p>
                    </div>
                    <div className="col-6 pt-3">
                      <p>Organization</p>

                      {userData?.OrgnisationCollection.map((i, ind) => {
                        return (
                          <p className='text-content'>
                            {i.OrgFullName ?? ""}
                            <br/>
                          </p>
                        );
                      }) ?? "N/A"}
                    </div>
                    <div className="col-6 pt-3">
                      <p>Registered Date</p>
                      <p
                        className="m-0 wordWrap"
                        title={userData?.OrgFullName ?? "N/A"}
                      >
                        {DateFormat(userData.RegisteredDate ?? null)}
                      </p>
                    </div>
                    <div className="col-6 pt-3">
                      <p>Last Login</p>
                      <p
                        className="m-0 wordWrap"
                        title={
                          DateFormatWithtime(userData?.LastLoginUtcDate) ??
                          "N/A"
                        }
                      >
                        {DateFormatWithtime(userData?.LastLoginUtcDate) ??
                          "N/A"}
                      </p>
                    </div>

                    <div className="col-6 pt-3">
                      <p>Access</p>
                      <div className="grant-color px-2 py-1">
                        {userData?.Access ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-6 pt-3">
                      <p>Role</p>
                      <div className="admin-color px-2 py-1">
                        {userData?.Roles ?? "N/A"}
                      </div>
                    </div>
                    <div className="col-6 pt-5">
                      {/* <p>ACTION</p> */}
                      <div
                        style={{
                          // marginLeft: "35px",
                          color: "var(--color-secondary-dark)",
                          cursor: "pointer",
                        }}
                        className="d-flex align-items-center justify-content-end"
                      >
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          PaperProps={{
                            style: {
                              direction: "ltr", // Change 'rtl' to 'ltr' for left-to-right direction
                            },
                          }}
                        >
                          {/* {Admin_Portal_Action_Modules && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                navigate(
                                  Routeconstant.USER_Orgination_mapping,
                                  {
                                    state: {
                                      item: userData,
                                    },
                                  }
                                );
                              }}
                            >
                              <img
                                src={urlResolver(
                                  `assets/orgnizationMappingFill.png`
                                )}
                                style={{ height: "22px" }}
                              />
                              Org. mapping
                            </MenuItem>
                          )} */}

                          <MenuItem
                            onClick={() => {
                              handleClose();
                              setOpen(true);
                              setModuleCaller({
                                name: "Accessibility",
                                mailId: userData?.Email,
                                userName: userData?.ContactName,
                              });
                            }}
                          >
                            <ViewModuleIcon />
                            Accessibility
                          </MenuItem>
                          {Admin_Portal_Action_Modules && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                setOpen(true);
                                setModuleCaller({
                                  name: "Role",
                                  mailId: userData?.Email,
                                  userName: userData?.ContactName,
                                });
                              }}
                            >
                              <ManageAccountsIcon />
                              Role management
                            </MenuItem>
                          )}
                          {Admin_Portal_Action_Modules && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                setOpenAddEditDelete(true);
                                setPropertiesAddEditDelete((prev) => {
                                  return {
                                    ...prev,
                                    data: userData,
                                    sender: "editUser",
                                    changeAllow: 0,
                                  };
                                });
                              }}
                            >
                              <EditIcon /> Edit
                            </MenuItem>
                          )}
                          {Admin_Portal_Action_Modules && (
                            <MenuItem
                              onClick={() => {
                                handleClose();
                                setOpenAddEditDelete(true);
                                setPropertiesAddEditDelete((prev) => {
                                  return {
                                    ...prev,
                                    data: userData,
                                    sender: "removeUser",
                                    changeAllow: 1, //1 mean all are not allowed
                                  };
                                });
                              }}
                            >
                              <PersonRemoveIcon /> Delete
                            </MenuItem>
                          )}
                        </Menu>
                        {/* <MoreVertIcon onClick={handleClick} /> */}
                        <Button variant="contained" onClick={handleClick}>
                          ACTION
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Usercard;
