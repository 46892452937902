import React, { useState } from "react";
import TablerowEnquiry  from "./TablerowEnquiry.js";
import {
  Button,
  Grid,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Switch,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import "../rms/rms.css";
import instance from "../../api/Interceptor";
import { AuthContext } from "../../store/context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { Routeconstant } from "../../routing/utils/RouteConstant";

const EnquiryList = ({ rows, setRows }) => {
  const navigate = useNavigate();
  const context = React.useContext(AuthContext);
  // console.log("context=====>", context.rmsinquiry);
  const savedTransportData = JSON.parse(localStorage.getItem("TransportData"));
  // console.log("con=====>", savedTransportData);
  const [loading, setLoading] = useState(false);
  const handleAddRow = () => {
    const newRow = {
      id: rows.length,
      PackagingType: "",
      Quantity: "",
      DimensionalUnit: "",
      Length: "",
      Length: "",
      Width: "",
      Height: "",
      WeightUnit: "",
      WeightPerPiece: "",
      containerQuant: "",
      containerType: "",
      Stakable: false, // Default value for Stakable field
      disableDelete: false, // Enable delete icon for the new row
    };

    const updatedRows = rows.map((row) => ({
      ...row,
      disableDelete: false, // Enable delete icon for the default row
    }));

    setRows([...updatedRows, newRow]);
  };

  const handleDeleteRow = (id) => {
    if (id === 0) {
      return;
    }
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
    // console.log(updatedRows, "updatedRows");
    // const updatedRows = rows.filter((row) => {
    //   if (row.id === id) {
    //     return false; // Skip deleting the row if delete icon is disabled
    //   }
    //   return true;
    // });
    // setRows(updatedRows);
    // console.log("delete", updatedRows);
  };

  const handleRowChange = (id, field, value) => {
    const updatedRows = rows.map((row) => {
      if (row.id === id) {
        return { ...row, [field]: value };
      }
      return row;
    });

    setRows(updatedRows);
  };
  //   const handleRmsDataChange = () => {
  //      setLoading(true);
  //      let invalidRows=[]
  //      let invalidRows2=[]

  //     if (["LSE","CON","BCN","LCL","BLK","LQD","BBK","OBC","UNA"].includes(savedTransportData)) {
  //      invalidRows = rows.filter((row) =>
  //      row.PackagingType === "Select" ||
  //      row.VolumeUnit === "Select" ||
  //      row.WeightUnit === "Select" ||
  //      row.Quantity === "" ||
  //      row.Volume === "" ||
  //      row.WeightPerPiece === ""
  //   );
  //      }
  //      else{
  //        invalidRows2 = rows.filter((row) =>
  //        row.containerQuant === "" ||
  //        row.containerType === "Select"
  //     );
  //      }

  //   if (invalidRows.length > 0) {
  //     alert("Please select package type,Quantity, volume unit,volume,weight unit and weight for each row");

  //   }
  //   else if (invalidRows2.length > 0) {
  //     alert("Please select container quantity and container type for each row");
  //   }
  //     else {
  //     const ratingPackages = rows.map((row) => {
  //       return {
  //         PakageTypeCode: "",
  //         PakageTypeName: row.PackagingType,
  //         FK_RH_InquiryNo: context.rmsinquiry,
  //         PackageQuantity: row.Quantity,
  //         Volume: row.Volume,
  //         VolumeUnit: row.VolumeUnit,
  //         weight: row.Weight,
  //         weightUnit: row.WeightUnit,
  //         IsStackable: row.Stakable,
  //         ContainerType: row.containerType,
  //         ContainerCount: row.containerQuant
  //       };
  //     });

  //     const body = { RatingPackages: ratingPackages };

  //     instance
  //       .post("api/v1/RateManagement/CreateUpdateRatePackage", body)
  //       .then((res) => {
  //       Swal.fire({
  //       icon: 'info',
  //       text: 'No live rates are currently available. We will notify you as soon as the quotes are ready.',
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate(Routeconstant.RMS);
  //       }
  //     });

  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       })};
  // };
  const transportTypeString = savedTransportData;
  const transportTypeParts = transportTypeString.split(" (");
  const code =
    transportTypeParts.length === 2
      ? transportTypeParts[1].replace(")", "")
      : null;
  return (
    // <>
    //   <Grid
    //     container
    //     spacing={1}
    //     style={{
    //       width: "100%",
    //       display: "block",
    //       overflowX: "auto",
    //     }}
    //   >
    //     <Grid
    //       item
    //       xs={12}
    //       sx={{
    //         cursor: "pointer",
    //         marginBottom: "1rem",
    //         fontFamily: "var(--family-label)",
    //       }}
    //     >
    //       {['LCL', 'LSE', 'ULD', 'BBK', 'LQD'].includes(code)
    //         ? 'Cargo Details'
    //         : 'Container Details'}
    //     </Grid>
    //     <Table>
    //       {[
    //         "LSE",
    //         "CON",
    //         "BCN",
    //         "LCL",
    //         "BLK",
    //         "LQD",
    //         "BBK",
    //         "OBC",
    //         "UNA",
    //         'ULD',
    //       ].includes(code) ? (
    //         <>
    //           <Grid item xs={12} sx={{ width: "100%" }}>
    //             <TableContainer component={Paper}>
    //               <TableHead>
    //                 <TableRow>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Action
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Packaging Type
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Quantity
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Dimensional Unit
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Length 
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Width 
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Height 
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Calculated Volume (m3)
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Weight Unit
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Weight Per Piece
    //                   </TableCell>
    //                   <TableCell span="1" className="tablecell" align="center">
    //                     Stakable
    //                   </TableCell>
    //                 </TableRow>
    //               </TableHead>
    //             </TableContainer>
    //           </Grid>
    //         </>
    //       ) : (
    //         <>
    //           <Grid item xs={12} sx={{ width: "100%" }}>
    //             <TableContainer component={Paper}>
    //               <Table>
    //                 <TableHead>
    //                   <TableRow>
    //                     <TableCell
    //                       span="1"
    //                       className="tablecell"
    //                       align="center"
    //                     >
    //                       Action
    //                     </TableCell>
    //                     <TableCell
    //                       span="1"
    //                       className="tablecell"
    //                       align="center"
    //                       style={{ width: "40%" }}
    //                     >
    //                       Quantity
    //                     </TableCell>
    //                     <TableCell
    //                       span="1"
    //                       className="tablecell"
    //                       align="center"
    //                     >
    //                       Container Type
    //                     </TableCell>
    //                   </TableRow>
    //                 </TableHead>
    //               </Table>
    //             </TableContainer>
    //           </Grid>
    //         </>
    //       )}
       
    //       <TableBody>
    //         {rows?.map((row, index) => (
    //           <TablerowEnquiry 
    //             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //             className="table-row-data"
    //             key={row.id}
    //             data={row}
    //             onDelete={handleDeleteRow}
    //             onChange={handleRowChange}
    //           />
    //         ))}
    //       </TableBody>
    //     </Table>
    
    //   </Grid>
    //   <Grid>
    //     <Grid item xs={12} className="pb-3">
    //       <Button
    //         href="#text-buttons"
    //         endIcon={<AddOutlinedIcon />}
    //         sx={{ textTransform: "capitalize" }}
    //         onClick={handleAddRow}
    //       >
    //         Add New
    //       </Button>
    //     </Grid>
    //     <Grid item xs={12}></Grid>
    //   </Grid>
    // </>
    <>
      <Grid
        container
        spacing={1}
        style={{
          width: '100%',
          display: 'block',
          overflowX: 'auto'
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            cursor: 'pointer',
            marginBottom: '1rem',
            fontFamily: 'var(--family-label)'
          }}
        >
          {['LCL', 'LSE', 'ULD', 'BBK', 'LQD'].includes(code)
            ? 'Cargo Details'
            : 'Container Details'}
          {/* Cargo Details */}
        </Grid>

        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table stickyHeader>
            {[
              'LSE',
              'CON',
              'BCN',
              'LCL',
              'BLK',
              'LQD',
              'BBK',
              'OBC',
              'UNA',
              'ULD'
            ].includes(code) ? (
              <TableHead>
                <TableRow>
                  <TableCell className='tablecell' align='center'>
                    Action
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Packaging Type
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Quantity
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Dimensional Unit
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Length
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Width
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Height
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Calculated Volume (m³)
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Weight Unit
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Weight Per Piece
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Stakable
                  </TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <TableHead>
                <TableRow>
                  <TableCell className='tablecell' align='center'>
                    Action
                  </TableCell>
                  <TableCell
                    className='tablecell'
                    align='center'
                    style={{ width: '40%' }}
                  >
                    Quantity
                  </TableCell>
                  <TableCell className='tablecell' align='center'>
                    Container Type
                  </TableCell>
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {rows?.map((row, index) => (
                <TablerowEnquiry
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  className='table-row-data'
                  key={row.id}
                  data={row}
                  onDelete={handleDeleteRow}
                  onChange={handleRowChange}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container>
        <Grid item xs={12} className='pb-3'>
          <Button
            href='#text-buttons'
            endIcon={<AddOutlinedIcon />}
            sx={{ textTransform: 'capitalize' }}
            onClick={handleAddRow}
          >
            Add New
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default EnquiryList;
