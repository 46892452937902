import {
  Autocomplete,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { docTypes } from "../apiCalls/get/docList";
import CloseIcon from "@mui/icons-material/Close";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import { updateInputState } from "../../../../redux/orderRedux/createOrder/inputSlice";
import {
  addDocument,
  removeCurrentDoc,
  updateCurrentDoc,
} from "../../../../redux/orderRedux/createOrder/docSlice";
import { useState } from "react";
import { orderDocSchema } from "../../../../schema/orderDocSchema";
import { addErrors } from "../../../../redux/orderRedux/createOrder/orderErrors/orderspecificerrorslice";
import { API_PATH } from "../../../../constant/apiPath";
import orderInstance from "../../../../api/orderInterceptor";
import moment from "moment/moment";
const DocUpload = ({ EditPermission }) => {
  const {
    input: { docTypeList },
    documents: { currentDoc, docList },
    orderSpecificError: { errors },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState("");
  const { permisisonOrder } = useSelector((state) => state.orderPermission);
  const getData = async () => {
    const promiseDocTypes = docTypes();
    const dataOfDocTypes = await promiseDocTypes;
    dispatch(
      updateInputState({
        name: "docTypeList",
        data: dataOfDocTypes,
      })
    );
  };
  const updateCDoc = (name, value) => {
    dispatch(updateCurrentDoc({ name, value }));
  };
  const removeFile = () => {
    dispatch(removeCurrentDoc());
  };
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const formatDate = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
  };
  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return "0 Bytes";
    let k = 1000,
      dm = decimalPoint || 2,
      i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }

  const docUpload = (doc) => {
    let splitted = doc?.base?.split(",");
    let body = {
      DocsPk: "",
      DocsFk: "",
      DocsDocType: currentDoc?.docType?.Code,
      docTypeDesc: currentDoc?.docType?.Description,
      DocsFileName: currentDoc?.docName,
      DocsFileSize: `${Math.ceil(
        formatFileSize(currentDoc?.docFile[0]?.size)
      )}`,
      DocsFileSizeUnit: "kB",
      DocsUploadedDate: formatDate(new Date()),
      DocsUploadedBY: doc?.userName,
      DocsFileData: splitted.length > 0 ? splitted[1] : "",
      DocsFileExtention: "pdf",
      DocsFileConversion: splitted.length > 0 ? splitted[0] : "",
    };
    orderInstance
      .post(API_PATH.UPLOAD_DOC, body)
      .then((res) => {
        // setMergableOrders(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpload = async () => {
    const { Name } = await JSON.parse(localStorage.getItem("userData"));
    const base = await getBase64(currentDoc?.docFile[0]);
    const info = {
      index: docList.length + 1,
      userName: Name,
      uploadedOn: new Date(),
      base: base,
    };
    orderDocSchema
      .validate(currentDoc, { abortEarly: false })
      .then(() => {
        dispatch(addDocument(info));
      })
      .catch((validationErrors) => {
        const errorsObj = {};
        validationErrors.inner.forEach((error) => {
          errorsObj[error.path] = error.message;
        });
        dispatch(addErrors({ errorsObj }));
        setError(errorsObj);
      })
      .finally(() => {
        docUpload(info);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (
      currentDoc?.docName?.length >= 1 &&
      currentDoc?.docFile?.length >= 1 &&
      currentDoc?.docType?.Code?.length >= 1
    )
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [currentDoc]);
  return (
    <div>
      <Typography variant="overline" color={"primary"} className="font-change">
        Upload E doc
      </Typography>
      <div
        className="font-change"
        style={{
          marginBottom: "1rem",
        }}
      >
        <Autocomplete
          name="incoTerm"
          freeSolo
          disabled={EditPermission ? false : true}
          size="small"
          id="free-solo-2-demo"
          options={docTypeList}
          value={currentDoc?.docType?.length > 1 ? currentDoc.docType : null}
          getOptionLabel={(option) => option.Description}
          onChange={(e, value) => updateCDoc("docType", value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Document Type"
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </div>
      
      <div
        className="font-change"
        style={{
          marginBottom: "1rem",
        }}
      >
        <TextField
          size="small"
          name="totalPrice"
          label="Document Name"
          error={errors?.hasOwnProperty("docName")}
          helperText={
            errors?.hasOwnProperty("docName") ? errors["docName"] : ""
          }
          disabled={EditPermission ? false : true}
          value={currentDoc?.docFile?.length > 0 ? currentDoc?.docFile[0]?.name :""}
          onChange={(e) =>
             
            updateCDoc("docName", e.target.value)}
          fullWidth
        />
      </div>

      <div
        className="font-change"
        style={{
          position: "relative",
        }}
      >
        {/* after doc is uploaded */}
        {currentDoc?.docFile?.length > 0 ? (
          <div
            className="font-change"
            style={{
              position: "absolute",
              zIndex: "2",
              height: "100%",
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <div
              className="font-change"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton color="secondary" size="small" onClick={removeFile}>
                <CloseIcon />
              </IconButton>
            </div>
            <div
              className="font-change"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "1rem",
                height: "80%",
              }}
            >
              <FilePresentIcon color="primary" />
              <Typography variant="overline" className="font-change">
                {currentDoc?.docFile[0]?.name}
              </Typography>
            </div>
          </div>
        ) : null}

        <DropzoneArea
          filesLimit={1}
          showAlerts={false}
          showFileNames={true}
          showPreviewsInDropzone={false}
          // classes={{ root: clsx([classes.dropZoneRoot, uploading ? classes.disableClick : null]) }}
          onChange={(e) => {
            if (EditPermission) updateCDoc("docFile", e);
          }}
          style={{ opacity: "0.6" }}
          // dropzoneClass={classes.dropZone}
        />
      </div>
      <div
        className="buttons-container"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          paddingTop: "0.5rem",
        }}
      >
        <Button
          className="font-change"
          color="primary"
          disabled={permisisonOrder?.CreateOrderDocument ? isDisabled : true}
          variant="contained"
          onClick={() => {
            handleUpload();
          }}
        >
          Upload
        </Button>
      </div>
    </div>
  );
};
export default DocUpload;
