import React, { useState } from 'react'
import '../../screens/shipment/shipment.css'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  Grid
} from '@mui/material'
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import CustomTooltip from '../../utils/CustomTooltip'
import SortModalTooltip from '../../utils/SortModalTooltip'

const SortModalShipment = ({
  modalShipment,
  setModalShipment,
  setSortColumn,
  setSortDirection,
  status,
  getShipmentCollection,
  setLoadingOnSearch,
  sortFilterObj,
  setCurrentPage
}) => {
  const handleClose = () => setModalShipment(false)
  const [hoverColumn, setHoverColumn] = useState(null)

  const handleSortSelection = (column, direction) => {
    setSortColumn(column)
    setSortDirection(direction)
    sortFilterObj.current = {
      sortColumn: column,
      sortDirection: direction
    }
    getShipmentCollection(0, status)
    setLoadingOnSearch(true)
    setCurrentPage(0)
    setModalShipment(false)
  }

  return (
    <Dialog
      open={modalShipment}
      onClose={handleClose}
      aria-labelledby='scroll-dialog-title'
      aria-describedby='scroll-dialog-description'
      fullWidth
    >
      <DialogContent>
        <div className='tooltip-container'>
          <SortModalTooltip />
        </div>
        <Card style={{ border: 'none', boxShadow: 'none' }}>
          <CardHeader
            subheader={
              <div className='d-flex justify-content-between'>
                <Button
                  className='font-change'
                  color={'primary'}
                  onClick={() => {
                    sortFilterObj.current = {
                      sortColumn: '',
                      sortDirection: 'Asc'
                    }
                    // setLoadingOnSearch(true)
                    setModalShipment(false)
                    // setSortColumn('')
                    // setSortDirection('Asc')
                    // getShipmentCollection(0, status)
                  }}
                >
                  <KeyboardBackspaceIcon /> Back
                </Button>
                <Button
                  className='font-change'
                  color={'primary'}
                  onClick={() => {
                    sortFilterObj.current = {
                      sortColumn: '',
                      sortDirection: 'Asc'
                    }
                    setSortColumn('')
                    setSortDirection('Asc')
                    setCurrentPage(0)
                  }}
                >
                  Reset
                </Button>
              </div>
            }
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div
                  className='sort-container'
                  onMouseEnter={() => setHoverColumn('Departure')}
                  onMouseLeave={() => setHoverColumn(null)}
                >
                  <div className='sort-label'>Departure</div>
                  <div className='sort-subtext'>ETD / ATD</div>
                  {hoverColumn === 'Departure' && (
                    <div className='sort-options'>
                      <Button
                        onClick={() =>
                          handleSortSelection('JS_Departure', 'Asc')
                        }
                      >
                        <AlignHorizontalLeftIcon
                          style={{
                            fontSize: '20px',
                            transform: 'rotate(180deg)',
                            marginRight: '8px'
                          }}
                        />
                        Asc
                      </Button>
                      <Button
                        onClick={() =>
                          handleSortSelection('JS_Departure', 'Desc')
                        }
                      >
                        <AlignHorizontalRightIcon
                          style={{
                            fontSize: '20px',
                            marginRight: '8px'
                          }}
                        />
                        Desc
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  className='sort-container'
                  onMouseEnter={() => setHoverColumn('Arrival')}
                  onMouseLeave={() => setHoverColumn(null)}
                >
                  <div className='sort-label'>Arrival</div>
                  <div className='sort-subtext'>ETA / ATA</div>
                  {hoverColumn === 'Arrival' && (
                    <div className='sort-options'>
                      <Button
                        onClick={() => handleSortSelection('JS_Arrival', 'Asc')}
                      >
                        <AlignHorizontalLeftIcon
                          style={{
                            fontSize: '20px',
                            transform: 'rotate(180deg)',
                            marginRight: '8px'
                          }}
                        />
                        Asc
                      </Button>
                      <Button
                        onClick={() =>
                          handleSortSelection('JS_Arrival', 'Desc')
                        }
                      >
                        <AlignHorizontalRightIcon
                          style={{
                            fontSize: '20px',
                            marginRight: '8px'
                          }}
                        />
                        Desc
                      </Button>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog>
  )
}

export default SortModalShipment
