import { useEffect } from 'react'
import TableHeader from './TableHeader'
import TableRow from './TableRow'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchDoclist,
  updateDocListBody
} from '../../../../redux/documentRedux/documentListSlice'

export default function List() {
  const dispatch = useDispatch()

  const {
    docListState: { data, isLoading, isError, body, totalCount }
  } = useSelector(state => state.documentApproval)
  useEffect(() => {
    const promise = dispatch(fetchDoclist(body))
    return () => {
      promise.abort()
    }
  }, [body.CurrentPage])
  const updateBody = (objname, name, value) => {
    dispatch(
      updateDocListBody({
        obj: 'docListState',
        objName: objname,
        name: name,
        value: value
      })
    )
  }
  console.log('data', totalCount)
  return (
    <>
      <div className='tableWrap'>
        <table className='table'>
          <TableHeader />
          {data &&
            data?.map((row, index) => <TableRow key={index} row={row} />)}
        </table>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {totalCount > data.length && (
          <Button
            variant='contained'
            size='small'
            style={{
              marginTop: '4px'
            }}
            onClick={() => {
              updateBody(null, 'showMore', true)
              updateBody('body', 'CurrentPage', body.CurrentPage + 1)
            }}
          >
            Show more
          </Button>
        )}
      </div>
    </>
  )
}
