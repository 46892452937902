import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { userTypeSelect } from "../../../../redux/bookingRedux/createBookingSlice";
import { updateDetails } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import { useState } from "react";

const UserTypeDialog = ({ open, handleClose, Name, buyerSuplier }) => {
  const dispatch = useDispatch();
  const { userType } = useSelector((state) => state.createBooking);
  const {
    bookingBasicDetails: { BookingHeader },
  } = useSelector((state) => state);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  
  if (
    buyerSuplier[0]?.Roles.includes("Buyer") ||
    buyerSuplier[0]?.Roles.includes("Supplier")
  ) {
    return (
      <>
        <Dialog
          open={open}
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <DialogContent>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ paddingBottom: "1rem" }}
            >
              Proceed As
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
                gap: "0.5rem",
                paddingBottom: "2rem"
              }}
            >
              {roles.map((item, index) => {
                return (
                  <Chip
                    label={item.label}
                    variant={userType === item.label ? "" : "outlined"}
                    sx={
                      userType === item.label
                        ? {
                            padding: "5rem",
                            backgroundColor: "var(--color-miscellaneous-1)",
                          }
                        : {
                            padding: "5rem",
                          }
                    }
                    onClick={() => {
                      dispatch(userTypeSelect(item?.label));
                      dispatch(
                        updateDetails({
                          objName: "BookingHeader",
                          name: "SupplierBuyerType",
                          value: item?.label,
                        })
                      );
                      dispatch(
                        updateDetails({
                          objName: "BookingHeader",
                          name: "CreatedUserName",
                          value: Name,
                        })
                      );
                      handleClose();
                    }}
                  />
                );
              })}
            </div>
          </DialogContent>

        </Dialog>
      </>
    );
  }
};

const roles = [{ label: "Buyer" }, { label: "Supplier" }];

export default UserTypeDialog;
