import { useEffect, useState } from 'react'
import OrgDest from './OrgDest'
import BuyerSupplier from './BuyerSupplier'
import instance from '../../../../api/Interceptor'
import { API_PATH } from '../../../../constant/apiPath'
import DialogActions from '@mui/material/DialogActions'
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateConsigneeAddress,
  updateConsigneeName,
  updateConsignorAddress,
  updateConsignorName,
  updateUserData,
  userTypeSelect
} from '../../../../redux/bookingRedux/createBookingSlice'
import {
  updateBookingHeader,
  updateDetails
} from '../../../../redux/bookingRedux/bookingBasicDetailSlice'
import { useLocation, useParams } from 'react-router-dom'
import EditBuyerSupplier from './EditBuyerSupplier'
import UserTypeDialog from './UserTypeDialog'
import { CleaningServices } from '@mui/icons-material'
import {
  deststorepage,
  originstorepage
} from '../../../../redux/bookingRedux/storeDataSlice'
import {
  destselectedpage,
  originselectedpage
} from '../../../../redux/bookingRedux/selectedDataSlice'

const UserType = () => {
  const { bookingId } = useParams()
  /* -----------------------------States----------------------------- */
  const location = useLocation()

  const dispatch = useDispatch()
  const { userType } = useSelector(state => state.createBooking)
  const {
    bookingBasicDetails: { BookingHeader },
    createBooking: { userData },
    storeData: {
      store: { originunloco, destunloco }
    }
  } = useSelector(state => state)
  const [edit, setEdit] = useState([
    {
      editBuyer: false,
      newName: '',
      newAddress1: '',
      newAddress2: '',
      newCity: '',
      newPin: ''
    },
    {
      editSupplier: false,
      newName: '',
      newAddress1: '',
      newAddress2: '',
      newCity: '',
      newPin: ''
    }
  ])

  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('sm')
  const { Email } = JSON.parse(localStorage.getItem('userData'))
  const [buyerSuplier, setBuyerSupplier] = useState([])
  const [open, setOpen] = useState(
    location.pathname.includes('edit') ? false : !userType
  )

  const { Name } = JSON.parse(localStorage.getItem('userData'))

  const [checked, setChecked] = useState(() => {
    return localStorage.getItem('userType') === 'Buyer'
  })

  const [bookingData, setBookingData] = useState(null)

  useEffect(() => {
    localStorage.setItem('userType', checked ? 'Buyer' : 'Supplier')
  }, [checked])
  /* -----------------------------APIs----------------------------- */

  const getUserData = () => {
    let body = {
      OrgCode: '',
      OrgFullName: '',
      EmailId: Email,
      SearchData: '',
      Role: '',
      RegisteredDateFrom: '',
      RegisteredDateTo: '',
      LastLoginUtcDateFrom: '',
      LastLoginUtcDateTo: '',
      ContactName: '',
      ContactNumber: '',
      Access: '',
      SortColumnName: '',
      SortDirection: '',
      CurrentPage: 0,
      PageSize: 10
    }
    instance.post(`${API_PATH.GET_ALL_USER}`, body).then(res => {
      setBuyerSupplier(res?.data)
      dispatch(
        updateUserData({ keyName: 'initialBuyerOrSupplier', value: res.data })
      )
    })
  }

  /* -----------------------------Functions----------------------------- */
  const handleChecked = event => {
    const newChecked = event.target.checked
    setChecked(newChecked)
    const selectedUserType = newChecked ? 'Buyer' : 'Supplier'
    dispatch(userTypeSelect(selectedUserType))
    dispatch(
      updateDetails({
        objName: 'BookingHeader',
        name: 'SupplierBuyerType',
        value: selectedUserType
      })
    )
    dispatch(
      updateDetails({
        objName: 'BookingHeader',
        name: 'CreatedUserName',
        value: Name
      })
    )
  }

  const handleClose = () => {
    if (!location.pathname.includes('edit')) {
      setOpen(false)
    }
  }

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    const newChecked = localStorage.getItem('userType') === 'Buyer'
    setChecked(newChecked)
    const selectedUserType = newChecked ? 'Buyer' : 'Supplier'
    dispatch(userTypeSelect(selectedUserType))
    dispatch(
      updateDetails({
        objName: 'BookingHeader',
        name: 'SupplierBuyerType',
        value: selectedUserType
      })
    )
    dispatch(
      updateDetails({
        objName: 'BookingHeader',
        name: 'CreatedUserName',
        value: Name
      })
    )
  }, [checked])

  const getBuyerSupplier = (org, role) => {
    let body = {
      OrganisationCode: org,
      Type: role
    }
    instance.post(`${API_PATH.GET_BUYER_SUPPLIER}`, body).then(res => {
      if (
        role === 'Buyer' &&
        BookingHeader?.ConsigneeCode &&
        userData?.address?.buyerAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: 'viewSupplier',
            value: res?.data?.SupplierBuyers
          })
        )
        let temp = { ...userData.address }
        temp.buyerAddress = res?.data?.Organisation?.Addresses

        dispatch(updateUserData({ keyName: 'address', value: temp }))
      } else if (
        role === 'Supplier' &&
        BookingHeader.ConsignorCode &&
        userData?.address?.supplierAddress?.length === 0
      ) {
        dispatch(
          updateUserData({
            keyName: 'viewBuyers',
            value: res.data.SupplierBuyers
          })
        )
        let temp = { ...userData.address }
        temp.supplierAddress = res?.data?.Organisation?.Addresses
        dispatch(updateUserData({ keyName: 'address', value: temp }))
      }
    })
  }

  const getDetails = async () => {
    if (bookingId) {
      // setLoading(true)
      const body = {
        BookingNo: bookingId
      }
      await instance
        .post(`${API_PATH.GET_BOOKING_DETAILS}`, body)
        .then(async res => {
          setBookingData(res?.data)
          dispatch(updateBookingHeader(res?.data?.BookingHeader))
          // backfill consignor name
          const supliers = userData?.viewSupplier
          const supplierCodeToFind = res?.data?.BookingHeader?.ConsignorCode
          const supplier = supliers.find(
            supplier => supplier.OrgCode === supplierCodeToFind
          )
          dispatch(updateConsignorName(supplier))

          // backfill consignor address
          // getBuyerSupplier(supplierCodeToFind)
          const supplierAddresses = userData?.address?.supplierAddress
          const supplierConsignorCode = res?.data?.BookingHeader?.ConsignorCode
          const supplierAddress = supplierAddresses.find(
            supplierAddress => supplierAddress.Code === supplierConsignorCode
          )
          dispatch(updateConsignorAddress(supplierAddress))

          // backfill consignee name
          if (!userData.selectedBuyer) {
            const buyers = userData?.viewBuyers
            const buyersCodeToFind = res?.data?.BookingHeader?.ConsigneeCode
            const buyer = buyers.find(
              buyer => buyer.OrgCode === buyersCodeToFind
            )
            dispatch(updateConsigneeName(buyer))
          }

          // backfill consignee address
          const buyerAddresses = userData?.address?.buyerAddress
          const buyersConsigneeCode = res?.data?.BookingHeader?.ConsigneeCode
          const buyerAddress = buyerAddresses.find(
            buyerAddress => buyerAddress.Code === buyersConsigneeCode
          )
          dispatch(updateConsigneeAddress(buyerAddress))

          // backfill origin port
          let originPortName = res?.data?.BookingHeader.OriginPortName
          let body_origin = {
            CountryCode: '',
            Search: originPortName ?? ''
          }

          await instance
            .post('api/v1/master/GetUnlocoInfo', body_origin)
            .then(result => {
              dispatch(originstorepage(result?.data))

              const ports = result?.data
              const originPortToFind = res?.data?.BookingHeader?.OriginPort
              const port = ports.find(port => port.Code === originPortToFind)

              dispatch(originselectedpage(port))
            })

          // backfill destination port
          let destinationPortName = res?.data?.BookingHeader.DestinationPortName
          let body_dest = {
            CountryCode: '',
            Search: destinationPortName ?? ''
          }

          await instance
            .post('api/v1/master/GetUnlocoInfo', body_dest)
            .then(result => {
              dispatch(deststorepage(result?.data))

              const ports = result?.data
              const destPortToFind = res?.data?.BookingHeader?.DestinationPort
              const port = ports.find(port => port.Code === destPortToFind)

              dispatch(destselectedpage(port))
            })
        })
      // .catch(err => {
      //   setError(true)
      // })
      // .finally(() => {
      //   setLoading(false)
      // })
    }
  }

  useEffect(() => {
    getDetails()
  }, [bookingId, userData?.viewSupplier, userData?.viewBuyers])

  useEffect(() => {
    userData.selectedSupplier &&
      getBuyerSupplier(BookingHeader.ConsignorCode, 'Supplier')
  }, [userData.selectedSupplier])

  useEffect(() => {
    if (userType === 'Buyer' && BookingHeader.ConsigneeCode) {
      getBuyerSupplier(BookingHeader.ConsigneeCode, 'Buyer')
    } else if (userType === 'Supplier' && BookingHeader.ConsignorCode) {
      getBuyerSupplier(BookingHeader.ConsignorCode, 'Supplier')
    }
  }, [BookingHeader.ConsigneeCode, BookingHeader.ConsignorCode])


  return (
    <>
      <div className='user-type'>
        {/* <UserTypeDialog
          open={open}
          handleClose={handleClose}
          Name={Name}
          buyerSuplier={buyerSuplier}
        /> */}
        <div className='d-flex justify-content-between'>
          <h5>Basic Details</h5>
          {buyerSuplier[0]?.Roles.includes('Buyer') &&
            buyerSuplier[0]?.Roles.includes('Supplier') && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch checked={checked} onChange={handleChecked} />
                  }
                  label={checked ? 'Buyer' : 'Supplier'}
                />
              </FormGroup>
            )}
        </div>
        <div className='userTypesContainer'>
          {location.pathname.includes('edit') ? (
            <EditBuyerSupplier />
          ) : (
            <BuyerSupplier
              edit={edit}
              setEdit={setEdit}
              collection={userData?.initialBuyerOrSupplier}
              role={userType}
              buyer
            />
          )}
          {/* <OrgDest edit={edit} setEdit={setEdit} /> */}
        </div>
      </div>
    </>
  )
}
export default UserType

const roles = [{ label: 'Buyer' }, { label: 'Supplier' }]
