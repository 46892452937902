import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import instance from '../../../api/Interceptor'
import { API_PATH } from '../../../constant/apiPath'
import { Puff } from 'react-loader-spinner'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { DateFormat } from '../../../utils/moment'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'
import PreviewIcon from '@mui/icons-material/Preview'
import {
  Container,
  DetailsContainer,
  Heading,
  HeadingDetails
} from './components/CustomComp'
import PackingDetails from './components/PackingDetails'
import downloadDocument from '../downloadDocument'
import { genrateDownloadLink } from '../../../utils/utility'
import toast from 'react-hot-toast'
import FileViewer from 'react-file-viewer'
import DocViewer from 'react-doc-viewer'
import { PDFDocument, StandardFonts, degrees, rgb } from 'pdf-lib'
import download from 'downloadjs'
const ViewBooking = () => {
  const { bookingId } = useParams()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [open, setOpen] = useState(false)
  const [docLink, setDocLink] = useState('')
  const [extension, setExtension] = useState('')
  const navigate = useNavigate()
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')
  const [docLoading, setDocLoading] = useState({
    preview: false,
    download: false
  })

  const handleClose = () => {
    setOpen(false)
  }

  const docs = [
    { uri: `${docLink}` }
    // Local File
  ]

  const { Email } = JSON.parse(localStorage.getItem('userData'))

  const getDetails = () => {
    setLoading(true)
    const body = {
      BookingNo: bookingId
    }
    instance
      .post(`${API_PATH.GET_BOOKING_DETAILS}`, body)
      .then(res => {
        setData(res?.data)
      })
      .catch(err => {
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getDetails()
  }, [])
  function showAdditionalServices(data) {
    const res = data
      .filter(item => item?.IsTrue === true)
      .map(item => item?.type)
      .join(', ')
    return res
  }

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center w-100 h-100'>
        {/* <CircularProgress color="inherit" /> */}
        <Puff
          height='80'
          width='80'
          radius={1}
          color='#0a2d44'
          ariaLabel='puff-loading'
          wrapperStyle={{}}
          wrapperClass=''
          visible={true}
        />
      </div>
    )
  }
  function formatFileSize(bytes, decimalPoint) {
    if (bytes == 0) return '0 Bytes'
    let k = 1000,
      dm = decimalPoint || 2,
      sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  async function modifyPdf(link, name) {
    // Fetch an existing PDF document
    const url = link
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

    // Load a PDFDocument from the existing PDF bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    // Get the first page of the document
    const pages = pdfDoc.getPages()

    // Get the width and height of the first page
    /* const modeNames = values(BlendMode);
      
       modeNames.forEach((m, i) => {
          firstPage.drawText(`blendMode: ${m}`, {
      size: 14,
      x: 40,
      y: 700 - i * 20,
      color: cmyk(0, 0, 0, 0.65),
      blendMode: m,
          });
        }); */

    // Draw a string of text diagonally across the first page
    pages.map(page => {
      const { width, height } = page.getSize()

      return page.drawText('Draft!', {
        x: width / 3,
        y: height / 2,
        size: 80,
        font: helveticaFont,
        color: rgb(0.95, 0.1, 0.1),
        rotate: degrees(0)
        // color: cmyk(0.58, 0.78, 0.53, 0),
        // blendMode: BlendMode.Darken,
      })
    })

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save()

    // Trigger the browser to download the PDF document
    download(pdfBytes, `${name}`, 'application/pdf')
  }

  const file = docLink
  const type = extension

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
      >
        <DialogTitle>Preview</DialogTitle>
        <DialogContent style={{ height: '70rem' }}>
          {/* <iframe
            title="doc-view"
            src={`${docLink}#toolbar=0`}
            width="100%"
            height="100%"
          /> */}
          <div
            style={{
              zIndex: 1,
              position: 'absolute',
              height: '2rem',
              width: '2rem',
              opacity: '0.5',
              left: '35%',
              top: '50%',
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              transform: ' rotate(-45deg)'
            }}
          >
            <span
              style={{
                color: 'red',
                fontSize: 'var(--fs-md)',
                fontWeight: '700'
              }}
            >
              Draft!
            </span>
            <span
              style={{
                color: 'red',
                fontSize: 'var(--fs-head)',
                fontWeight: '700'
              }}
            >
              {Email}
            </span>
          </div>
          <FileViewer fileType={type} filePath={file} />
          {/* <DocViewer documents={docs} /> */}
        </DialogContent>
      </Dialog>

      <div className='layout-padding view-booking-parent'>
        <IconButton
          onClick={() => {
            navigate(-1)
          }}
        >
          <ArrowBackIcon />
        </IconButton>{' '}
        <h5 className='common-p'>Booking Number : {bookingId}</h5>
        {data?.BookingHeader?.StatusCode === '0' ||
        data?.BookingHeader?.StatusCode === '2' ? (
          <IconButton
            color='primary'
            onClick={() => {
              navigate(`/booking/edit/${bookingId}`)
            }}
          >
            <EditIcon />
          </IconButton>
        ) : (
          <div></div>
        )}
        {/* <IconButton
          color='primary'
          onClick={() => {
            navigate(`/booking/edit/${bookingId}`)
          }}
        >
          <EditIcon />
        </IconButton> */}
      </div>
      <div className=' view-booking-header'>
        <h5 className='common-p'>Basic Details</h5>
        <hr />
        {/* TransportMode CW1 Number Created on */}
        <Container>
          <DetailsContainer>
            <Heading>Transport Mode: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.TransportMode}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Cargo Type: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.Carotype}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>CW1 Number: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.CWBookingNumber}
            </HeadingDetails>
          </DetailsContainer>
        </Container>
        {/* Transport Details */}
        <Container>
          <DetailsContainer>
            <Heading>Consignor Name: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.ConsignorName}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Consignee Name: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.ConsigneeName}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Created By: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.CreatedUserName?.split('@')?.[0]}
            </HeadingDetails>
          </DetailsContainer>

          <DetailsContainer>
            <Heading>Gross Weight: </Heading>{' '}
            <HeadingDetails>{`${data?.BookingHeader?.GrossWeight} ${data?.BookingHeader?.WeightUnit}`}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Gross Volume: </Heading>{' '}
            <HeadingDetails>{`${data?.BookingHeader?.GrossVolume} ${data?.BookingHeader?.VolumeUnit}`}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Created On: </Heading>{' '}
            <HeadingDetails>
              {DateFormat(data?.BookingHeader?.CreateDateTimeUTC)}
            </HeadingDetails>
          </DetailsContainer>
          {data?.BookingHeader?.AdditionalServices.length > 0 && (
            <DetailsContainer>
              <Heading>Additional Servces: </Heading>{' '}
              <HeadingDetails>
                {showAdditionalServices(
                  data?.BookingHeader?.AdditionalServices
                )}
              </HeadingDetails>
            </DetailsContainer>
          )}
          <DetailsContainer>
            <Heading>Description: </Heading>{' '}
            <HeadingDetails>{`${data?.BookingHeader?.GoodsDescription}`}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Is Hazardous: </Heading>{' '}
            <HeadingDetails>{`${data?.BookingHeader?.IsHazardous}`}</HeadingDetails>
          </DetailsContainer>
          {data?.BookingHeader?.IsHazardous == true && (
            <DetailsContainer>
              <Heading>Hazardous good details: </Heading>{' '}
              <HeadingDetails>{`${data?.BookingHeader?.HazardousGoodDetails}`}</HeadingDetails>
            </DetailsContainer>
          )}
        </Container>
      </div>
      <div className=' view-booking-header'>
        <h5 className='common-p'>Transport Details</h5>
        <hr />
        <Container>
          <DetailsContainer>
            <Heading>Origin Port Name: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.OriginPortName}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Origin Port: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.OriginPort}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>ETD: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.ETD?.split('T')?.[0]}
            </HeadingDetails>
          </DetailsContainer>
        </Container>
        <Container>
          <DetailsContainer>
            <Heading>Destination Port Name: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.DestinationPortName}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Destination Port: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.DestinationPort}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>ETA: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.ETA?.split('T')?.[0]}
            </HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Preferred Carrier: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.CarrierName}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Incoterm: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.Incoterm}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Service Level: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.MovementType}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Release Type: </Heading>{' '}
            <HeadingDetails>{data?.BookingHeader?.ReleaseType}</HeadingDetails>
          </DetailsContainer>
          <DetailsContainer>
            <Heading>Marks and Numbers: </Heading>{' '}
            <HeadingDetails>
              {data?.BookingHeader?.MarksandNumbers}
            </HeadingDetails>
          </DetailsContainer>
        </Container>
      </div>
      <PackingDetails data={data} />
      <div className='view-booking-header'>
        <h5 className='common-p'>Document Details</h5>
        <hr />
        <div className='tableContainer'>
          <table className='upload-table'>
            <thead>
              <th>#</th>
              <th>Name</th>
              <th>Type</th>
              <th>Size</th>
              <th>Preview</th>
              <th>Actions</th>
            </thead>
            <tbody className='upload-table-body'>
              {data?.BookingEDocs?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item?.DocsFileName}</td>
                    <td>{item?.DocsDocType}</td>
                    <td>{formatFileSize(item?.DocsFileSize)}</td>
                    <td>
                      <PreviewIcon
                        onClick={() => {
                          setExtension(item?.DocsFileExtention)
                          let prom = downloadDocument({
                            DocumentPk: item?.DocPK,
                            BookingNumber: item?.BookingNumber
                          })
                          prom
                            .then(res => {
                              const downloadLink = document.createElement('a')
                              const prefix = genrateDownloadLink(
                                res?.data?.DocsFileName ?? ''
                              )
                              downloadLink.href = `${prefix}${
                                res?.data?.DocsFileData ?? ''
                              }`
                              let link = `${prefix}${res?.data?.DocsFileData}`
                              setDocLink(link)
                            })
                            .catch(err => {
                              console.log('err', err)
                            })
                            .finally(() => {
                              setOpen(true)
                            })
                        }}
                        style={{ cursor: 'pointer' }}
                      />
                    </td>
                    <td>
                      <Button
                        className=''
                        onClick={() => {
                          let tempLoading = { ...docLoading, download: true }
                          setDocLoading(tempLoading)
                          let prom = downloadDocument({
                            DocumentPk: item?.DocPK,
                            BookingNumber: item?.BookingNumber
                          })
                          prom
                            .then(res => {
                              const downloadLink = document.createElement('a')
                              const prefix = genrateDownloadLink(
                                res?.data?.DocsFileName ?? ''
                              )
                              downloadLink.href = `${prefix}${
                                res?.data?.DocsFileData ?? ''
                              }`

                              let link = `${prefix}${res?.data?.DocsFileData}`

                              if (prefix && res?.data?.DocsFileData) {
                                downloadLink.download =
                                  res?.data?.DocsFileName ?? ''
                                if (res?.data?.DocsFileExtention === 'pdf') {
                                  modifyPdf(link, res?.data?.DocsFileName)
                                }
                                if (res?.data?.DocsFileExtention !== 'pdf') {
                                  downloadLink.click()
                                }
                              } else {
                                toast.error('Invalid document found!')
                              }
                            })
                            .catch(err => {
                              console.log('err', err)
                            })
                            .finally(() => {
                              let tempLoading = {
                                ...docLoading,
                                download: false
                              }
                              setDocLoading(tempLoading)
                            })
                        }}
                      >
                        {docLoading?.download === true ? (
                          <CircularProgress size={'1rem'} />
                        ) : (
                          <FileDownloadOutlinedIcon />
                        )}{' '}
                      </Button>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default ViewBooking
