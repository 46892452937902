import DocumentApproval from './page'
import './document.css'
import { Outlet } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  resetOnUnmout,
  updateDocListBody
} from '../../redux/documentRedux/documentListSlice'
import { userType } from './utils/lib'
export default function DocumentApprovalLayout() {
  const dispatch = useDispatch()
  const getRoles = async () => {
    let type = ''
    let roles = await userType()
    if (roles.includes('Admin')) {
      type = 'FreightForwarder'
    } else {
      type = 'Approver'
    }
    dispatch(
      updateDocListBody({
        obj: 'userInfo',
        name: 'userRole',
        value: type
      })
    )
  }
  useEffect(() => {
    getRoles()
    return () => {
      dispatch(resetOnUnmout())
    }
  }, [])
  return (
    <>
      <DocumentApproval />
      <Outlet />
    </>
  )
}
