import React from 'react'
import { tooltipClasses } from '@mui/material/Tooltip';
import Tooltip from '@mui/material/Tooltip';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Divider } from '@mui/material';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <ClickAwayListener onClickAway={props.handleTooltipClose}>
    <Tooltip 
      PopperProps={{ disablePortal: true }}
      onClose={props.handleTooltipClose}
      open={props.open}
      {...props}
      classes={{ popper: className }}
    />
  </ClickAwayListener>
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    minWidth: 250,
    fontSize: '12px',
  },
});

const SortModalTooltip = () => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const tooltipContent = (
    <div>
      <Typography variant="subtitle2" component="div"><strong>Departure dates</strong></Typography>
      <Typography variant="body2" component="div">Pre Loaded state: ETD date</Typography>
      <Typography variant="body2" component="div">In - Transit state: ATD date</Typography>
      <Typography variant="body2" component="div">Arrived state: ATD date</Typography>
      <Typography variant="body2" component="div">Archive state: ATD date</Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="subtitle2" component="div"><strong>Arrival dates</strong></Typography>
      <Typography variant="body2" component="div">Pre Loaded state: ETA date</Typography>
      <Typography variant="body2" component="div">In - Transit state: ETA date</Typography>
      <Typography variant="body2" component="div">Arrived state: ATA date</Typography>
      <Typography variant="body2" component="div">Archive state: ATA date</Typography>
    </div>
  );

  return (
    <CustomWidthTooltip 
      title={tooltipContent}
      open={open}
      handleTooltipClose={handleTooltipClose}
      handleTooltipOpen={handleTooltipOpen}
    >
      <HelpOutlineIcon 
        onClick={handleTooltipOpen}
        onMouseOverCapture={handleTooltipOpen}
        sx={{ fontSize: '15px' }}
      />
    </CustomWidthTooltip>
  )
}

export default SortModalTooltip;
