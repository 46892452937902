import React, { useEffect, useState } from "react";
import "../User.css";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import { DateFormat, DateFormatWithtime } from "../../../utils/moment";
import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { styled } from "@mui/material/styles";
import { changeStatusApiCall } from "../userApi/userApiDefination";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import urlResolver from "../../../utils/urlResolver";
import EditIcon from "@mui/icons-material/Edit";
const UserTableRow = ({
  Admin_Portal_Action_Modules,
  setOpenAddEditDelete,
  setPropertiesAddEditDelete,
  setActiveUserAndAdminNumber,
  setOpen,
  data,
  item,
  index,
  sendResetLink,
  setLoadingReset,
  loadingReset,
  setModuleCaller,
}) => {

  const [switchStatus, setSwitchStatus] = useState(null);
  useEffect(() => {
    setSwitchStatus(item?.IsLoginEnabled);
  }, [item]);
  const [PauseTimmer, setPauseTimmer] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#65C466" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#a19f9c" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  useEffect(() => {
    setSwitchStatus(item?.IsLoginEnabled);
  }, [item]);
  return (
    <tr>
      <td className="m-0 usercol_data " title={item?.ContactName ?? ""}>
        <span> {item?.ContactName}</span>
      </td>
      <td className="m-0 usercol_data" title={item?.Email ?? ""}>
        <span>{item?.Email ?? ""}</span>
      </td>
      {item.OrgnisationCollection.length ? (
        <td className="m-0 usercol_data_orgnisation">
          <Tooltip
            title={item.OrgnisationCollection.map((i, ind) => {
              return (
                <Typography>
                  {i.OrgFullName ?? ""}
                  <br />
                </Typography>
              );
            })}
          >
            <span>{item?.OrgnisationCollection[0]?.OrgFullName ?? ""} </span>
          </Tooltip>
        </td>
      ) : (
        <td className="m-0 usercol_data_orgnisation"></td>
      )}
      <td className="m-0 usercol_data">
        {/* <Tooltip title={DateFormatWithtime(item?.LastLoginUtcDate)}> */}
        <p>{DateFormatWithtime(item?.LastLoginUtcDate) ?? null} </p>
        {/* </Tooltip> */}
      </td>
      <td
        className="m-0 usercol_data"
        title={DateFormat(item.RegisteredDate ?? null)}
      >
        <span>{DateFormat(item.RegisteredDate ?? null)}</span>
      </td>
      <td
        className="m-0 usercol_data d-flex align-items-center  gap-2 "
        style={{ borderBottomWidth: "0px", borderTop: "0px" }}
        title={item?.Roles ?? ""}
      >
        {data.map((Role) => {
          if (Role.length > 0) return <div className="admin-color">{Role}</div>;
        })}
      </td>

      <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <CustomSwitch
            checked={switchStatus}
            disabled={PauseTimmer || data.includes("Admin")}
            onChange={(event) => {
              setPauseTimmer(true);
              changeStatusApiCall(
                event?.target?.checked,
                item?.Email,
                setSwitchStatus,
                setActiveUserAndAdminNumber
              );
              setTimeout(() => {
                setPauseTimmer(false);
              }, 4000);
            }}
          />
        </div>
      </td>

      <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          {loadingReset === index ? (
            <div className="d-flex align-items-center justify-content-center">
              <LockResetIcon style={{ color: "#dddddd" }} />
            </div>
          ) : (
            <LockOutlinedIcon
              onClick={() => {
                sendResetLink(item?.Email ?? "");
                setLoadingReset(index);
              }}
            />
          )}
        </div>
      </td>

      {/* ACTION */}
      <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
        >
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {/* {Admin_Portal_Action_Modules && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate(Routeconstant.USER_Orgination_mapping, {
                    state: {
                      item,
                    },
                  });
                }}
              >
                <img
                  src={urlResolver(`assets/orgnizationMappingFill.png`)}
                  style={{ height: "22px" }}
                />
                Org. mapping
              </MenuItem>
            )} */}

            <MenuItem
              onClick={() => {
                handleClose();
                setOpen(true);
                setModuleCaller({
                  name: "Accessibility",
                  mailId: item?.Email,
                  userName: item?.ContactName,
                });
              }}
            >
              <ViewModuleIcon />
              Accessibility
            </MenuItem>
            
            {/* {Admin_Portal_Action_Modules && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpen(true);
                  setModuleCaller({
                    name: "Role",
                    mailId: item?.Email,
                    userName: item?.ContactName,
                  });
                }}
              >
                <ManageAccountsIcon />
                Role management
              </MenuItem>
            )}
            {Admin_Portal_Action_Modules && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenAddEditDelete(true);
                  setPropertiesAddEditDelete((prev) => {
                    return {
                      ...prev,
                      data: item,
                      sender: "editUser",
                      changeAllow: 0,
                    };
                  });
                }}
              >
                <EditIcon /> Edit
              </MenuItem>
            )}
            {Admin_Portal_Action_Modules && (
              <MenuItem
                onClick={() => {
                  handleClose();
                  setOpenAddEditDelete(true);
                  setPropertiesAddEditDelete((prev) => {
                    return {
                      ...prev,
                      data: item,
                      sender: "removeUser",
                      changeAllow: 1, //1 mean all are not allowed
                    };
                  });
                }}
              >
                <PersonRemoveIcon /> Delete
              </MenuItem>
            )} */}
          </Menu>

          <MoreVertIcon onClick={handleClick} />
        </div>
      </td>
      {/* edit */}
      {/* <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
          onClick={() => {
            setOpenAddEditDelete(true);
            setPropertiesAddEditDelete((prev) => {
              return {
                ...prev,
                data: item,
                sender: "editUser",
                changeAllow: 0,
              };
            });
          }}
        >
          <ManageAccountsIcon />
        </div>
      </td> */}
      {/* delete */}
      {/* <td className="user_btnn">
        <div
          style={{
            marginLeft: "35px",
            color: "var(--color-secondary-dark)",
            cursor: "pointer",
          }}
          className="d-flex align-items-center justify-content-center"
          onClick={() => {
            setOpenAddEditDelete(true);
            setPropertiesAddEditDelete((prev) => {
              return {
                ...prev,
                data: item,
                sender: "removeUser",
                changeAllow: 1, //1 mean all are not allowed
              };
            });
          }}
        >
          <PersonRemoveIcon />
        </div>
      </td> */}
    </tr>
  );
};

export default UserTableRow;
