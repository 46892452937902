import ReviewsIcon from '@mui/icons-material/Reviews'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  updateDocListBody,
  updateStatus
} from '../../../../redux/documentRedux/documentListSlice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { DateFormat } from '../../../../utils/moment'
import { status } from '../../utils/lib'
export default function TableRow({ row }) {
  const dispatch = useDispatch()
  const {
    status: { isLoading }
  } = useSelector(state => state.documentApproval)
  const navigate = useNavigate()
  const handleDialogueOpen = parentId => {
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'currentParentId',
        value: parentId
      })
    )
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'isRemarksOpen',
        value: true
      })
    )
  }
  const handleReject = parentId => {
    handleDialogueOpen(parentId)
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'isReject',
        value: true
      })
    )
    dispatch(
      updateDocListBody({
        obj: 'remarks',
        objName: null,
        name: 'currentPk',
        value: row?.PK
      })
    )
  }
  const handleAccept = () => {
    dispatch(
      updateStatus({ pk: row?.PK, status: 1, shipmentid: row?.ParentId })
    )
  }
  const Actions = ({ status }) => {
    switch (status) {
      case 0:
        return (
          <>
            <IconButton
              disabled={isLoading}
              size='small'
              color='primary'
              onClick={handleAccept}
              style={{
                padding: 0
              }}
            >
              <CheckCircleIcon />
            </IconButton>
            <IconButton
              disabled={isLoading}
              onClick={() => {
                handleReject(row.ParentId)
              }}
              size='small'
              color='secondary'
              style={{
                padding: 0,
                marginLeft: '1rem'
              }}
            >
              <CancelIcon />
            </IconButton>
          </>
        )
      case 1:
        return <>Accepted</>
      case 2:
        return <>Rejected</>
      default:
        return <></>
    }
  }
  const LatestUpdate = ({ status }) => {
    switch (status) {
      case 0:
        return <>{`${'     '}-`}</>
      case 1:
        return <>{DateFormat(row?.DateOfApprove)}</>
      case 2:
        return <>{DateFormat(row?.DateOfDisApprove)}</>
      default:
        return <></>
    }
  }
  console.log('rowwww', row)
  return (
    <tr>
      <td>{row?.ParentId}</td>
      <td>{row?.DocType}</td>
      <td>{row?.FileName}</td>
      <td>{DateFormat(row?.SystemCreateDateTimeUtc)}</td>
      <td>{status(row.Status)}</td>
      <td>
        {' '}
        <LatestUpdate status={row?.Status} />
      </td>
      <td
        style={{
          textAlign: 'center'
        }}
      >
        <IconButton
          disabled={isLoading}
          size='small'
          color='primary'
          onClick={() => {
            navigate(`view/${row?.PK}__${row?.ParentId}`)
          }}
          style={{
            padding: 0
          }}
        >
          <RemoveRedEyeIcon />
        </IconButton>
      </td>
      <td
        style={{
          textAlign: 'center'
        }}
      >
        <IconButton
          size='small'
          color='primary'
          style={{
            padding: 0
          }}
          onClick={() => {
            dispatch(
              updateDocListBody({
                obj: 'remarks',
                objName: null,
                name: 'currentPk',
                value: row?.PK
              })
            )
            handleDialogueOpen(row.ParentId)
          }}
        >
          <ReviewsIcon />
        </IconButton>
      </td>
      <td className={isLoading ? 'rowLoading' : 'rowNoLoading'}>
        <Actions status={row?.Status} />
      </td>
    </tr>
  )
}
