import { Grid, Typography } from "@mui/material";
import moment from "moment/moment";
import { useSelector } from "react-redux";

const Right = () => {
  const {
    order: { orderNumber, supplier, buyer, orderDetails },
  } = useSelector((state) => state);
  const data = useSelector((state) => state);
  let NA = "N/A";
  const formatDate = (date) => {
    return moment(new Date(date)).format("D MMMM  YYYY");
  };

  return (
    <Grid
      container
      spacing={1}
      sx={{
        height: "fit-content",
        overflow: "auto",
      }}
    >
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Order Date </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {orderNumber?.orderDate ? formatDate(orderNumber?.orderDate) : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Order Status </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>:1254</Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Receiving Agent </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {orderNumber?.receivingAgent?.Code
            ? `${orderNumber?.receivingAgent?.Code}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Supplier Address </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          {" "}
          :{" "}
          {supplier?.supplierAddress?.Address1
            ? `${supplier?.supplierAddress?.Address1}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Buyer Address </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {buyer?.buyerAddress?.Address1
            ? `${buyer?.buyerAddress?.Address1}`
            : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>INCO Terms </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          :{" "}
          {`${
            orderDetails?.incoTerm?.Description ?? orderDetails?.incoTerm?.Code
          }`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Transport Mode </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderDetails?.transportMode?.Description}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Req. In Store </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          {" "}
          : {orderDetails?.reqStore ? formatDate(orderDetails?.reqStore) : NA}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Shipment No. </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          {" "}
          : {`${orderDetails?.shipmentNumber}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Origin Country </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}>
          : {`${orderDetails?.originCountry}`}
        </Typography>
      </Grid>
      <Grid item lg={5} xs={5}>
        <Typography color={"primary"}>Special Instructions </Typography>
      </Grid>
      <Grid item lg={7} xs={7}>
        <Typography color={"secondary"}></Typography>
      </Grid>
    </Grid>
  );
};
export default Right;
