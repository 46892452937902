import React, { useEffect, useState } from "react";
import ShipmentDetails from "../components/ShipmentDetails";
import CargoDetails from "../components/CargoDetails";
import Documentation from "../components/Documentation";
import Routing from "../components/Routing";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "../shipment.css";
import { useLocation, useNavigate } from "react-router";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Routeconstant } from "../../../routing/utils/RouteConstant";
import { DateFormat } from "../../../utils/moment";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import instance from "../../../api/Interceptor";
import { API_PATH } from "../../../constant/apiPath";
import Tooltip from "@mui/material/Tooltip";
import { basename, customerName } from "../../../Config";
import urlResolver from "../../../utils/urlResolver";
import { toast } from "react-hot-toast";
import { clsx } from "clsx";
const ShipmentDetailsListView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [shipmentDetails, setShipmentDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [tabindex, setTabindex] = useState(0);
  const [whatsappNumber, setWhatsappNumber] = useState("");
  const [emailContact, setemailContact] = useState("");
  const [loadingStar, setLoadingStar] = useState(false);
  const [starStatus, setStarStatus] = useState(false);
  const getEmailContacts = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_EMAIL_CONTACTS, body)
      .then((res) => {
        setemailContact(res.data[0].EmailId);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getWhatsappNumber = () => {
    const body = {
      ShipmentId: location?.state?.ShipmentId,
      ShowDeveloperResponse: true,
    };
    instance
      .post(API_PATH.GET_WHATSAPP_NUMBER, body)
      .then((res) => {
        setWhatsappNumber(res.data[0].ContactNumber);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const markFavourite = (isFavourite) => {
    setLoadingStar(true);
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${location?.state?.ShipmentId}&IsFavourite=${isFavourite}`
      )
      .then((res) => {
        toast.success(res?.message?.Message ?? "");
        setStarStatus(isFavourite);
      })
      .catch((err) => {
        toast.success(err ?? "");
        setStarStatus(!isFavourite);
      })
      .finally(() => {
        setLoadingStar(false);
      });
  };
  const getShipmentDetails = () => {
    setLoading(true);
    const body = {
      ShipmentId: location?.state?.ShipmentId ?? "",
      TransportMode: "",
      ContainerType: "",
      Carrier: "",
      HouseBillNumber: "",
      Consignee: "",
      Consignor: "",
      OrderNumber: "",
      MasterBillNumber: "",
      ETAFrom: "",
      ETATo: "",
      ETDFrom: "",
      ETDTo: "",
      ATDFrom: "",
      ATDTo: "",
      ATAFrom: "",
      ATATo: "",
      EmailID: "",
      ShowDeveloperResponse: true,
      DateFrom: "",
      DateTo: "",
      Status: "",
      CurrentPage: 0,
      PageSize: 0,
      SortColumnName: "",
      SortDirection: "",
    };
    instance
      .post(API_PATH.GET_SHIPMENT_DETAILS, body)
      .then((res) => {
        // setLoading(false);
        setShipmentDetails(res?.data[0] ?? {});
        setStarStatus(res?.data[0]?.IsFavourite);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const sendMail = () => {
    let body = `Hello, %0A%0ATeam ${customerName}, %0A%0ACould you please send more details about my shipment? %0A%0AShipment Number: ${
      location?.state?.ShipmentId ?? ""
    } %0AHouse BL Number: ${
      shipmentDetails?.HouseBillNumber ?? ""
    } %0ATransport Mode: ${
      shipmentDetails?.TransportMode ?? ""
    } %0A%0AThank you! %0A%0A`;
    window.location.href = `mailto:${emailContact}?subject=Shipment ID - ${
      location?.state?.ShipmentId ?? ""
    }&body=${body}`;
  };
  useEffect(() => {
    getShipmentDetails();
    getWhatsappNumber();
    getEmailContacts();
  }, [location.state.ShipmentId]);
  // console.log("shipmentDetails", shipmentDetails);
  if (Object.keys(shipmentDetails).length === 0)
    return (
      <div className="container-fluid">
        <div className="row">
          {loading ? (
            <div></div>
          ) : (
            <>
              <div
                className="mb-3"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <KeyboardBackspaceIcon /> Back
              </div>
              <div className="d-flex justify-content-center  align-items-center mt-4">
                <p className="font-change">No data found!</p>
              </div>
            </>
          )}
        </div>
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="mb-3">
          <span style={{ cursor: "pointer" }} onClick={() => navigate(-1)}>
            <KeyboardBackspaceIcon /> Back
          </span>
        </div>
      </div>
      <div className="row">
        <div
          className="col-12  tab-text-data"
          title={shipmentDetails.ShipmentId ?? "N/A"}
        >
          <span>{shipmentDetails.ShipmentId ?? "N/A"}</span>
        </div>
      </div>
      <div className="row d-flex justify-content-between mb-3">
        {/* destinaiton origin and etd eta details */}
        <div className="col-8 d-flex justify-content-between flex-wrap ps-0 pe-0">
          <div>
            <div
              // style={{
              //   fontSize: "var(--fs-mid)",
              //   fontWeight: "500",
              //   lineHeight: "30px",
              // }}
              className="d-flex justify-content-start align-items-start head-listview"
            >
              <PlaceOutlinedIcon /> Origin :{" "}
              {shipmentDetails?.OriginPortName ?? "N/A"}
              {`(${shipmentDetails?.OriginPortCode})`}
            </div>
            <div
              // style={{ fontSize: "var(--fs-mid)", fontWeight: "500" }}
              className="d-flex justify-content-start align-items-center head-listview"
            >
              <PlaceOutlinedIcon /> Destination :{" "}
              {shipmentDetails?.DestinationPortName ?? "N/A"}
              {`(${shipmentDetails?.DestinationPortCode})`}
            </div>
          </div>
          <div>
            <div
              // style={{ fontSize: "var(--fs-mid)", fontWeight: "500" }}
              className="d-flex justify-content-start align-items-start head-listview"
            >
              <CalendarMonthOutlinedIcon /> ETD :{" "}
              {DateFormat(shipmentDetails?.ETDDate ?? null)}
            </div>
            <div
              // style={{ fontSize: "var(--fs-mid)", fontWeight: "500" }}
              className="d-flex justify-content-start align-items-start head-listview"
            >
              <CalendarMonthOutlinedIcon /> ETA :{" "}
              {DateFormat(shipmentDetails.ETADate ?? null)}
            </div>
          </div>
        </div>

        {/* icons code  */}
        <div
          className=" col-4 d-flex justify-content-end align-items  "
          style={{ minWidth: "12%", gap: "1em" }}
        >
          <Tooltip title="Favorite">
            <img
              style={{
                cursor: "pointer",
                marginTop: "0.31em",
                height: "22px",
              }}
              className={clsx("img-star", loadingStar && "flash")}
              // src={urlResolver("assets/coloredStartfordetials.svg")}
              src={urlResolver(
                `assets/${
                  starStatus ? "coloredStartfordetials.svg" : "star.svg"
                }`
              )}
              onClick={() => {
                markFavourite(!starStatus);
              }}
            />
          </Tooltip>

          <Tooltip title="Mail">
            <MailIcon className="layout-notification" onClick={sendMail} />
          </Tooltip>
          <Tooltip title="Whatsapp">
            <a
              href={`https://web.whatsapp.com/send?phone=${whatsappNumber}`}
              target="_blank"
            >
              <WhatsAppIcon className="layout-notification" />
            </a>
          </Tooltip>
        </div>
      </div>
      <div
        className="row tabrows"
        style={{
          backgroundColor: "var(--color-white)",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <div
          className={
            tabindex === 0
              ? "col-1 tab-text tab-text-active"
              : "col-1 tab-text "
          }
          onClick={() => {
            setTabindex(0);
          }}
        >
          Basic Details
        </div>
        <div
          className={
            tabindex === 1
              ? "col-1 tab-text tab-text-active"
              : "col-1 tab-text "
          }
          onClick={() => {
            setTabindex(1);
          }}
        >
          Route details
        </div>
        <div
          className={
            tabindex === 2
              ? "col-1 tab-text tab-text-active"
              : "col-1 tab-text "
          }
          style={{ paddingLeft: "0.2rem" }}
          onClick={() => {
            setTabindex(2);
          }}
        >
          Cargo Details
        </div>
        <div
          className={
            tabindex === 3
              ? "col-1 tab-text tab-text-active"
              : "col-1 tab-text "
          }
          onClick={() => {
            setTabindex(3);
          }}
        >
          Documentation
        </div>
      </div>
      <div className="row d-flex align-items-center justify-content-center">
        {tabindex === 0 ? (
          <ShipmentDetails
            shipmentDetails={shipmentDetails}
            loading={loading}
          />
        ) : tabindex === 1 ? (
          <Routing shipmentDetails={shipmentDetails} />
        ) : tabindex === 2 ? (
          <CargoDetails TransportMode={shipmentDetails.TransportMode} />
        ) : tabindex === 3 ? (
          <Documentation />
        ) : null}
      </div>
    </div>
  );
};

export default ShipmentDetailsListView;
