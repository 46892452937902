import React, { useState } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  DialogActions
} from '@mui/material'
import urlResolver from '../../utils/urlResolver'
import '../../screens/shipment/shipment.css'
import EastIcon from '@mui/icons-material/East'
import WestIcon from '@mui/icons-material/West'
import SouthIcon from '@mui/icons-material/South'
import NorthIcon from '@mui/icons-material/North'
import { useEffect } from 'react'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { TableBookColumns } from '../../utils/TableBookColumns'
import { useNavigate } from 'react-router-dom'
const TableBookingColumn = ({
  tableModal,
  setTableModal,
  updateTableCols,
  tableCopy,
  setTableCopy,
}) => {
  const [fullWidth, setFullWidth] = useState(true)
  const [maxWidth, setMaxWidth] = useState('md')
  const [showCopy, setShowCopy] = useState([])
  const [availableCopy, setAvailableCopy] = useState([])
  const [searchvalue, setSearchValue] = useState('')
  const navigate=useNavigate()
  const HandleOnClose = () => {
    setTableModal(false)
  }

  const HandleOnSave = () => {
    const arr = [...showCopy, ...availableCopy]
    updateTableCols(arr)
    setTableCopy([...arr])
    HandleOnClose()
    navigate("/booking")
  }

  const DivideColumn = tableCopy => {
    let IsVisible = []
    let IsAvailable = []
    tableCopy.map((item, index) => {
      if (item.IsVisible) {
        IsVisible.push(item)
      } else {
        IsAvailable.push(item)
      }
    })
    setAvailableCopy(IsAvailable)
    setShowCopy(IsVisible)
  }
  const AddColumn = () => {
    const arr = [...availableCopy]
    const tempShowCopy = [...showCopy]
    const result = arr
      .map(item => {
        if (item.IsSelected) {
          tempShowCopy.push({ ...item, IsSelected: false, IsVisible: true })
        } else {
          return item
        }
      })
      .filter(Boolean)
    setAvailableCopy(result)
    setShowCopy([...tempShowCopy])
  }
  const RemoveColumn = () => {
    const arr = [...availableCopy]
    const tempShowCopy = [...showCopy]
    const result = tempShowCopy
      .map(item => {
        if (item.IsSelected) {
          arr.push({ ...item, IsSelected: false, IsVisible: false })
        } else {
          return item
        }
      })
      .filter(Boolean)
    setAvailableCopy([...arr])
    setShowCopy([...result])
  }

  // useEffect(() => {
  //   DivideColumn(tableCopy)
  // }, [tableCopy])
  useEffect(() => {
    const filteredCopy = tableCopy.filter(
      column => column.DataKey !== 'StatusDescription'
    )
    DivideColumn(filteredCopy)
  }, [tableCopy])

  const HandleSortUp = () => {
    const arr = [...showCopy]
    showCopy.map((item, index) => {
      if (item.IsSelected && index !== 0) {
        const tempCurrent = arr[index]
        const tempNext = arr[index - 1]
        arr[index] = tempNext
        arr[index - 1] = tempCurrent
      }
    })
    setShowCopy(arr)
  }

  const HandleSortDown = () => {
    const arr = [...showCopy]
    showCopy.map((item, index) => {
      if (item.IsSelected && index !== showCopy.length - 1) {
        const tempCurrent = arr[index]
        const tempNext = arr[index + 1]
        arr[index] = tempNext
        arr[index + 1] = tempCurrent
      }
    })
    setShowCopy(arr)
  }
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={tableModal}
      onClose={HandleOnClose}
      aria-labelledby='responsive-dialog-title'
      className='Dialog-section'
    >
      <div
        className='headersearch searchShipmentDiv '
        style={{
          border: ' 1px solid #e9ecef',
          borderRadius: '8px',
          width: '35%',
          marginLeft: '22px'
        }}
      >
        <input
          type='text'
          className='form-control '
          placeholder='Search'
          onChange={e => setSearchValue(e.target.value)}
          value={searchvalue}
        />

        <img
          src={urlResolver('assets/search_icon.svg')}
          style={{ color: 'rgba(var(--text-heading-dark))' }}
          className='searchIcon'
        />
        <CloseOutlinedIcon
          style={{
            position: 'absolute',
            right: '0.5rem',
            cursor: 'pointer'
          }}
          className='searchClearIcon'
          onClick={() => {
            setSearchValue('')
          }}
        />
      </div>

      <DialogContent style={{ padding: '16px 16px 0px 16px' }}>
        <Grid container spacing={1}>
          <Grid
            item
            lg={4.2}
            md={12}
            sm={12}
            xs={12}
            style={{ padding: '0px', marginLeft: '21px' }}
          >
            <p
              className='mb-1 font-change '
              style={{
                fontFamily: ' var(--family-label)',
                fontStyle: 'var(--family-style)',
                fontWeight: '500',
                color: 'var(--color-secondary-dark)',
                fontSize: 'var(--fs-subtitle)'
              }}
            >
              Available column
            </p>
            <ul
              className='list-section'
              style={{
                padding: '2px',
                border: '1px solid #dee2e6',
                backgroundColor: '#f8f9fa',
                overflowY: 'auto',
                height: '45vh',
                marginBottom: '0px'
              }}
            >
              {availableCopy
                .filter(item =>
                  item.ColumnName.toUpperCase().includes(
                    searchvalue.toUpperCase()
                  )
                )
                .map((item, index) => {
                  return (
                    <li
                      className={item.IsSelected ? 'highlight' : 'no-highlight'}
                      style={{
                        fontFamily: ' var(--family-label)',
                        fontStyle: 'var(--family-style)',
                        fontSize: 'var(--fs-lg)'
                      }}
                      onClick={() => {
                        const arr = [...availableCopy]
                        let obj = { ...arr[index] }
                        obj = { ...obj, IsSelected: !obj.IsSelected }
                        arr[index] = obj
                        setAvailableCopy(arr)
                        // ---Select change----
                        const tempArr = [...showCopy]
                        showCopy.map((item, index) => {
                          if (item.IsSelected) {
                            let tempObj = { ...tempArr[index] }
                            tempObj = { ...tempObj, IsSelected: false }
                            tempArr[index] = tempObj
                          }
                        })
                        setShowCopy(tempArr)
                      }}
                    >
                      {item.ColumnName}
                    </li>
                  )
                })}
            </ul>
          </Grid>
          <Grid
            item
            lg={2.5}
            md={12}
            sm={12}
            xs={12}
            style={{ padding: '29px 15px 0px 15px' }}
          >
            <div className='d-flex justify-content-between  flex-column '>
              <div
                className='d-flex justify-content-center flex-column '
                style={{ gap: 10 }}
              >
                <Button
                  className='font-change'
                  variant='outlined'
                  endIcon={
                    window.innerWidth > 1200 ? (
                      <EastIcon />
                    ) : (
                      <ArrowDownwardIcon />
                    )
                  }
                  fullWidth
                  onClick={() => {
                    AddColumn()
                  }}
                >
                  Add
                </Button>
                <Button
                  className='font-change'
                  variant='outlined'
                  startIcon={
                    window.innerWidth > 1200 ? (
                      <WestIcon />
                    ) : (
                      <ArrowUpwardIcon />
                    )
                  }
                  fullWidth
                  onClick={() => {
                    RemoveColumn()
                  }}
                >
                  Remove
                </Button>
              </div>
              <div>
                <Button
                  className='font-change'
                  style={{ marginTop: '3rem' }}
                  variant='outlined'
                  fullWidth
                  onClick={() => {
                    DivideColumn(TableBookColumns)
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Grid>
          <Grid
            item
            lg={4.2}
            md={10}
            sm={10}
            xs={10}
            sx={{
              paddingLeft: {
                md: '100px'
              }
            }}
          >
            <p
              className='mb-1 font-change'
              style={{
                fontFamily: ' var(--family-label)',
                fontStyle: 'var(--family-style)',
                fontWeight: '500',
                color: 'var(--color-secondary-dark)',
                fontSize: 'var(--fs-subtitle)'
              }}
            >
              Show columns in this order
            </p>
            <ul
              className='list-section'
              style={{
                padding: '2px',
                border: '1px solid #dee2e6',
                backgroundColor: '#f8f9fa',
                overflowY: 'auto',
                height: '45vh',
                marginBottom: '0px'
              }}
            >
              {showCopy
                .filter(item =>
                  item.ColumnName.toUpperCase().includes(
                    searchvalue.toUpperCase()
                  )
                )
                .map((item, index) => {
                  return (
                    <li
                      className={item.IsSelected ? 'highlight' : 'no-highlight'}
                      style={{
                        fontFamily: ' var(--family-label)',
                        fontStyle: 'var(--family-style)',
                        fontSize: 'var(--fs-lg)'
                      }}
                      onClick={() => {
                        const arr = [...showCopy]
                        let obj = { ...arr[index] }
                        obj = { ...obj, IsSelected: !obj.IsSelected }
                        arr[index] = obj
                        setShowCopy(arr)
                        // ---select_Change---
                        const tempArr = [...availableCopy]
                        availableCopy.map((item, index) => {
                          if (item.IsSelected) {
                            let tempObj = { ...tempArr[index] }
                            tempObj = { ...tempObj, IsSelected: false }
                            tempArr[index] = tempObj
                          }
                        })
                        setAvailableCopy(tempArr)
                      }}
                    >
                      {item.ColumnName}
                    </li>
                  )
                })}
            </ul>
          </Grid>
          <Grid
            item
            lg={0.8}
            md={2}
            sm={2}
            xs={2}
            style={{
              padding: '29px 0px  0px 15px',
              display: 'flex',
              gap: 10,
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Button
              onClick={() => {
                HandleSortUp()
              }}
              variant='outlined'
              style={{
                padding: '0',
                width: '40%',
                minWidth: 'fit-content',
                height: '20%'
              }}
            >
              <NorthIcon />
            </Button>

            <Button
              onClick={() => {
                HandleSortDown()
              }}
              variant='outlined'
              style={{
                padding: '0',
                width: '40%',
                minWidth: 'fit-content',
                height: '20%'
              }}
            >
              <SouthIcon />
            </Button>
          </Grid>
        </Grid>

        <DialogActions style={{ padding: '16px 6px 0px 6px' }}>
          <Button className='font-change' onClick={HandleOnSave} autoFocus>
            Save
          </Button>
          <Button className='font-change' autoFocus onClick={HandleOnClose}>
            Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
export default TableBookingColumn
