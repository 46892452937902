import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Card from '../../components/card/Card'
import LayoutShipments from '../../components/layout/LayoutShipments'
import { basename } from '../../Config'
import instance from '../../api/Interceptor'
import { API_PATH } from '../../constant/apiPath'
import BtnOutline from '../../components/button/BtnOutline'
import { Routeconstant } from '../../routing/utils/RouteConstant'
import './shipment.css'
import { AuthContext } from '../../store/context/AuthProvider'
import { CircularProgress, IconButton, Skeleton, Stack } from '@mui/material'
import ShipmentDetailsListView from './listview/ShipmentDetailsListView'
import FilterModalShipment from '../../components/model/FilterModalShipment'
import { toast } from 'react-hot-toast'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import SortModalShipment from '../../components/model/SortModalShipment'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import moment from 'moment/moment'
import urlResolver from '../../utils/urlResolver'
import CustomTooltip from '../../utils/CustomTooltip'
// import Tableindex from "./listview/Tableindex";
import { lazy, Suspense } from 'react'
import Tableindex from './listview/Tableindex'
// const Tableindex = lazy(() => import("./listview/Tableindex"));
const Index = () => {
  const sortColumnCopy = useRef(null)
  const [status, setStatus] = useState('')
  const [sortmodal, setSortmodal] = useState(false)
  const [modalShipment, setModalShipment] = useState(false)
  const [searchedClicked, setSearchedClicked] = useState(false)
  const [sortColumn, setSortColumn] = useState('')
  const [sortDirection, setSortDirection] = useState('')
  const [search, setSearch] = useState('')
  const [totalcount, setTotalcount] = useState(0)
  const [countryList, setCountryList] = useState([])
  const crossButtonClick = useRef(false)
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    TransportMode: "",
    ContainerType: "",
    Origin: "",
    Destination: "",
    Carrier: "",
    OriginPortName: "",
    DestinationPortName: "",
    Consignor: "",
    Consignee: "",
    OrderNumber: "",
    MasterBill: "",
    FromETA: null,
    ToETA: null,
    ToETD: null,
    FromETD: null,
    FromATA: null,
    ToATA: null,
    FromATD: null,
    ToATD: null
  })
  function isObject(value) {
    return typeof value === 'object' && value !== null && !Array.isArray(value)
  }
  const filterObj = useRef({})
  const sortFilterObj = useRef({
    sortColumn: '',
    sortDirection: ''
  })
  const [checkActive, setCheckActive] = useState(null)
  const location = useLocation()
  const [currentPage, setCurrentPage] = useState(0)
  const [loadingOnSearch, setLoadingOnSearch] = useState(false)
  const [searchValidation, setSearchValidation] = useState(true)
  const [loading, setLoading] = useState({
    initial: false,
    loadmore: false
  })

  const [checkFirst, setCheckFirst] = useState(true)
  const context = useContext(AuthContext)

  // console.log("contextShipment", context.shipmentCollection);
  // on reload push back to listing path
  // const checkReloadPageStatus = ()=>{
  //   if(location.pathname.split("/").at(-1)  !== "listing" && checkActive === null){
  //     navigate(Routeconstant.SHIPMENT_LISTING)
  //   }
  // }
  // useEffect(()=>{
  //   checkReloadPageStatus()
  // },[])

  const inputValidation = input => {
    const regex = new RegExp('^[^<>()*]*$')
    return regex.test(input)
  }
  // console.log("Search", search, inputValidation(search));
  const getAllCountry = () => {
    instance
      .get(API_PATH.GET_LISTOF_COUNTRY)
      .then(res => {
        setCountryList(res.data)
      })
      .catch(err => {
        console.log(err, 'err')
      })
      .finally(() => {})
  }
  useEffect(() => {
    if (context?.shipmentId?.length === 0) {
      // getShipmentCollection(0, status);
      getAllCountry()
    }
    getShipmentCollection(0, status)
    setCheckFirst(false)
  }, [])

  useEffect(() => {
    setCheckActive(null)
  }, [status])

  const getShipmentCollection = (pagenumber, status, length = 10) => {
    if (pagenumber === 0 && checkFirst) {
      setLoading({ ...loading, initial: true })
    } else {
      setLoading({ ...loading, loadmore: true })
    }
    const sortingColoumName =
      sortFilterObj?.current?.sortColumn.length > 0
        ? sortFilterObj?.current?.sortColumn
        : sortColumnCopy.current?.columnName ?? ''
    const sortingdirection =
      sortFilterObj?.current?.sortDirection.length > 0
        ? sortFilterObj?.current?.sortDirection
        : sortColumnCopy.current?.columndir ?? ''
    const body = {
      Search: crossButtonClick.current ? ' ' : search.trim() ?? '',
      ShipmentId: '',
      TransportMode: filterObj.current?.TransportMode ?? '',
      ContainerType: filterObj.current?.ContainerType ?? '',
      OriginCode: filterObj.current?.Origin ?? '',
      DestinationCode: filterObj.current?.Destination ?? '',
      Carrier: filterObj.current?.Carrier ?? '',
      HouseBillNumber: '',
      Consignee: filterObj.current?.Consignee ?? '',
      Consignor: filterObj.current?.Consignor ?? '',
      OrderNumber: filterObj.current?.OrderNumber ?? '',
      MasterBillNumber: filterObj.current?.MasterBill ?? '',
      ETAFrom: filterObj.current?.FromETA
        ? moment(new Date(filterObj.current?.FromETA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETATo: filterObj.current?.ToETA
        ? moment(new Date(filterObj.current?.ToETA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETDFrom: filterObj.current?.FromETD
        ? moment(new Date(filterObj.current?.FromETD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ETDTo: filterObj.current?.ToETD
        ? moment(new Date(filterObj.current?.ToETD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATDFrom: filterObj.current?.FromATD
        ? moment(new Date(filterObj.current?.FromATD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATDTo: filterObj.current?.ToATD
        ? moment(new Date(filterObj.current?.ToATD)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATAFrom: filterObj.current?.FromATA
        ? moment(new Date(filterObj.current?.FromATA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : '',
      ATATo: filterObj.current?.ToATA
        ? moment(new Date(filterObj.current?.ToATA)).format(
            'yyyy-MM-DDT18:30:00.000Z'
          )
        : "",
      DestinationPortName:filterObj.current?.DestinationPortName ?? "",
      OriginPortName:filterObj.current?.OriginPortName ?? "",
      EmailID: "",
      ShowDeveloperResponse: false,
      DateFrom: '',
      DateTo: '',
      Status: status ?? '',
      CurrentPage: pagenumber ?? 0,
      PageSize: length,
      SortColumnName: sortingColoumName,
      SortDirection: sortingdirection
    }
    instance
      .post(API_PATH.GET_SHIPMENT_COLLECTION, body)
      .then(res => {
        setLoading({ ...loading, initial: false, loadmore: false })
        setTotalcount(res?.totalCount ?? 0)
        if (status === '') {
          if (pagenumber === 0) {
            context.setShipmentCollection(res.data)
          } else {
            context.setShipmentCollection([
              ...context.shipmentCollection,
              ...res.data
            ])
          }
        } else if (status === '1') {
          if (pagenumber === 0) {
            context.setShipmentCollection(res.data)
            context.setPreloaded(res.data)
          } else {
            context.setShipmentCollection([
              ...context.shipmentCollection,
              ...res.data
            ])
            context.setPreloaded([...context.preloaded, ...res.data])
          }
        } else if (status === '2') {
          if (pagenumber === 0) {
            context.setShipmentCollection(res.data)

            context.setLoaded(res.data)
          } else {
            context.setShipmentCollection([
              ...context.shipmentCollection,
              ...res.data
            ])
            context.setLoaded([...context.loaded, ...res.data])
          }
        } else if (status === '3') {
          if (pagenumber === 0) {
            context.setShipmentCollection(res.data)
            context.setArrived(res.data)
          } else {
            context.setShipmentCollection([
              ...context.shipmentCollection,
              ...res.data
            ])
            context.setArrived([...context.arrived, ...res.data])
          }
        } else if (status === '4') {
          if (pagenumber === 0) {
            context.setShipmentCollection(res.data)
            context.setArchive(res.data)
          } else {
            context.setShipmentCollection([
              ...context.shipmentCollection,
              ...res.data
            ])
            context.setArchive([...context.archive, ...res.data])
          }
        }
        setLoadingOnSearch(false)
      })
      .catch(err => {
        console.log(err)
        // setLoadingStar(false);
        setLoadingOnSearch(false)
      })
      .finally(() => {
        setLoading({ ...loading, initial: false, loadmore: false })
        crossButtonClick.current = false
      })
  }

  //Update

  //End Update

  const markFavourite = (shipmentId, isFavourite, setLoadingStar) => {
    setLoadingStar(true)
    instance
      .post(
        `${API_PATH.MARK_FAVOURITE}ShipmentId=${shipmentId}&IsFavourite=${isFavourite}`
      )
      .then(res => {
        // let result = context.shipmentCollection.findIndex(
        //   (ordNum) => ordNum.ShipmentId == shipmentId
        // );
        // console.log("result", result);
        // context.shipmentCollection[result].IsFavourite =
        //   !context.shipmentCollection[result].IsFavourite;
        // toast.success(res?.message?.Message ?? "");
        // getShipmentCollection(0, status, setLoadingStar)
        context.shipmentCollection = context.shipmentCollection.map(shipment => {
          if (shipment.ShipmentId === shipmentId) {
            shipment.IsFavourite = isFavourite
          }
          return shipment
        })
        setLoadingStar(false)
      })
      .catch(err => {
        toast.success(err ?? '')
      })
      .finally(() => {
        setLoadingStar(false)
      })
  }

  const updateShipmentCollection = (prevState) => {
    return prevState.map(shipment =>
      shipment.ShipmentId === context.shipmentId
        ? {
            ...shipment,
            CarbonEmissionAmount: context.mass.amount,
            CarbonEmissionUnit: context.mass.unit
          }
        : shipment
    );
  };
  
  useEffect(() => {
    if (context.shipmentId && context.mass.amount && context.mass.unit) {
      context.setShipmentCollection(prevState => 
        updateShipmentCollection(prevState)
      );
  
      if (status === '1') {
        context.setPreloaded(prevState => 
          updateShipmentCollection(prevState)
        );
      } else if (status === '2') {
        context.setLoaded(prevState => 
          updateShipmentCollection(prevState)
        );
      } else if (status === '3') {
        context.setArrived(prevState => 
          updateShipmentCollection(prevState)
        );
      } else if (status === '4') {
        context.setArchive(prevState => 
          updateShipmentCollection(prevState)
        );
      }
    }
  }, [context.mass.amount, context.mass.unit]);
  

  // console.log(JSON.stringify(context.shipmentCollection),"shipment123");
  // console.log('Mass', context.mass)
  // console.log('Shipment', context.shipmentId)

  return (
    <>
      {sortmodal ? (
        <FilterModalShipment
          setSortmodal={setSortmodal}
          sortmodal={sortmodal}
          setFilter={setFilter}
          filter={filter}
          status={status}
          getShipmentCollection={getShipmentCollection}
          setStatus={setStatus}
          setCurrentPage={setCurrentPage}
          countryList={countryList}
          setLoadingOnSearch={setLoadingOnSearch}
          setCheckActive={setCheckActive}
          filterObj={filterObj}
        />
      ) : null}
      {modalShipment ? (
        <SortModalShipment
          setModalShipment={setModalShipment}
          modalShipment={modalShipment}
          sortColumn={sortColumn}
          setSortColumn={setSortColumn}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          setLoadingOnSearch={setLoadingOnSearch}
          status={status}
          getShipmentCollection={getShipmentCollection}
          setCheckActive={setCheckActive}
          sortFilterObj={sortFilterObj}
          setCurrentPage={setCurrentPage}
        />
      ) : null}
      <LayoutShipments
        sortColumn={sortColumn}
        setSortColumn={setSortColumn}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        setFilter={setFilter}
        filter={filter}
        status={status}
        search={search}
        setSearch={setSearch}
      >
        {context.gridViewShipment ? (
          <div>
            <div style={{ width: '100%' }}>
              <div
                className='d-flex justify-content-between align-items-center'
                style={{
                  paddingBottom: '0.5rem'
                }}
              >
                <div
                  className={`d-flex justify-content-center align-items-center filter-div-shipment ${
                    Object.values(filterObj.current).filter(element => {
                      if (element?.length > 0 || isObject(element)) return 1
                    }).length > 0
                      ? 'color-style'
                      : ''
                  }`}
                >
                  <div
                    className=' d-flex cursorPointer '
                    onClick={() => {
                      setFilter({
                        TransportMode: filter.TransportMode,
                        ContainerType: filter.ContainerType,
                        Origin: filter.Origin,
                        Destination: filter.Destination,
                        Carrier: filter.Carrier,
                        Consignor: filter.Consignor,
                        Consignee: filter.Consignee,
                        OrderNumber: filter.OrderNumber,
                        MasterBill: filter.MasterBill,
                        FromETA: filter.FromETA,
                        ToETA: filter.ToETA,
                        ToETD: filter.ToETD,
                        FromETD: filter.FromETD,
                        FromATA: filter.FromATA,
                        ToATA: filter.ToATA,
                        FromATD: filter.FromATD,
                        ToATD: filter.ToATD,
                        DestinationPortName: filter.DestinationPortName,
                        OriginPortName: filter.OriginPortName,
                      });
                      setSortmodal(true);
                    }}
                  >
                    <FilterAltOutlinedIcon
                      color={`${
                        Object.values(filterObj.current).filter(element => {
                          if (element?.length > 0 || isObject(element)) return 1
                        }).length > 0
                          ? 'third'
                          : 'primary'
                      }`}
                    />
                  </div>
                  <div className=' d-flex cursorPointer '>
                    {Object.values(filterObj.current).filter(element => {
                      if (element?.length > 0 || isObject(element)) return 1
                    }).length > 0 && (
                      <div className='d-flex ps-2'>
                        <div style={{ border: '1px solid white' }} />
                        <IconButton disabled={loadingOnSearch} size='small'>
                          <CloseOutlinedIcon
                            color='third'
                            onClick={() => {
                              setCurrentPage(0)
                              setLoadingOnSearch(true)
                              filterObj.current = {
                                TransportMode: '',
                                ContainerType: '',
                                Origin: '',
                                Destination: '',
                                Carrier: '',
                                Shipper: '',
                                Consignor: '',
                                Consignee: '',
                                OrderNumber: '',
                                MasterBill: '',
                                FromETA: null,
                                ToETA: null,
                                ToETD: null,
                                FromETD: null,
                                FromATA: null,
                                ToATA: null,
                                FromATD: null,
                                ToATD: null,
                                DestinationPortName: "",
                                OriginPortName: "",
                              };
                              setFilter({
                                TransportMode: '',
                                ContainerType: '',
                                Origin: '',
                                Destination: '',
                                Carrier: '',
                                Shipper: '',
                                Consignor: '',
                                Consignee: '',
                                OrderNumber: '',
                                MasterBill: '',
                                FromETA: null,
                                ToETA: null,
                                ToETD: null,
                                FromETD: null,
                                FromATA: null,
                                ToATA: null,
                                FromATD: null,
                                ToATD: null,
                                DestinationPortName: "",
                                OriginPortName: "",
                              });
                              getShipmentCollection(0, status); //api
                            }}
                          />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>

                <Stack
                  direction='row'
                  spacing={1}
                  style={{
                    backgroundColor: `${
                      sortFilterObj?.current?.sortColumn.length > 0
                        ? 'var(--color-primary)'
                        : ''
                    }`
                  }}
                  className='sort-div'
                >
                  <div
                    className='d-flex align-items-center'
                    onClick={() => {
                      // setSortColumn("");
                      // setSortDirection("Asc");
                      setModalShipment(true)
                    }}
                  >
                    <p
                      className='mr-2'
                      style={{
                         minWidth: '60px',
                        textAlign: 'center',
                        marginTop: '5px',
                        color: `${
                          sortFilterObj?.current?.sortColumn.length > 0
                            ? 'var(--color-white) '
                            : ''
                        }`
                      }}
                    >
                      Sort By
                    </p>

                    <img
                      src={
                        sortFilterObj?.current?.sortColumn.length > 0
                          ? urlResolver('assets/line_arrow_white.svg')
                          : urlResolver('assets/line_arrow.svg')
                      }
                    />
                  </div>
                  <div>
                    <div className=' d-flex cursorPointer '>
                      {sortFilterObj?.current?.sortColumn.length > 0 && (
                        <div className='d-flex ps-2'>
                          <div style={{ border: '1px solid white' }} />
                          <IconButton disabled={loadingOnSearch} size='small'>
                            <CloseOutlinedIcon
                              color='third'
                              onClick={() => {
                                setLoadingOnSearch(true)
                                sortFilterObj.current = {
                                  sortColumn: '',
                                  sortDirection: ''
                                }
                                setSortDirection('')
                                setSortColumn('')
                                setCurrentPage(0)

                                getShipmentCollection(0, status) //api
                              }}
                            />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                </Stack>

                <div className='headersearch searchShipmentDiv '>
                  <input
                    type='text'
                    className='form-control '
                    placeholder='Search your Shipment'
                    value={search}
                    onChange={e => {
                      setSearch(e.target.value)
                    }}
                    onKeyPress={e => {
                      if (e.key === 'Enter' && inputValidation(search)) {
                        navigate(Routeconstant.SHIPMENT_LISTING, {
                          replace: true
                        })
                        filterObj.current = {}
                        sortFilterObj.current = {
                          sortColumn: '',
                          sortDirection: ''
                        }
                        setCheckActive(null)
                        setLoadingOnSearch(true)
                        setStatus('')
                        setSearchedClicked(true)
                        getShipmentCollection(0, '')
                      }
                    }}
                  />
                  <img
                    src={urlResolver('assets/search_icon.svg')}
                    onClick={
                      search
                        ? e => {
                            navigate(Routeconstant.SHIPMENT_LISTING, {
                              replace: true
                            })
                            filterObj.current = {}
                            sortFilterObj.current = {
                              sortColumn: '',
                              sortDirection: 'Asc'
                            }
                            setCurrentPage(0)
                            setCheckActive(null)
                            setStatus('')
                            getShipmentCollection(0, '', e.target.value)
                            setSearchedClicked(true)
                            setLoadingOnSearch(true)
                          }
                        : () => {}
                    }
                    style={{ color: 'rgba(var(--text-heading-dark))' }}
                    className='searchIcon'
                  />
                  {search && (
                    <CloseOutlinedIcon
                      style={{
                        position: 'absolute',
                        right: '1rem',
                        cursor: 'pointer'
                      }}
                      className='searchClearIcon'
                      onClick={() => {
                        setSearchValidation(true)
                        navigate(Routeconstant.SHIPMENT_LISTING, {
                          replace: true
                        })
                        setCurrentPage(0)
                        setCheckActive(null)
                        crossButtonClick.current = true
                        setSearch('')
                        setSearchedClicked(false)
                        getShipmentCollection(0, status)
                        setLoadingOnSearch(true)
                      }}
                    />
                  )}
                </div>
              </div>
              {inputValidation(search) ? (
                ''
              ) : (
                <div
                  className='validation-error'
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  Special characters are not allowed.
                </div>
              )}
            </div>
            <ul
              className='nav nav-pills mb-3 justify-content-between'
              style={{ width: '98%' }}
            >
              <li className='nav-item'>
                <a
                  className={
                    status === ''
                      ? 'nav-link active nav-active-div font-text'
                      : 'nav-link nav-div font-text'
                  }
                  href='#'
                  onClick={() => {
                    setLoadingOnSearch(true)
                    getShipmentCollection(0, '')
                    setCurrentPage(0)
                    setStatus('')
                    setSearch('')
                    setSearchedClicked(false)
                    context.setPreloaded([])
                    context.setLoaded([])
                    context.setArrived([])
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  All
                </a>
              </li>
              <li
                className={searchedClicked ? 'disabled nav-item' : 'nav-item'}
              >
                <a
                  className={
                    status === '1'
                      ? 'nav-link active nav-active-div font-text'
                      : 'nav-link nav-div font-text'
                  }
                  href='#'
                  onClick={() => {
                    setLoadingOnSearch(true)
                    getShipmentCollection(0, '1')
                    setCurrentPage(0)
                    setStatus('1')
                    context.setShipmentCollection([])
                    context.setLoaded([])
                    context.setArrived([])
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  Pre-loaded
                </a>
              </li>
              <li
                className={searchedClicked ? 'disabled nav-item' : 'nav-item'}
              >
                <a
                  className={
                    status === '2'
                      ? 'nav-link active nav-active-div font-text'
                      : 'nav-link nav-div font-text'
                  }
                  href='#'
                  onClick={() => {
                    setLoadingOnSearch(true)

                    getShipmentCollection(0, '2')
                    setCurrentPage(0)
                    setStatus('2')
                    context.setPreloaded([])
                    context.setShipmentCollection([])
                    context.setArrived([])
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  In-transit
                </a>
              </li>
              <li
                className={searchedClicked ? 'disabled nav-item' : 'nav-item'}
              >
                <a
                  className={
                    status === '3'
                      ? 'nav-link active nav-active-div font-text'
                      : 'nav-link nav-div font-text'
                  }
                  href='#'
                  onClick={() => {
                    setLoadingOnSearch(true)
                    setCurrentPage(0)
                    getShipmentCollection(0, '3')
                    setStatus('3')
                    context.setPreloaded([])
                    context.setLoaded([])
                    context.setShipmentCollection([])
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  Arrived
                </a>
              </li>
              <li
                className={searchedClicked ? 'disabled nav-item' : 'nav-item'}
              >
                <a
                  className={
                    status === '4'
                      ? 'nav-link active nav-active-div font-text'
                      : 'nav-link nav-div font-text'
                  }
                  href='#'
                  onClick={() => {
                    setLoadingOnSearch(true)
                    setCurrentPage(0)
                    getShipmentCollection(0, '4')
                    setStatus('4')
                    context.setPreloaded([])
                    context.setLoaded([])
                    context.setArrived([])
                    context.setShipmentCollection([])
                    navigate(Routeconstant.SHIPMENT_LISTING)
                  }}
                >
                  Archive
                </a>
              </li>
              <li>
                <CustomTooltip
                  of={'Shipment_Listing'}
                  forKey={'TabInformation'}
                />
              </li>
            </ul>

            <div className='index-card '>
              {loading.initial || loadingOnSearch ? (
                <div className='skeleton-div'>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              ) : (
                <>
                  {status === '' && context.shipmentCollection.length ? (
                    context.shipmentCollection.map((i, index) => {
                      return (
                        <div style={{ marginRight: '8px' }} key={index}>
                          <Card
                            setCheckActive={setCheckActive}
                            checkActive={checkActive}
                            baseroute={Routeconstant.SHIPMENT_LISTING}
                            data={i}
                            index={index}
                            markFavourite={markFavourite}
                          />
                        </div>
                      )
                    })
                  ) : status === '1' && context.preloaded.length ? (
                    context.preloaded.map((i, index) => {
                      return (
                        <div key={index}>
                          <Card
                            setCheckActive={setCheckActive}
                            checkActive={checkActive}
                            baseroute={Routeconstant.SHIPMENT_LISTING}
                            data={i}
                            index={index}
                            markFavourite={markFavourite}
                          />
                        </div>
                      )
                    })
                  ) : status === '2' && context.loaded.length ? (
                    context.loaded.map((i, index) => {
                      return (
                        <div key={index}>
                          <Card
                            setCheckActive={setCheckActive}
                            checkActive={checkActive}
                            baseroute={Routeconstant.SHIPMENT_LISTING}
                            data={i}
                            index={index}
                            markFavourite={markFavourite}
                          />
                        </div>
                      )
                    })
                  ) : status === '3' && context.arrived.length ? (
                    context.arrived.map((i, index) => {
                      return (
                        <div key={index}>
                          <Card
                            setCheckActive={setCheckActive}
                            checkActive={checkActive}
                            index={index}
                            baseroute={Routeconstant.SHIPMENT_LISTING}
                            data={i}
                            markFavourite={markFavourite}
                          />
                        </div>
                      )
                    })
                  ) : status === '4' && context.archive.length ? (
                    context.archive.map((i, index) => {
                      return (
                        <div key={index}>
                          <Card
                            setCheckActive={setCheckActive}
                            checkActive={checkActive}
                            index={index}
                            baseroute={Routeconstant.SHIPMENT_LISTING}
                            data={i}
                            markFavourite={markFavourite}
                          />
                        </div>
                      )
                    })
                  ) : (
                    <div className='d-flex justify-content-center align-items-center'>
                      No data found
                    </div>
                  )}
                  {(status === '' &&
                    context?.shipmentCollection.length !== totalcount) ||
                  (status === '1' &&
                    context?.preloaded.length !== totalcount) ||
                  (status === '2' && context?.loaded.length !== totalcount) ||
                  (status === '3' && context?.arrived.length !== totalcount) ||
                  (status === '4' && context?.archive.length !== totalcount) ? (
                    <BtnOutline
                      title={
                        loading.loadmore ? (
                          <CircularProgress
                            size='1rem'
                            style={{ color: 'var(--color-miscellaneous-4)' }}
                          />
                        ) : (
                          'Show More'
                        )
                      }
                      onClick={() => {
                        const pagenumber = currentPage + 1
                        getShipmentCollection(pagenumber, status)
                        setCurrentPage(pagenumber)

                        context.setListSize(pagenumber)
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        ) : (
          <>
            {location.pathname === `${Routeconstant.SHIPMENT_LISTING}` ? (
              <Suspense fallback={<p>coming up!</p>}>
                <Tableindex
                  sortColumnCopy={sortColumnCopy}
                  setSearch={setSearch}
                  search={search}
                  searchedClicked={searchedClicked}
                  setSearchedClicked={setSearchedClicked}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                  getShipmentCollection={getShipmentCollection}
                  setLoading={setLoading}
                  loading={loading}
                  setSortmodal={setSortmodal}
                  totalCount={totalcount}
                  sortColumn={sortColumn}
                  setSortColumn={setSortColumn}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  setStatus={setStatus}
                  filterObj={filterObj}
                  setFilter={setFilter}
                  loadingOnSearch={loadingOnSearch}
                  setLoadingOnSearch={setLoadingOnSearch}
                  crossButtonClick={crossButtonClick}
                  inputValidation={inputValidation}
                  searchValidation={searchValidation}
                  setSearchValidation={setSearchValidation}
                />
              </Suspense>
            ) : (
              <ShipmentDetailsListView />
            )}
          </>
        )}
      </LayoutShipments>
    </>
  )
}

export default Index
