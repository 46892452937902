import { CancelOutlined } from "@mui/icons-material";
import { Autocomplete, IconButton, Slide, TextField } from "@mui/material";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import UploadTable from "./UploadTable";
import { useDispatch, useSelector } from "react-redux";
import { addDocument } from "../../../../redux/bookingRedux/bookingBasicDetailSlice";
import instance from "../../../../api/Interceptor";
import { API_PATH } from "../../../../constant/apiPath";
import toast from "react-hot-toast";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
const docTypesToBeShown = ["CIV", "PKL"];

const DocumentUpload = () => {
  const { bookingBasicDetails } = useSelector((state) => state);
  const isEdit = bookingBasicDetails.BookingHeader?.BookingNo?.length > 1;
  // console.log("BookingHeader=>", bookingBasicDetails.BookingHeader?.BookingNo?.length > 1);
  const [uploadBar, setUploadBar] = useState(false);
  const [docuploadoption, setDocuploadoption] = useState([]);
  const [currentDoc, setCurrentDoc] = useState({});
  const [currentDocType, setCurrentDocType] = useState("");
  const [editDocs, SetEditDocs] = useState([]);
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  const updateCDoc = async (name, value) => {
    const base = await getBase64(value[0]);
    const splittedBase = base.split(",");
    let nameDoc = currentDoc[0]?.name?.split(".");
    let lastElement = nameDoc.length - 1;
    let extension = nameDoc[lastElement];
    let obj = {
      file: value[0],
      base: splittedBase,
      docType: currentDocType,
      docExtension: extension,
    };
    dispatch(addDocument(obj));
    setCurrentDoc({});
    setCurrentDocType("");
    setUploadBar(false);
  };
  const editNewDocs = async (value) => {
    const base = await getBase64(value[0]);
    const splittedBase = base.split(",");
    let tempObj = {
      DocsDocType: currentDocType,
      docTypeDesc: "",
      DocsFileName: value[0].name,
      DocsFileSize: value[0].size,
      DocsFileSizeUnit: "bytes",
      DocsUploadedDate: "",
      DocsUploadedBY: "",
      DocsFileData: splittedBase[1],
      DocsFileExtention: "",
      DocsFileConversion: `${splittedBase[0]},`,
    };
    let tempDocs = [...editDocs];
    tempDocs.push(tempObj);
    SetEditDocs(tempDocs);
  };
  const deleteNewDocs = (index) => {
    let temp = [...editDocs];
    temp.splice(index, 1);
    SetEditDocs(temp);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  const getOptionsForDocType = () => {
    const body = {
      //   ShipmentId: location?.state?.ShipmentId ?? "",
    };
    instance
      .post(API_PATH.GET_OPTIONS_FOR_DOC_UPLOAD, body)
      .then((res) => {
        let filteredTypes = res.data.filter((item, index) => {
          return docTypesToBeShown.includes(item.Type);
        });
        setDocuploadoption(filteredTypes);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };
  const uploadNewDocs = () => {
    const body = {
      BookingNumber: bookingBasicDetails.BookingHeader?.BookingNo,
      DocsCollection: editDocs,
      ShowDeveloperResponse: true,
    };
    instance
      .post(`${API_PATH.UPLOAD_NEW_DOCS}`, body)
      .then((res) => {
        console.log("ress", res);
      })
      .catch((err) => {
        console.log("error", err);
      })
      .finally(() => {});
  };
  useEffect(() => {
    getOptionsForDocType();
  }, []);

  return (
    <div
      className="doc-upload upload-layout  "
      style={{
        width: "100%",
        overflow: "auto",
      }}
      ref={containerRef}
    >
      <h5
        style={{
          paddingBottom: "1rem",
          marginBottom: "0rem",
        }}
      >
        Document Upload
      </h5>
      <UploadTable setUploadBar={setUploadBar} />
      {editDocs.length > 0 && (
        <>
          <h5
            style={{
              margin: "2rem 0",
            }}
          >
            New Documents
          </h5>
          <UploadTable
            isEdit={isEdit}
            editDocs={editDocs}
            deleteNewDocs={deleteNewDocs}
          />
        </>
      )}
      <Slide
        direction="up"
        in={uploadBar}
        mountOnEnter
        unmountOnExit
        container={containerRef.current}
      >
        <div
          className={
            uploadBar
              ? "upload-drawer-visible layout-padding"
              : "upload-drawer-hidden"
          }
        >
          <div className="upload-drawer-head">
            <h6 className="common-p">UPLOAD</h6>
            <IconButton color="secondary" onClick={() => setUploadBar(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={{
              // backgroundColor: "var(--color-white)",
              // marginBottom: "1rem",
              borderRadius: "10px",
            }}
          >
            <Autocomplete
              sx={{ width: "100%" }}
              freeSolo
              autoHighlight
              size="small"
              disablePortal
              id="combo-box-demo"
              onChange={(e, val) => {
                setCurrentDocType(val.Type);
              }}
              options={docuploadoption}
              getOptionLabel={(option) => `${option?.Description}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Document type"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              height: "16rem",
            }}
          >
            {currentDoc.length > 0 ? (
              <div
                style={{
                  backgroundColor: "var(--white)",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    flexGrow: "initial",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      setCurrentDoc({});
                    }}
                  >
                    <CloseIcon />
                  </IconButton>{" "}
                </div>
                <div
                  style={{
                    flexGrow: "1",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <h6>
                    {currentDoc[0].name} <UploadFileIcon />
                  </h6>
                </div>
              </div>
            ) : (
              <DropzoneArea
                filesLimit={1}
                showAlerts={false}
                showFileNames={true}
                showPreviewsInDropzone={false}
                // classes={{ root: clsx([classes.dropZoneRoot, uploading ? classes.disableClick : null]) }}
                onChange={(e) => {
                  if (isEdit) {
                    editNewDocs(e);
                  } else {
                    setCurrentDoc(e);
                  }
                }}
                style={{ opacity: "0.6" }}
              />
            )}
          </div>
        </div>
      </Slide>
      <div
        className="buttons-container upload-button d-flex justify-content-end"
        style={{
          width: "97%",
        }}
      >
        {uploadBar ? (
          currentDocType?.length > 0 &&
          currentDoc.length > 0 && (
            <button
              style={{ border: "none", width: "10rem" }}
              onClick={() => {
                if (uploadBar) {
                  updateCDoc("docFile", currentDoc);
                  setUploadBar(false);
                }
                setUploadBar(true);
              }}
            >
              Upload
            </button>
          )
        ) : (
          ""
        )}

        {isEdit && (
          <button
            disabled={!editDocs?.length}
            style={{ border: "none", width: "10rem" }}
            onClick={() => {
              uploadNewDocs();
            }}
          >
            Upload All
          </button>
        )}
      </div>
    </div>
  );
};
export default DocumentUpload;
