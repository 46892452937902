import { AppBar, IconButton, Toolbar } from "@mui/material";
import React from "react";
import urlResolver from "../../utils/urlResolver";

const PublicHeader = () => {
  return (
    <>
      <AppBar position="static" color="transparent">
        <Toolbar disableGutters>
          <div className="header-logo">
            <a href={`https://softfreightlogic.com/`} target="_blank">
              <IconButton
                onClick={() => {
                  // navigate("https://softfreightlogic.com/");
                }}
              >
                <img src={urlResolver(`assets/headerLogo.png`)} />
              </IconButton>
            </a>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default PublicHeader;
