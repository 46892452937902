import { Button, Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  decrease,
  increase,
} from "../../../redux/orderRedux/createOrder/headerSlice";
import { orderspecificSchema } from "../../../schema/orderSpecificSchema";
import { useSelector } from "react-redux";
import { useState } from "react";
import { addErrors } from "../../../redux/orderRedux/createOrder/orderErrors/orderspecificerrorslice";
import { createOrder } from "./apiCalls/post/createOrder";
import {
  updateLoading,
  updateResponse,
} from "../../../redux/orderRedux/createOrderSlice";
import { orderLineDetailsSchema } from "../../../schema/orderLineDetailsSchema";
const Footer = () => {
  const { order, linedetails, orderplan, documents, header, createOrderData } =
    useSelector((state) => state);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});

  const increaseStepper = () => {
    if (header.currentStepper == 0) {
      orderspecificSchema
        .validate(order, { abortEarly: false })
        .then(() => {
          dispatch(increase());
          dispatch(addErrors({}));
        })
        .catch((validationErrors) => {
          const errorsObj = {};
          validationErrors.inner.forEach((error) => {
            errorsObj[error.path] = error.message;
          });
          dispatch(addErrors({ errorsObj }));
          setErrors(errorsObj);
        });
    } else if (header.currentStepper == 1) {
      orderLineDetailsSchema
        .validate(linedetails[0], { abortEarly: false })
        .then(() => {
          dispatch(increase());
          dispatch(addErrors({}));
        })
        .catch((validationErrors) => {
          const errorsObj = {};
          validationErrors.inner.forEach((error) => {
            errorsObj[error.path] = error.message;
          });
          dispatch(addErrors({ errorsObj }));
          setErrors(errorsObj);
        });
    } else {
      dispatch(increase());
    }
  };
  const decreaseStepper = () => {
    dispatch(decrease());
  };
  const handleCreateOrder = async (type) => {
    dispatch(updateLoading({ load: true }));
    const promiseData = await createOrder(
      order,
      linedetails,
      orderplan,
      documents,
      type
    );
    dispatch(updateResponse({ resp: promiseData[0] }));

    dispatch(updateLoading({ load: false }));
  };
  return (
    <Grid container spacing={0} className="overlayPadding">
      <Grid
        item
        className="buttons-container"
        lg={12}
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {header.currentStepper > 0 ? (
          <Button
            className="font-change"
            variant="contained"
            color="primary"
            onClick={decreaseStepper}
            sx={{
              marginRight: "1rem",
            }}
          >
            Previous
          </Button>
        ) : null}

        {header.currentStepper < 4 ? (
          <Button
            variant="contained"
            color="primary"
            className="font-change"
            onClick={increaseStepper}
          >
            NEXT
          </Button>
        ) : (
          <>
            {order?.orderDetailsFromAPI?.data?.OrderLinesCollection ? (
              <Button
                className="font-change"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCreateOrder("put");
                }}
              >
                Update Order
              </Button>
            ) : (
              <Button
                className="font-change"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleCreateOrder("post");
                }}
              >
                Create Order
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default Footer;
