import CryptoJS from "crypto-js";
import { SECRET_KEY } from "../Config";

function decryptData(data) {
  try {
    const encrypted = localStorage.getItem(data);
    const decrypted = CryptoJS?.AES?.decrypt(encrypted, SECRET_KEY).toString(
      CryptoJS.enc.Utf8
    );
    console.log("decrypted", decrypted);
    return JSON.parse(decrypted);
   
    
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
}
export default decryptData;
