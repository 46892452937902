import { customerName } from "../Config";

let permission = {
  financesAccess: true,
  exportToExcel: true,
  rms: {
    dashboard: {
      getrates: true
    }
  },
  AxisShipping:{
    captcha: true
  }
};
const accessGiven = () => {
  switch (customerName) {
    case "SFLInsight":
     return permission;

     case "Brokeman":
      permission.financesAccess = false;
      return permission;

      case "SAR Transport":
        permission.exportToExcel = false;
        return permission;

        case "AxisShipping":
        permission.AxisShipping.captcha = false;
        return permission;

      default: return permission;
  }
};
export default accessGiven;
