import React, { useEffect } from 'react'

const Map = props => {
  const { id, options, onMapLoad } = props

  const onScriptLoad = () => {
    const map = new window.google.maps.Map(document.getElementById(id), options)
    onMapLoad(map)
  }

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://maps.google.com/maps/api/js?key=AIzaSyDpfWeLTpZm0lhuv7_s-8cvKv_8sxJUHD0`
      const head = document.getElementsByTagName('head')[0]
      head.appendChild(script)

      // Attach the load event listener
      script.addEventListener('load', onScriptLoad)
      console.log('styleSelector', id)
      const styleSelector = document.getElementById('style-selector')
      console.log('styleSelector', styleSelector)
      // Cleanup function to remove event listener and script tag
      return () => {
        script.removeEventListener('load', onScriptLoad)
        head.removeChild(script)
      }
    } else {
      onScriptLoad()
    }
  }, [id, options, onMapLoad])

  return <div style={{ width: '100%', height: '100%' }} id={id} />
}

export default Map
