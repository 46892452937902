import React, { useState, createContext } from "react";
import decryptData from "../../decryptencrypt/decrypt";
import instance from "../../api/Interceptor";
import { API_PATH } from "../../constant/apiPath";
import toast from "react-hot-toast";
const AuthContext = createContext();

function AuthProvider(props) {
  const [isLogin, setIslogin] = useState(false);
  const [userData, setUserData] = useState({});
  const [gridViewShipment, setGridViewShipment] = useState(true);
  const [gridViewOrder, setGridViewOrder] = useState(true);
  const [shipmentCollection, setShipmentCollection] = useState([]);
  
  const [preloaded, setPreloaded] = useState([]);
  const [loaded, setLoaded] = useState([]);
  const [arrived, setArrived] = useState([]);
  const [archive, setArchive] = useState([]);
  const [orderCollection, setOrderCollection] = useState([]);
  const [preloadedOrder, setPreloadedOrder] = useState([]);
  const [loadedOrder, setLoadedOrder] = useState([]);
  const [arrivedOrder, setArrivedOrder] = useState([]);
  const [token, setToken] = useState({});
  const [filterLoaded, setFilterLoaded] = useState(false);
  const [buttonActive, setButtonActive] = useState({
    Code: "SEA",
    Description: "Sea Freight",
  });
  const [mass, setMass] = useState([]);
  const [rmsData, setRmsData] = useState({});
  const [rmsinquiry, setRmsinquiry] = useState("");
  const [listSize, setListSize] = useState(0);
  const [shipmentId, setShipmentId] = useState("");
  const [modalRmsData, setModalRmsData] = useState({});
  const [QuotationID, setQuotationID] = useState("");
  const role =
    JSON.parse(localStorage.getItem("userData"))?.Role?.includes("Sales") ===
    true
      ? "Sales"
      : "";
  const [headerTab, setHeaderTab] = useState(false);
  const updateUserData = (data) => {
    localStorage.setItem("userData", JSON.stringify(data));
    // Update the state with the new userData
    setUserData(data);
  };
  const [openUpdate, setOpenUpdate] = useState(false);
  const [ModuleAccessibility, setModuleAccessibility] = useState(
    decryptData("moduleAccessibility")
  );
  const [emissions, setEmissions] = useState([
    { CO2: "", loading: false },
    { lune: "", loading: false },
  ]);
  const getCarbonEmission = () => {
    let tempLoading = { ...emissions };
    tempLoading[0].loading = true;
    setEmissions(tempLoading);
    let shipmentID = shipmentId;
    instance
      .get(`${API_PATH.GET_CARBON_EMISSION}/${shipmentID}`)
      .then((res) => {
        let temp = { ...emissions };
        temp[0].CO2 = `${Number(res?.data?.emissions[0]?.amount).toFixed(2)} ${
          res?.data?.emissions[0]?.unit
        }`;
        setEmissions(temp);
      })
      .catch((err) => {
        console.log(err);
        toast.error("No data.");
      })
      .finally(() => {
        let tempLoading = { ...emissions };
        tempLoading[0].loading = false;
        setEmissions(tempLoading);
      });
  };
  const getLuneEmission = () => {
    let tempLoading = { ...emissions };
    tempLoading[1].loading = true;
    setEmissions(tempLoading);
    let shipmentID = shipmentId;
    instance
      .get(`${API_PATH.GET_LUNE_EMISSION}/${shipmentID}`)
      .then((res) => {
        console.log('Response Data:', res.data); // Log the entire response data
        
        if (res?.data?.emissions?.length > 0) {
          let temp = { ...emissions };
          temp[1].lune = `${Number(res.data.emissions[0].amount).toFixed(2)} ${
            res.data.emissions[0].unit
          }`;
          setEmissions(temp);
          
          console.log('No emissions data found'); // Log if no emissions data is available
        }
      })
      .catch((err) => console.error('Error fetching emissions data:', err)) // Log the error
      .finally(() => {
        let tempLoading = { ...emissions };
        tempLoading[1].loading = false;
        setEmissions(tempLoading);
      });
  };
  
  return (
    <AuthContext.Provider
      value={{
        listSize,
        isLogin,
        gridViewShipment,
        shipmentCollection,
        preloaded,
        loaded,
        arrived,
        gridViewOrder,
        arrivedOrder,
        loadedOrder,
        preloadedOrder,
        orderCollection,
        token,
        userData,
        archive,
        filterLoaded,
        buttonActive,
        rmsData,
        rmsinquiry,
        shipmentId,
        openUpdate,
        setHeaderTab,
        headerTab,
        modalRmsData,
        role,
        mass,
        QuotationID,
        setModuleAccessibility,
        ModuleAccessibility,
        setOpenUpdate,
        setMass,
        setListSize,
        setUserData: updateUserData,
        setPreloadedOrder,
        setOrderCollection,
        setLoadedOrder,
        setArrivedOrder,
        setIslogin,
        setGridViewShipment,
        setShipmentCollection,
        setPreloaded,
        setLoaded,
        setArrived,
        setGridViewOrder,
        setToken,
        setArchive,
        setFilterLoaded,
        setButtonActive,
        setRmsData,
        setRmsinquiry,
        setModalRmsData,
        setQuotationID,
        setShipmentId,
        getCarbonEmission,
        getLuneEmission,
        emissions,
        setEmissions,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };
