export function status(state) {
  switch (state) {
    case 0:
      return 'Pending'
    case 1:
      return 'Accepted'
    case 2:
      return 'Rejected'
    default:
      return null
  }
}
export async function userType() {
  const userRoles = new Promise((res, rej) => {
    let role = localStorage.getItem('userData')
    if (role) {
      res(role)
    } else {
      rej(null)
    }
  })
  return await userRoles
}
